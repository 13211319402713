import React from "react";
import ReactModal from "react-modal";
import Loader from "../../../../common/loader.jsx";
import axios, { errorHandler, responseHandler } from "../../../../requests/axios.js";
import { toast } from "react-toastify";

function ManagePromoLinkDialog({ promoGuid, message, onClose, onConfirm, action, isOpen, contentType, contentGuid }) {
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  function onSubmit() {
    setLoading(true);
    const url = `/api/${contentType}/${contentGuid}/promos/${promoGuid}`;
    let promise = action === "delete" ? axios.delete(url) : axios.post(url);

    promise
      .then(
        responseHandler(() => {
          onClose();
          toast.success(action === "delete" ? "Promo unlinked successfully" : "Promo Link restored successfully");
          onConfirm();
        }),
      )
      .catch(
        errorHandler((err) => {
          console.error(err);
          setError("Something went wrong while contacting the server");
        }),
      )
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">Manage Promo Link</div>
        <div className="v-react-modal__body modal-body">
          {message}
          {error ? <p className="u-fc--warning">{error}</p> : null}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={onSubmit}>
                Confirm
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default ManagePromoLinkDialog;
