import { HbbTvScheduler } from "../js/scheduler/HbbTvScheduler.js";
import HbbTvPlayer from "../js/scheduler/HbbTvPlayer.js";
import Logger from "../js/utils/Logger.js";

export default function hbbtvPlayerModule(channelName, debugTimeTravel = null) {
  const options = {
    logger: "console", // can be screen or http or empty, but use console
    loggingEndpoint: "", // must be empty
    adEndpoint: "https://ads-middleware.goseetv.media/rt/88440", // never changes - this is the demo ad endpoint
    scheduleEndpoint: `https://api.goseetv.media/feeds/epgs/hbbtv?channel=${channelName}&days=1&no_tracking=true${debugTimeTravel ? `&_debug_time=${debugTimeTravel.toISOString()}` : ""}`,
    channel: channelName,
  };

  const logger = new Logger(options.logger, false, options.loggingEndpoint);

  // setup scheduler
  const scheduler = new HbbTvScheduler(
    options.scheduleEndpoint,
    options.adEndpoint,
    logger,
    options.channel,
    debugTimeTravel,
  );

  // setup player
  const videoContainer = document.querySelector("#gstv-hbbtv-video-container");
  const video = videoContainer.querySelector("video");
  const adContainer = document.querySelector("#gstv-hbbtv-ad-container");
  const adVideo = adContainer.querySelector("video");

  const hbbtvPlayer = new HbbTvPlayer(videoContainer, adContainer, "https", logger);

  const coverImage = document.getElementById("gst-hbbtv-cover-image");

  function onSchedulerReady(program) {
    if (!program) {
      logger.error("ON SCHEDULER.READY >>> BUT NOT PROGRAM IS AVAILABLE");
      return;
    }

    try {
      hbbtvPlayer.play(program.streamurl, scheduler.getSeekTime(program));
    } catch (e) {
      logger.error("ON SCHEDULER.READY >>> FAILED TO PLAY PROGRAM", e.toString());
    }
  }

  function onSchedulerProgramChange(program) {
    try {
      hbbtvPlayer.play(program.streamurl, scheduler.getSeekTime(program));
    } catch (e) {
      logger.error("ON CHANGE_PROGRAM >>> FAILED TO PLAY PROGRAM", JSON.stringify(e));
    }
  }

  function onSchedulerAdBreak({ adverts, cuepoint }) {
    try {
      hbbtvPlayer.playAdverts(adverts, cuepoint);
    } catch (e) {
      logger.error("ON AD_BREAK >>> FAILED TO PLAY ADVERTS", e.toString());
    }
  }

  function onPlayerInitialised() {
    if (coverImage) {
      logger.info("ON PLAYER.INITIALISED >>> REMOVING COVER IMAGE");
      coverImage.style.display = "none";
      scheduler.didInitialise();
    }
  }

  function onPlayerPlaybackStart() {
    logger.info("ON PLAYER.PLAYBACK_STARTING >>> TOGGLING VISIBILITY");

    adContainer.classList.remove("container-hidden", "container-shown");
    videoContainer.classList.remove("container-hidden", "container-shown");

    adContainer.classList.add("container-hidden");
    videoContainer.classList.add("container-shown");

    if (!video.classList.contains("test-vid-shown")) {
      video.classList.add("test-vid-shown");
      video.classList.remove("test-vid-hidden");
      adVideo.classList.remove("test-vid-shown");
      adVideo.classList.add("test-vid-hidden");
    }
  }

  function onPlayerPlaybackEnd() {
    logger.error("ON PLAYER.PLAYBACK_ENDED");
    scheduler.playNextProgram();
  }

  function startApplication() {
    logger.info("STARTING APPLICATION");

    // Attach scheduler events
    scheduler.events.on("READY", onSchedulerReady);
    scheduler.events.on("CHANGE_PROGRAM", onSchedulerProgramChange);
    scheduler.events.on("AD_BREAK", onSchedulerAdBreak);

    // Attach player events
    hbbtvPlayer.events.on("INITIALISED", onPlayerInitialised);
    hbbtvPlayer.events.on("PLAYBACK_STARTING", onPlayerPlaybackStart);
    hbbtvPlayer.events.on("PLAYBACK_ENDED", onPlayerPlaybackEnd);
  }

  function destroyApplication() {
    logger.info("RESETTING PLAYER");
    hbbtvPlayer._resetPlayer();
    logger.info("CLEANING EVENTS");

    // detach scheduler events
    scheduler.events.off("READY", onSchedulerReady);
    scheduler.events.off("CHANGE_PROGRAM", onSchedulerProgramChange);
    scheduler.events.off("AD_BREAK", onSchedulerAdBreak);

    // detach player events
    hbbtvPlayer.events.off("INITIALISED", onPlayerInitialised);
    hbbtvPlayer.events.off("PLAYBACK_STARTING", onPlayerPlaybackStart);
    hbbtvPlayer.events.off("PLAYBACK_ENDED", onPlayerPlaybackEnd);
  }

  return {
    startApplication,
    destroyApplication,
  };
}
