import React from "react";
import ReactModal from "react-modal";
import Loader from "../../../../common/loader.jsx";
import axios, { errorHandler, responseHandler } from "../../../../requests/axios.js";
import { toast } from "react-toastify";
import DataTable from "../../../../components/data-table/data-table.jsx";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Link } from "react-router-dom";

export default function AddPromoDialog({ contentGuid, contentType, onClose, onConfirm, isOpen }) {
  const [isLoading, setLoading] = React.useState(false);
  const [linkIds, setLinkIds] = React.useState([]);
  const [error, setError] = React.useState("");

  const fetchLinkIds = React.useCallback(() => {
    if (contentType && contentGuid) {
      axios
        .get(`api/${contentType}/${contentGuid}/promos`)
        .then(responseHandler((resp) => setLinkIds(resp.data.data.map((promo) => promo.promo_guid))))
        .catch(
          errorHandler((e) => {
            console.error(e);
            toast.error("Error fetching Linked Promo Ids");
          }),
        );
    }
  }, [contentGuid, contentType]);

  React.useEffect(() => {
    fetchLinkIds();
  }, [fetchLinkIds]);

  function onSubmit(promoGuid) {
    setLoading(true);
    axios
      .post(`/api/${contentType}/${contentGuid}/promos/${promoGuid}`)
      .then(
        responseHandler(() => {
          toast.success("Promo linked to content successfully");
          onConfirm();
          fetchLinkIds();
        }),
      )
      .catch(
        errorHandler((err) => {
          console.error(err);
          setError("Something went wrong while contacting the server");
        }),
      )
      .finally(() => {
        setLoading(false);
      });
  }

  const columns = [
    {
      label: "Promo Name",
      key: "promo_name",
      value: "promo.promo_name",
      width: 30,
      renderValue: (row) => (
        <Link
          to={`/content/library/promo/${row.promo_guid}`}
          className={`data-table__cell__value ${!row.promo_active ? "data-table__cell__value--inactive" : ""}`}
          target="_blank"
          rel="noreferrer"
          onClick={() => document.activeElement.blur()}
        >
          {row.promo_name}
          {!row.promo_active ? " (inactive)" : ""}
        </Link>
      ),
    },
    {
      label: "",
      value: "",
      key: "0",
      width: 15,
      renderValue: (row) => {
        return row.promo_active && !linkIds.find((id) => id === row.promo_guid) ? (
          <span className="data-table__action-cell">
            <button
              className="btn btn--icon"
              onClick={() => onSubmit(row.promo_guid)}
              aria-label="Link Promo to content"
              data-balloon-pos="left"
            >
              <AddRoundedIcon />
            </button>
          </span>
        ) : null;
      },
    },
  ];

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width modal-width--larger">
        <div className="v-react-modal__heading">Select Promo to link</div>
        <div className="v-react-modal__body v-react-modal__body--table-container modal-body">
          {error ? (
            <p className="u-fc--warning">{error}</p>
          ) : (
            <DataTable
              url={`api/promos?${contentType === "channels" ? `channel_id=${contentGuid}` : ""}`}
              pagination={false}
              columns={columns}
              withSearch={true}
            />
          )}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--primary" onClick={onClose} key="close">
                Close
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
