import React from "react";
import { addSeconds } from "date-fns";
import useTimelineZoom from "./use-timeline-zoom.jsx";
import { getHeightStyleFromSeconds } from "./helpers/style-helpers.js";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { useSchedulerContext } from "../../providers/scheduler-context.jsx";

const SECONDS_PER_MARKER = 5 * 60; // 5 minutes per marker
const SECONDS_IN_A_NORMAL_DAY = 86400;

function getMarkerHeight(secondsPerSection, pixelsPerSection) {
  return getHeightStyleFromSeconds(SECONDS_PER_MARKER, secondsPerSection, pixelsPerSection);
}

function makeMarkers(planDate) {
  const numberOfMarkers = SECONDS_IN_A_NORMAL_DAY / SECONDS_PER_MARKER;
  let markers = [];
  let minutesFromStartDay = 0;
  const minutesPerStep = SECONDS_PER_MARKER / 60;

  for (let i = 0; i <= numberOfMarkers; i++) {
    const secondsToAdd = i * SECONDS_PER_MARKER;
    // do a 1 hour look ahead when searching for a timezone offset because clock's turning back is reported at the end of the hour
    const nextMarker = {
      value: minutesFromStartDay,
      dateValue: addSeconds(planDate, secondsToAdd),
    };

    markers.push(nextMarker);
    minutesFromStartDay += minutesPerStep;
  }

  return markers;
}

export default function CalendarMarkers({
  addBreak,
  containerRef,
  secondsPerSection,
  pixelsPerSection,
  popoutWidth,
  changeZoomLevel,
  readOnlyMode = false,
}) {
  const { scheduleStart, getRenderTime } = useSchedulerContext();
  const markers = React.useMemo(() => makeMarkers(scheduleStart), [scheduleStart]);
  const timelineItemsRef = React.useRef({});
  const { startDate, endDate } = useTimelineZoom(containerRef, timelineItemsRef);

  React.useEffect(() => {
    if (startDate && endDate) {
      changeZoomLevel(startDate, endDate);
    }
  }, [startDate, endDate, changeZoomLevel]);

  return (
    <div className="vertical-scheduler__marker-list">
      {markers.map((marker, index) => (
        <div
          className="vertical-scheduler-marker"
          key={marker.value}
          style={{ height: getMarkerHeight(secondsPerSection, pixelsPerSection) }}
        >
          <span className="vertical-scheduler-marker__inner" key={marker.dateValue}>
            <span
              ref={(el) => {
                timelineItemsRef.current[`${index}`] = el;
              }}
              className="vertical-scheduler-marker__text"
              data-marker-date={marker.dateValue}
              data-is-marker={"true"}
              data-marker-number={index}
            >
              {getRenderTime(marker.dateValue, "HH:mm")}
            </span>
            {!readOnlyMode ? (
              <button className="vertical-scheduler-marker__button" onClick={() => addBreak(marker.dateValue)}>
                <CircleRoundedIcon />
              </button>
            ) : null}
          </span>
          <span className="vertical-scheduler-marker__line" style={{ width: popoutWidth }}></span>
        </div>
      ))}
    </div>
  );
}
