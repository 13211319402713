import React from "react";
import Loader from "../common/loader.jsx";
import ContentCard from "./library/components/content-card.jsx";
import EpgTimeline from "./epg-editor/components/epg-timeline/epg-timeline.jsx";
import { planToEpg } from "./epg-editor/utils/epg-transformers.js";
import { toast } from "react-toastify";
import { addSeconds, endOfDay, format, startOfDay } from "date-fns";
import SchedulerProvider from "../providers/scheduler-context.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import axios from "../requests/axios.js";
import { HlsOrDashPlayer } from "../components/player/player.jsx";

function HomePage() {
  const videoHeightRef = React.useRef();
  const DEFAULT_DESTINATION = "media-tailor";

  const [activeChannel, setActiveChannel] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [planDate] = React.useState(new Date());
  const [_, setActiveProgram] = React.useState();
  const [channelPlans, setChannelPlans] = React.useState({});
  const [channels, setChannels] = React.useState({});
  const [_1, setActiveContent] = React.useState("channel");
  const [_2, setCardHeight] = React.useState();
  const [activeSource, setActiveSource] = React.useState({});

  const playerOptions = React.useMemo(
    () => ({
      source: activeSource.url,
      type: activeSource.type,
      destination: activeSource.destination,
      controls: {
        selectSource: false,
        changeSource: false,
        editCuepoints: false,
        playoutTrimming: false,
        timers: false,
      },
    }),
    [activeSource.url, activeSource.type, activeSource.destination],
  );

  React.useEffect(() => {
    setIsLoading(true);
    setChannelPlans({});

    axios
      .get(`/api/channels?plans=all&plan_date=${format(planDate, "yyyy-MM-dd")}`)
      .then((response) => {
        if (response?.data?.data) {
          setChannels(response.data.data);
          setActiveChannel(
            response.data.data.find((channel) => channel.display_name === "MBC") || response.data.data[0],
          );

          let channels = [];
          let epg = [];
          response.data.data.forEach((channel) => {
            if (channel.plans[0]?.programs.length) {
              channel.plans[0].programs.forEach((p) => {
                p.program_start = new Date(p.program_start);
                p.program_end = new Date(p.program_end);
                return p;
              });
            }

            const channelPlan = planToEpg(channel, channel.plans[0]);

            channels.push({ ...channelPlan.channel, url: channel.url });

            let previousProgram = null;

            for (let index = 0; index < channelPlan.epg.length; index++) {
              const program = channelPlan.epg[index];
              if (program.Type === "promo") {
                continue;
              }

              if (previousProgram) {
                previousProgram.till = program.since;
              }

              previousProgram = program;
              epg.push(program);
            }
          });

          setChannelPlans({
            channels: channels,
            epg: epg,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error loading channel plans. Please contact support.");
      })
      .finally(() => setIsLoading(false));
  }, [planDate]);

  React.useEffect(() => {
    setActiveSource({ url: activeChannel?.url || "", type: "hls", destination: DEFAULT_DESTINATION }); // assume media tailor by default
  }, [activeChannel?.url]);

  React.useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (videoHeightRef.current) {
        setCardHeight(videoHeightRef.current.clientHeight);
      }
    });

    if (videoHeightRef.current) {
      observer.observe(videoHeightRef.current);
    } else {
      // hack to make sure we avoid timing issues
      setTimeout(() => {
        if (videoHeightRef.current) {
          observer.observe(videoHeightRef.current);
        }
      }, 300);
    }

    return () => observer.disconnect();
  }, []);

  function selectProgram(program) {
    setActiveProgram(program.data);
    setActiveContent("program");
  }

  function selectChannel(channelGuid) {
    setActiveChannel(channels.find((channel) => channel.channel_id === channelGuid));
    setActiveContent("channel");
  }

  return (
    <SchedulerProvider
      currentTimezone="Europe/London"
      scheduleStart={
        activeChannel?.plans[0]?.plan_start ? new Date(activeChannel.plans[0].plan_start) : startOfDay(new Date())
      }
      scheduleEnd={
        activeChannel?.plans[0]?.plan_end
          ? new Date(activeChannel.plans[0].plan_end)
          : addSeconds(endOfDay(new Date()), 1)
      }
    >
      <AppBody>
        <div className="gw home-page">
          <div className="g g--1-of-1">
            <ContentCard noPadding withDarkestBackground>
              <div className="overlay-container u-flex-center u-width-100 u-height-100">
                <div className="overlay">
                  <div className="home-video-player-container">
                    <div className="video-player" ref={videoHeightRef}>
                      <HlsOrDashPlayer {...playerOptions} channelName={activeChannel?.name || "MBC"} />
                    </div>
                  </div>
                  <div className="overlay__element overlay__element--cover">
                    <div className="image image--cover">
                      <img src="/images/home-page-overlay.png" alt="" />
                    </div>
                  </div>
                  <div className="overlay__element overlay__element--cover">
                    <div className="u-flex--columns u-display--flex-apart u-height-100">
                      <div className="u-flex--center  u-display--flex-vh-center u-flex--grow">
                        <div className="image image--center">
                          <img src="/images/gstv-welcome.png" alt="" />
                        </div>
                      </div>
                      <div className="home-page-epg-container">
                        {isLoading ? (
                          <div className="page-loader-wrapper u-flex--grow">
                            <Loader />
                          </div>
                        ) : (
                          <div className="u-flex--grow">
                            <EpgTimeline
                              epg={channelPlans?.epg ?? []}
                              channels={channelPlans?.channels ?? []}
                              setActiveProgram={selectProgram}
                              setActiveChannel={selectChannel}
                              mode="view"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ContentCard>
          </div>
        </div>
      </AppBody>
    </SchedulerProvider>
  );
}

export default HomePage;
