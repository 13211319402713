import React from "react";
import { createPortal } from "react-dom";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import { toast } from "react-toastify";
import { addDays } from "date-fns";
import axios, { errorHandler, responseHandler } from "../../../../requests/axios.js";
import Loader from "../../../../common/loader.jsx";
import "react-datepicker/dist/react-datepicker.css";

function CopyPlanDialog({ planDate, isOpen, onClose, channelId, updateActiveDate }) {
  const [sourceDate, setSourceDate] = React.useState(new Date());
  const [targetDate, setTargetDate] = React.useState(new Date());
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");

  React.useEffect(() => {
    if (isOpen) {
      // prefill source date to current plan
      setSourceDate(planDate);

      // prefill target date to day after current plan
      const newTargetDate = addDays(new Date(planDate.getTime()), 1);
      setTargetDate(newTargetDate);
    }
  }, [isOpen, channelId, planDate]);

  function onSubmit() {
    setIsLoading(true);
    setLoadMessage("Copying...");

    const payload = {
      from_date: format(sourceDate, "y-MM-dd"),
      to_date: format(targetDate, "y-MM-dd"),
    };

    axios
      .put(`api/channels/${channelId}/plans/copy`, payload)
      .then(
        responseHandler(() => {
          onClose();
          toast.success("Plan copied successfully");
          updateActiveDate(targetDate);
        }),
      )
      .catch(
        errorHandler((e) => {
          console.error(e);
          if (e.data?.hasOwnProperty("api")) {
            setErrorMessage(e.data.api);
          } else {
            setErrorMessage("There was an error copying your plan, please contact support.");
          }
        }),
      )
      .finally(() => setIsLoading(false));
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-copy">
        <div className="v-react-modal__heading">Copy Plan</div>
        <div className="v-react-modal__body modal-copy__body">
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Copy from:</div>
            <div className="modal-copy__dropdown">
              <DatePicker
                selected={new Date(sourceDate)}
                onChange={(date) => setSourceDate(new Date(date))}
                popperContainer={({ children }) => createPortal(children, document.body)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Paste to:</div>
            <div className="modal-copy__dropdown">
              <DatePicker
                selected={new Date(targetDate)}
                onChange={(date) => setTargetDate(new Date(date))}
                minDate={addDays(new Date(), 1)}
                popperContainer={({ children }) => createPortal(children, document.body)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <p className="modal-copy__body__subtext u-fc--warning">
            This action will overwrite any existing programs on the target
          </p>
          {errorMessage ? <p className="modal-copy__body__subtext u-fc--warning">{errorMessage}</p> : null}
        </div>
        <div className="v-react-modal__footer" style={{ marginTop: "4px" }}>
          {isLoading ? (
            <React.Fragment>
              <div className="modal-copy__load-text">{loadMessage}</div>
              <Loader width={45} height={45} />
            </React.Fragment>
          ) : (
            [
              <button className="btn btn--inverse-primary" onClick={onClose} key="copy-plan-dialog-cancel">
                Cancel
              </button>,
              <button className="btn btn--primary" onClick={onSubmit} key="copy-plan-dialog-submit">
                Copy Schedule
              </button>,
            ]
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default CopyPlanDialog;
