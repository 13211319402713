import React from "react";
import Select from "../../../common/select.jsx";
import axios from "../../../requests/axios.js";
import debounce from "../../../common/debounce.js";

function KeywordFilter({ onChange, selectedKeywords }) {
  const [query, setQuery] = React.useState("");

  const loadOptions = () => {
    return new Promise((resolve, reject) => {
      debounce(() => {
        axios
          .get(`api/keywords?search=%${query}%`)
          .then((response) => {
            resolve(() => {
              return response.data
                .filter((item) => !!item)
                .map((item) => ({
                  label: item.charAt(0).toUpperCase() + item.slice(1),
                  value: item,
                }))
                .filter((option) => !selectedKeywords.find((kw) => kw.value === option.value));
            });
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      }, 200);
    });
  };

  return (
    <Select
      placeholder={selectedKeywords?.length ? `${selectedKeywords.length} Keywords Selected` : "Search Keywords..."}
      name={"keyword"}
      isSearchable={true}
      onChange={onChange}
      containerClass="react-select-container--light react-select-container--small"
      controlShouldRenderValue={false}
      onInputChange={(value) => setQuery(value)}
      noOptionsMessage={() => null}
      isAsync={true}
      loadOptions={loadOptions}
      components={{
        DropdownIndicator: () => null,
        LoadingMessage: () => "",
      }}
    />
  );
}

export default KeywordFilter;
