import React from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import TemplateScheduler from "./template-scheduler";
import { compareAsc, isBefore } from "date-fns";
import EpgDndHelper from "../epg-editor/utils/dnd-helpers";
import { ContentCopyRounded } from "@mui/icons-material";

function TemplateDay({
  day,
  refDate,
  channelBreaks,
  templateBreaks = [],
  channelId,
  setActiveContent,
  setActiveBlock,
  templateColumnWidth,
  insertionThreshold,
  addBlock = () => {},
  isDropDisabled = () => {},
  removeTemplateDay = () => {},
  copyTemplateDay = () => {},
  updateTemplateDay = () => {},
}) {
  const [dayItems, setItems] = React.useState(day.items);

  const dndHelper = React.useMemo(() => {
    return EpgDndHelper(channelId, refDate, templateBreaks);
  }, [channelId, templateBreaks, refDate]);

  // @TODO use update function from parent
  const updateItems = React.useCallback(
    (itemId, data) => {
      const itemIndex = day.items.findIndex((item) => item.drag_id === itemId);
      const nextItems = [...day.items.slice(0, itemIndex), data, ...day.items.slice(itemIndex + 1)];

      updateTemplateDay(day.day_number, {
        ...day,
        items: nextItems,
      });
    },
    [day, updateTemplateDay],
  );

  const removeItem = React.useCallback(
    (item) => {
      updateTemplateDay(day.day_number, {
        ...day,
        items: dndHelper.removeTemplateItem([...day.items], item),
      });
    },
    [day, dndHelper, updateTemplateDay],
  );

  React.useEffect(() => {
    if (templateBreaks.length) {
      setItems(() => {
        return dndHelper.recalculate(day.items);
      });
    }
  }, [channelBreaks, dndHelper, templateBreaks.length, day.items]);

  async function addDayBreak(startTime) {
    const newBreaks = [...day.breaks, { break_time: startTime, type: "plan" }].sort((a, b) => {
      return compareAsc(a.break_time, b.break_time);
    });

    updateTemplateDay(day.day_number, {
      ...day,
      breaks: newBreaks,
    });
  }

  async function deleteDayBreak(start) {
    const newBreaks = [...day.breaks];
    const indexToRemove = newBreaks.findIndex((dBreak) => dBreak.break_time === start);
    newBreaks.splice(indexToRemove, 1);

    updateTemplateDay(day.day_number, {
      ...day,
      breaks: newBreaks,
    });
  }

  function getIndexOfItemStartingAfterBreak(breakTime) {
    let programIndex = day.items.findIndex((s) => s.since.toString() === breakTime.toString());

    if (programIndex === -1) {
      // no programs start on the provided break, return the index of the program before the break
      programIndex = day.items.findLastIndex((s) => isBefore(s.since, breakTime)) + 1;
    }
    return programIndex < 0 ? day.items.length : programIndex;
  }

  function overwriteSegmentWith(items, breakTime, deleteCount) {
    const insertIndex = getIndexOfItemStartingAfterBreak(breakTime);
    const newItems = dndHelper.overwriteTemplateSegmentWith([...day.items], items, insertIndex, breakTime, deleteCount);

    updateTemplateDay(day.day_number, {
      ...day,
      items: newItems,
    });
  }

  return (
    <div className="template-day" style={{ width: templateColumnWidth }}>
      <div className="template-day__header">
        <div className="template-day__header__label">DAY {day.day_number}</div>
        <button className="btn btn--icon" onClick={() => removeTemplateDay(day.day_number)}>
          <CancelRoundedIcon />
        </button>
        <button className="btn btn--icon" onClick={() => copyTemplateDay(day.day_number)}>
          <ContentCopyRounded />
        </button>
      </div>
      <div className="template-day__body">
        <TemplateScheduler
          dayNumber={day.day_number}
          templateItems={dayItems}
          refDate={refDate}
          templateDayBreaks={templateBreaks}
          droppableId={`template_day-${day.day_number}`}
          isDropDisabled={isDropDisabled}
          addDayBreak={addDayBreak}
          deleteDayBreak={deleteDayBreak}
          updateItems={updateItems}
          removeItem={removeItem}
          setActiveContent={setActiveContent}
          setActiveBlock={(segments, item) => setActiveBlock(day.day_number, segments, item)}
          copySegment={overwriteSegmentWith}
          insertionThreshold={insertionThreshold}
          addBlock={addBlock}
        />
      </div>
    </div>
  );
}

export default TemplateDay;
