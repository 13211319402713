import React from "react";
import { useParams } from "react-router-dom";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import axios from "../requests/axios.js";
import { toast } from "react-toastify";
import formatApiErrors from "../functions/format-api-errors.js";
import useApiRequest from "../hooks/use-api-request.js";

function formatResponseDataToList(data) {
  const formFields = Object.keys(data.autofill_rules)
    .map((category) =>
      Object.keys(data.autofill_rules[category]).map((rule, index) => {
        return {
          category,
          name: rule,
          label: data.autofill_rules[category][rule].label,
          defaultValue: data.autofill_rules[category][rule].value,
          index: `${category}-${index}`,
        };
      }),
    )
    .flat();

  return formFields;
}

export default function ManageChannelAutofill() {
  // state
  const { channelGuid } = useParams();
  const [form, setForm] = React.useState([]);
  const fieldRefs = React.useRef([]);
  const [errors, setErrors] = React.useState({});

  const { isLoading, response, error } = useApiRequest(`/api/channels/${channelGuid}/autofill`);
  const [disableActions, setDisableActions] = React.useState(false);

  React.useEffect(() => {
    if (error) {
      toast.error("Unable to fetch Autofill information for this channel");
    }
  }, [error]);

  React.useEffect(() => {
    // setup form
    if (response.data) {
      const formFields = formatResponseDataToList(response.data);
      setForm(formFields);
    }
  }, [response]);

  const save = React.useCallback(() => {
    setDisableActions(true);
    const toaster = toast.loading("Loading...");
    const payload = fieldRefs.current
      .map((input) => {
        if (input.value === input.defaultValue) {
          return null;
        }

        return {
          key: input.name,
          category: input.getAttribute("data-category"),
          value: input.value,
        };
      })
      .filter((e) => !!e);

    if (!payload.length) {
      toast.dismiss(toaster);
      setTimeout(() => {
        toast.success("Saved successfully");
        setDisableActions(false);
      }, 350);
      return;
    }

    axios
      .post(`/api/channels/${channelGuid}/autofill`, { fields: payload })
      .then((response) => {
        const formFields = formatResponseDataToList(response.data);
        setForm(formFields);
      })
      .catch((error) => {
        console.error(error);
        if (error.status !== 422) {
          toast.error("Something went wrong trying to access the API");
          return;
        }

        setErrors(formatApiErrors(error.data.errors || error.data.error));
      })
      .finally(() => {
        setDisableActions(false);
        toast.dismiss(toaster);
        setTimeout(() => {
          toast.success("Saved successfully");
          setDisableActions(false);
        }, 350);
      });
  }, [channelGuid]);

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/admin/",
            title: "Admin",
          },
          {
            link: "/admin/channels",
            title: "Channels",
          },
          {
            title: response.data?.display_name ?? "...",
            link: `/admin/channels/${channelGuid}`,
          },
          {
            title: "Autofill",
            link: "",
          },
        ]}
        rightActions={[
          {
            balloonLabel: "Save",
            icon: SaveRoundedIcon,
            onClick: save,
            disabled: disableActions,
          },
        ]}
      />
      <AppBody loading={isLoading}>
        {!isLoading && (
          <div className="gw">
            {form?.length &&
              form.map((field, index) => (
                <div className="g g--1-of-1" key={field.index}>
                  <div className="gw">
                    <div className="g g--2-of-3">
                      <label>{field.label}</label>
                    </div>
                    <div className="g g--1-of-3">
                      <input
                        type="number"
                        name={field.name}
                        defaultValue={field.defaultValue}
                        data-category={field.category}
                        ref={(ref) => {
                          fieldRefs.current[index] = ref;
                        }}
                      />
                      {errors.hasOwnProperty(field.name) ? (
                        <div className="form-field__error">{errors.playlist_name}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </AppBody>
    </React.Fragment>
  );
}
