import { EventEmit } from "../utils/EventEmit.js";
import dashjs from "dashjs";
import { handleVideoEvents } from "../ads/adManager.js";
import { captureEvent, captureException } from "@sentry/browser";

export default class HbbTvPlayer {
  constructor(videoContainer, adContainer, protocol, logger, useLegacyWorkflow = false) {
    this.protocol = protocol;
    this.videoContainer = videoContainer;
    this.videoElement = videoContainer.querySelector("video");

    this.adContainer = adContainer;
    this.adElement = adContainer.querySelector("video");

    this.log = logger;

    this.player = dashjs.MediaPlayer().create();
    this.events = new EventEmit();
    this.IS_INITIALISED = false;
    this.use_legacy_flow = useLegacyWorkflow;
    this.frozen = false;

    // listeners
    this.__loadPlayer = this._loadPlayer.bind(this);
    this.__playbackEnded = this._playbackEnded.bind(this);
    this.__playbackError = this._playbackError.bind(this);
    this.__metaDataLoadedEffect = this._metaDataLoadedEffect.bind(this);
    this.__scheduleAdBreakEffect = this._scheduleAdBreakEffect.bind(this);
  }

  play(src, at = 0) {
    this.log.info("[PLAYER] Play called", "src: " + src, "at " + at);
    if (this.frozen) {
      this.log.info("[PLAYER] Frozen so will not play");
      return;
    }

    this.activeSrc = src;
    const delay = this.use_legacy_flow ? 2000 : 0; // 2s delay required for tv not to crash during transitions from mp4s to dash

    setTimeout(() => {
      this._setupPlayer(src, at);
      this._attachPlayerEvents();
      this._startPlayback();
    }, delay);
  }

  freeze() {
    if (this.player) {
      this.player.pause();
    }

    // just let the adverts play through, use case means that we are most likely just watching a slate anyway
    if (this.adElement) {
      this.adElement.muted = true;
    }

    this.frozen = true;
  }

  unfreeze(src, at) {
    const replay = this.frozen;
    this.frozen = false;

    if (replay) {
      this.play(src, at);
    }
  }

  playAdverts(adList, cuepoint) {
    this.log.info("[HbbtvPlayer] Play Adverts | Called for cuepoint " + cuepoint);
    if (this.frozen) {
      this.log.info("[HbbtvPlayer] Play Adverts | Not played because frozen");
      return;
    }

    this._scheduleAdBreak(adList, cuepoint);
  }

  getInformation() {
    let currentBuffer = "N/A";
    let bitrate = "N/A";
    let framerate = "N/A";
    let droppedFrames = "N/A";
    let droppedFramesTime = "N/A";
    let currentProgramSrc = this.activeSrc ?? "N/A";
    let currentTime = "N/A";
    let isPlayingAd;
    let isPlayingProgram;

    if (this.player && this.player.isReady() && this.player.getActiveStream()?.getStreamInfo()) {
      const streamInfo = this.player.getActiveStream().getStreamInfo();
      const periodIdx = streamInfo.index;
      const dashMetrics = this.player.getDashMetrics();
      const dashAdapter = this.player.getDashAdapter();
      const repSwitch = dashMetrics.getCurrentRepresentationSwitch("video", true);
      const adaptation = dashAdapter.getAdaptationForType(periodIdx, "video", streamInfo);
      const currentRep = adaptation.Representation_asArray.find(function (rep) {
        return rep.id === repSwitch?.to;
      });
      if (currentRep) {
        framerate = currentRep.frameRate;
      }

      const dropped = dashMetrics.getCurrentDroppedFrames();

      currentTime = this.player.getVideoElement()?.currentTime;
      currentBuffer = dashMetrics.getCurrentBufferLevel("video", true);
      bitrate = repSwitch ? Math.round(dashAdapter.getBandwidthForRepresentation(repSwitch.to, periodIdx) / 1000) : NaN;
      droppedFramesTime = dropped?.time;
      droppedFrames = dropped?.droppedFrames;
      currentProgramSrc = this.player.getSource();
      isPlayingProgram = !this.player.isPaused() && this.player.isReady() ? "yes" : "no";
    } else {
      isPlayingProgram = "no";
    }

    isPlayingAd =
      this.adElement && this.adElement.currentTime > 0 && !this.adElement.paused && !this.adElement.ended
        ? "yes"
        : "no";

    return {
      currentBuffer,
      currentTime,
      bitrate,
      framerate,
      droppedFrames,
      droppedFramesTime,
      currentProgramSrc,
      isPlayingAd,
      isPlayingProgram,
    };
  }

  async _playAdverts(adList) {
    this._toggleVisibility(this.adContainer, this.videoContainer);
    this._resetPlayer();
    for (let i = 0; i < adList.length; i++) {
      try {
        this.log.info("[HbbtvPlayer] Play Adverts | Setting up Ad Player");
        this._setupAdPlayer(adList[i].source);
        await this._attachAdListeners(adList[i].meta.ad.trackingEvents);
      } catch (e) {
        this.log.error("[HbbtvPlayer] Play Adverts | Setting up Ad Player Failed", e.toString());
        captureException(e);
      }
    }

    this._destroyAdPlayer();
    return true;
  }

  _loadPlayer() {
    if (this.player) {
      this.player.off("streamInitialized", this.__loadPlayer);
    }
    if (!this.IS_INITIALISED) {
      this.IS_INITIALISED = true;
      this.log.info("[HbbtvPlayer] Load Player | Player initialised");
      this.events.emit("INITIALISED");
    }
  }

  _setupPlayer(src, seekTime) {
    this.log.info("[HbbtvPlayer] Setup Player | Creating Player ");
    this.player.initialize(this.videoElement, src, true, seekTime);
    this.player.updateSettings({
      streaming: {
        buffer: {
          bufferPruningInterval: 5, // bufferPruningInterval: 10,
          bufferToKeep: 10, // bufferToKeep: 20,
          stableBufferTime: 12, // stableBufferTime: 12,
          bufferTimeAtTopQuality: 12, // bufferTimeAtTopQuality: 30,
          bufferTimeAtTopQualityLongForm: 12, // bufferTimeAtTopQualityLongForm: 60,
          // initialBufferLevel: NaN,
        },
        gaps: {
          enableStallFix: true,
        },
      },
      errors: {
        recoverAttempts: {
          mediaErrorDecode: 10,
        },
      },
    });

    // sanity check because some players don't seem to correctly attach the source
    if (!this.player.isReady()) {
      try {
        this.player.getSource();
      } catch (e) {
        // get source threw an exception, this means that source was not set, let's call it directly
        this.player.attachSource(src, seekTime);
      }
    }

    return this.player;
  }

  _resetPlayer() {
    this.player.reset();
  }

  _attachPlayerEvents() {
    // remount events if player is already loaded
    if (this.player) {
      this.player.off("streamInitialized", this.__loadPlayer);
      this.player.off(dashjs.MediaPlayer.events.PLAYBACK_ENDED, this.__playbackEnded);
      this.player.off(dashjs.MediaPlayer.events.ERROR, this.__playbackError);
      this.player.off(dashjs.MediaPlayer.events.PLAYBACK_ERROR, this.__playbackError);
    }

    this.log.info("[HbbtvPlayer] Attach Player Events");
    this.player.on("streamInitialized", this.__loadPlayer);
    this.player.on(dashjs.MediaPlayer.events.PLAYBACK_ENDED, this.__playbackEnded);
    this.player.on(dashjs.MediaPlayer.events.ERROR, this.__playbackError);
    this.player.on(dashjs.MediaPlayer.events.PLAYBACK_ERROR, this.__playbackError);
  }

  _playbackEnded() {
    if (!this.player) {
      this.log.error("[HbbtvPlayer] Playback Ended | BUT NO PLAYBACK ENDED");
      return;
    }

    this.log.info("[HbbtvPlayer] Playback Ended");
    this.events.emit("PLAYBACK_ENDED");
  }

  _playbackError({ type, error, e }) {
    let message = error;
    if (!message) {
      message = e;
    }

    if (typeof message !== "string" && typeof message !== "number" && typeof message === "object" && message !== null) {
      if (message.toString().includes("[object ")) {
        message = JSON.stringify(message);
      } else {
        message = message.toString();
      }
    }

    captureEvent({
      message: "Playback Error Encountered",
      extra: { type, message, error, e },
    });
    this.log.error("Error encountered in playback", type, error, e);
  }

  _toggleVisibility(visible, hidden) {
    visible.classList.remove("container-hidden");
    visible.classList.add("container-shown");

    hidden.classList.remove("container-shown");
    hidden.classList.add("container-hidden");
  }

  _setupAdPlayer(source) {
    this.log.info("[HbbtvPlayer] Setup Ad Player");
    if (!this.adElement) {
      this.adElement = document.createElement("video");
      this.adElement.id = "gstv-hbbtv-ad-video";
      this.adElement.setAttribute("type", "video/mp4");
      // this.adElement.setAttribute('autoPlay', 'true');
      this.adElement.setAttribute("preload", "auto");
      this.adElement.setAttribute("crossorigin", "anonymous");
      this.adElement.setAttribute("disablePictureInPicture", "true");
      this.adElement.setAttribute("controlsList", "nodownload");
      this.adContainer.appendChild(this.adElement);
    }

    if (this.videoElement.classList.contains("test-vid-shown")) {
      this.videoElement.classList.remove("test-vid-shown");
      this.videoElement.classList.add("test-vid-hidden");
      this.adElement.classList.add("test-vid-shown");
      this.adElement.classList.remove("test-vid-hidden");
    }

    this.adElement.muted = false;
    this.adElement.setAttribute("src", source);
    this.adElement.load();

    Promise.resolve(this.adElement.play())
      .then(() => {})
      .catch((err) => {
        this.log.error(err);
      });
  }

  _attachAdListeners(trackingEvents) {
    this.log.info("[HbbtvPlayer] Attach Ad Listeners");
    return new Promise((resolve) => {
      handleVideoEvents(this.adElement, trackingEvents, () => {
        resolve();
      });
    });
  }

  _destroyAdPlayer() {
    this.log.info("[HbbtvPlayer] Destroy Ad Player");
    this.adElement.pause();
    this.adElement.setAttribute("src", "");
    this.adElement.removeAttribute("src");
    this.adElement.load();
  }

  _startPlayback() {
    this.log.info("[HbbtvPlayer] Start Playback");
    this._toggleVisibility(this.videoContainer, this.adContainer);
    this.events.emit("PLAYBACK_STARTING");

    // optimistically set time immediately
    this._metaDataLoadedEffect();
    this.player.on(dashjs.MediaPlayer.events.PLAYBACK_METADATA_LOADED, this.__metaDataLoadedEffect);

    // don't hang on to the event for too long
    setTimeout(() => {
      if (this.player) {
        this.player.off(dashjs.MediaPlayer.events.PLAYBACK_METADATA_LOADED, this.__metaDataLoadedEffect);
      }
    }, 5000);
  }

  _metaDataLoadedEffect() {
    this.log.info("[HbbtvPlayer] Meta Data Loaded Effect");
    if (!this.player.isReady()) {
      let message = "[HbbtvPlayer] Meta Data Loaded Effect called because ";
      try {
        this.player.getSource();
      } catch (e) {
        message += "SOURCE is not set ";
      }

      try {
        this.player.getVideoElement();
      } catch (e) {
        message += "VIDEO_ELEMENT is not set ";
      }

      this.log.error(message);
      captureException(new Error(message));
      return;
    }

    this.player.play();
    Promise.resolve(this.videoElement.play())
      .then(() => {})
      .catch((err) => {
        this.log.error(err);
      });
  }

  _scheduleAdBreak(adList, cuepoint, attempts = 0) {
    // if no player, we cannot play adverts
    if (!this.player) {
      if (this.cuepoint === cuepoint || attempts >= 10) {
        // this cuepoint is already scheduled, or we have attempted too many times
        return;
      }

      // player is not available right now, let's try again every second for 10 seconds
      return setTimeout(() => {
        this._scheduleAdBreak(adList, cuepoint, attempts + 1);
      }, 1000);
    }

    this.adList = adList;
    this.cuepoint = cuepoint;
    this.resumeAt = cuepoint;
    this.player.on(dashjs.MediaPlayer.events.PLAYBACK_TIME_UPDATED, this.__scheduleAdBreakEffect);
  }

  _scheduleAdBreakEffect({ time }) {
    if (this.cuepoint !== null && this.adList !== null && Math.floor(time) >= this.cuepoint) {
      const adList = this.adList;
      const resumeAt = this.resumeAt;

      // clean up
      this.cuepoint = null;
      this.adList = null;
      this.resumeAt = null;
      if (this.player) {
        this.player.off(dashjs.MediaPlayer.events.PLAYBACK_TIME_UPDATED, this.__scheduleAdBreakEffect);
      }

      this._playAdverts(adList)
        .then(() => {})
        .catch((error) => {
          // resume
          this.log.error("[HbbtvPlayer] Play Adverts | FAILED", error.toString());
        })
        .finally(() => {
          this.log.info("[HbbtvPlayer] Play Adverts | Resuming Playback");
          this.play(this.activeSrc, resumeAt);
        });
    }
  }
}
