import React from "react";
import ReactModal from "react-modal";

export default function ConfirmationDialog({ heading, body, submit, close, isOpen }) {
  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-copy">
        <div className="v-react-modal__heading">{heading}</div>
        <div className="v-react-modal__body modal-copy__body">{body}</div>
        <div className="v-react-modal__footer">
          <button className="btn btn--primary" onClick={close}>
            Cancel
          </button>
          <button className="btn btn--inverse" onClick={submit}>
            Go ahead
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
