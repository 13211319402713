import React from "react";
import ReactModal from "react-modal";
import Loader from "../../../common/loader.jsx";
import { toast } from "react-toastify";
import axios, { errorHandler, responseHandler } from "../../../requests/axios.js";

function RestoreOrRemoveOrganisationDialog({ id, message, onClose, onSuccess, action, isOpen }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  function onSubmit() {
    setIsLoading(true);
    if (action === "delete") {
      axios
        .delete(`/api/organisations/${id}`)
        .then(
          responseHandler(() => {
            onClose();
            toast.success("Organisation deleted successfully.");
            setTimeout(onSuccess, 1000);
          }),
        )
        .catch(
          errorHandler((error) => {
            console.error(error);
            setErrorMessage("There was an error deleting this Organisation. Please contact support.");
          }),
        )
        .finally(() => setIsLoading(false));
    } else if (action === "restore") {
      axios
        .post(`/api/organisations/${id}/restore`)
        .then(
          responseHandler(() => {
            onClose();
            toast.success("Organisation restored successfully.");
            setTimeout(onSuccess, 1000);
          }),
        )
        .catch(
          errorHandler((error) => {
            console.error(error);
            setErrorMessage("There was an error restoring this Organisation. Please contact support.");
          }),
        )
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__heading">
          {action === "delete" ? "Delete Organisation" : "Restore Organisation"}
        </div>
        <div className="v-react-modal__body">
          {message}
          {errorMessage ? <p className="u-fc--warning">{errorMessage}</p> : null}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={onSubmit}>
                Confirm
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default RestoreOrRemoveOrganisationDialog;
