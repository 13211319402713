import React from "react";
import { getHeightStyleFromSeconds } from "./helpers/style-helpers";
import { differenceInSeconds } from "date-fns";
import { getHeightFromSeconds } from "./helpers/scheduler-helpers";
import { InfoRounded } from "@mui/icons-material";
import axios from "../../requests/axios";

function makeFilterBlocks(blocks) {
  let filterBlocks = blocks.map((block) => {
    const start = new Date(block.starts_at);
    const end = new Date(block.ends_at);

    return {
      start,
      end,
      definitions: block.definitions,
      label: block.filter_label || "Filter Block",
      duration: differenceInSeconds(end, start),
    };
  });
  return filterBlocks;
}

function CalendarFilterBlocks({ epg, planDate, blocks, secondsPerSection, pixelsPerSection, applyFilters }) {
  const markers = React.useMemo(() => makeFilterBlocks(blocks), [blocks]);
  const [genres, setGenres] = React.useState([]);
  const currentHoveredInfoElement = React.useRef();

  const containsProgram = React.useCallback(
    (start, end) => epg.some((program) => program.since >= start && program.till <= end),
    [epg],
  );

  React.useEffect(() => {
    if (!genres.length) {
      axios
        .get(`api/genres`)
        .then((response) => {
          let newGenres = response.data.imdb.map((item) => ({
            label: item.label,
            value: item.value,
            id: item.genre_imdb_id,
            subgenres: item.subgenres.map((subgenre) => ({
              label: subgenre.label,
              value: subgenre.value,
              id: subgenre.subgenre_imdb_id,
            })),
          }));
          setGenres(newGenres);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [genres]);

  function onFilterBlockClick(definitions, blockName, start, end) {
    applyFilters({
      blockName,
      filters: processFilters(definitions),
      start,
      end,
    });
  }

  function processFilters(definitions) {
    const definitionsObject = {
      providers: [],
      keywords: [],
      ratings: [],
      genres: [],
      subgenres: [],
    };

    // need to process genre first then do nothing when we loop into it again.
    let imdbGenre = definitions.find((def) => def.type === "genre_imdb");
    if (imdbGenre) {
      imdbGenre = genres.find((genre) => genre.id === +imdbGenre.value);
      definitionsObject.genres.push(imdbGenre.value);
    }

    definitions.forEach((def) => {
      switch (def.type) {
        case "content_type": {
          definitionsObject.content_type = def.value === "series" ? def.value : `${def.value}s`;
          break;
        }

        case "provider":
          definitionsObject.providers.push(def.value);
          break;

        case "rating_us_tv":
        case "rating_bbfc":
        case "rating_mpaa": {
          let pieces = def.type.split("_");
          pieces.shift();
          pieces = pieces.join("_");

          definitionsObject.ratings.push(`${pieces}_${def.value}`);
          break;
        }

        case "keyword":
          definitionsObject.keywords.push(def.value);
          break;

        // can only have a subgenre if we have a genre, so find genre first (see above)
        case "genre_imdb":
          break;
        case "subgenre_imdb": {
          if (definitionsObject.genres.length) {
            const genre = genres.find((genre) => genre.value === definitionsObject.genres[0]); // assumes only one genre
            const subgenre = genre.subgenres.find((subgenre) => subgenre.id === +def.value);
            if (subgenre) {
              definitionsObject.subgenres.push(subgenre.value);
            }
          }
          break;
        }

        case "duration_min":
        case "duration_max":
          definitionsObject[def.type] = +def.value;
          break;

        default:
          definitionsObject[def.type] = def.value;
          break;
      }
    });

    let correctedSorts = [{ value: "title", order: "asc" }];
    if (definitionsObject.content_type === "episodes") {
      // we need to patch the value when sortOrder value of title is used, since a bit extra is required in the url
      const order = correctedSorts[0].order;
      correctedSorts = [
        ...correctedSorts.slice(0, 0),
        ...[
          {
            value: "series_name",
            order,
          },
          {
            value: "season_number",
            order: "asc",
          },
          {
            value: "episode_number",
            order: "asc",
          },
        ],
        ...correctedSorts.slice(0 + 1),
      ];
    }
    let sorting = correctedSorts.map((sortItem) => `${sortItem.value}.${sortItem.order}`);

    // default sorting for episodes
    if (!sorting.length && definitionsObject.content_type === "episodes") {
      sorting = ["series_name.asc", "season_number.asc", "episode_number.asc"];
    }

    return {
      content: definitionsObject.content_type,
      search: definitionsObject.search,
      providerGuids: definitionsObject.providers,
      ratings: definitionsObject.ratings,
      genres: definitionsObject.genres,
      subgenres: definitionsObject.subgenres,
      seriesGuid: definitionsObject.series,
      seasonGuid: definitionsObject.season,
      minDuration: definitionsObject.duration_min,
      maxDuration: definitionsObject.duration_max,
      keywords: definitionsObject.keywords,
      sorting,
      displayInactive: false, // in context of scheduler
      displayRestricted: false, // in context of scheduler
    };
  }

  const onMouseLeave = React.useCallback(() => {
    if (currentHoveredInfoElement.current) {
      currentHoveredInfoElement.current.remove();
      currentHoveredInfoElement.current = null;
    }
  }, []);

  const onMouseOver = React.useCallback(
    (index) => {
      if (currentHoveredInfoElement.current) {
        return;
      }

      const target = document.querySelector(".vertical-scheduler"); // @todo change this to a ref
      const element = document.querySelector(
        `[data-filter-block-index="filter-block-${index}"] .vertical-scheduler-filter-block__summary`,
      );
      const copy = element.cloneNode(false);
      copy.style.position = "fixed";
      copy.style.zIndex = 999;
      copy.style.top = element.getBoundingClientRect().top + "px";
      copy.style.left = element.getBoundingClientRect().left + "px";
      copy.style.width = element.getBoundingClientRect().width + "px";
      copy.style.height = element.getBoundingClientRect().height + "px";
      copy.setAttribute("aria-label", element.getAttribute("data-balloon-copy-label"));
      copy.setAttribute("data-balloon-pos", element.getAttribute("data-balloon-copy-pos"));
      copy.setAttribute("data-balloon-visible", "true");
      copy.addEventListener("mouseleave", onMouseLeave);
      currentHoveredInfoElement.current = copy;
      target.appendChild(copy);
    },
    [onMouseLeave],
  );

  return (
    <div className="vertical-scheduler__filter-list">
      {markers.map((filterBlock, index) => (
        <div // @TODO make this a button
          onClick={() =>
            onFilterBlockClick(filterBlock.definitions, filterBlock.label, filterBlock.start, filterBlock.end)
          }
          className={`vertical-scheduler-filter-block ${containsProgram(filterBlock.start, filterBlock.end) ? "vertical-scheduler-filter-block--contains-programs" : ""}`}
          data-filter-block-index={`filter-block-${index}`}
          style={{
            height: getHeightStyleFromSeconds(filterBlock.duration, secondsPerSection, pixelsPerSection),
            top: getHeightFromSeconds(
              secondsPerSection,
              pixelsPerSection,
              differenceInSeconds(filterBlock.start, planDate),
            ),
          }}
          key={`filter-${index}`}
        >
          <div className={`vertical-scheduler-filter-block__inner`}>
            {!containsProgram(filterBlock.start, filterBlock.end) ? (
              <div className="vertical-scheduler-filter-block__text">{filterBlock.label}</div>
            ) : (
              <span
                className="vertical-scheduler-filter-block__summary"
                data-balloon-copy-label={filterBlock.label}
                data-balloon-copy-pos={"right"}
                onMouseOver={() => onMouseOver(index)}
                // onMouseLeave={() => onMouseLeave(index)}
              >
                <InfoRounded />
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CalendarFilterBlocks;
