export class Ad {
  constructor(id, sequence, adTitle, creativeId, duration, trackingEvents, media) {
    this.id = id;
    this.sequence = sequence;
    this.adTitle = adTitle;
    this.creativeId = creativeId;
    this.duration = duration;
    this.trackingEvents = trackingEvents;
    this.media = media;
  }
}
