import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { formatSeconds } from "../../pages/epg-editor/utils/format-seconds.js";
import composeRefs from "@seznam/compose-react-refs/composeRefs.js";
import ReactInputMask from "react-input-mask";
import { getSecondsFromHis } from "../../pages/epg-editor/utils/create-ad-breaks.js";
import { toast } from "react-toastify";
import { useSchedulerContext } from "../../providers/scheduler-context.jsx";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import StraightIcon from "@mui/icons-material/Straight";
import { getMaximumDuration } from "../../common/programs/utils.js";
import useResizableSchedulerContent from "../../hooks/scheduler/use-resizable-scheduler-content.jsx";
import { isPast } from "date-fns";

function DraggableProgram({
  program,
  segment,
  updateDuration,
  onProgramClick,
  removeProgram,
  secondsPerSection,
  pixelsPerSection,
  index,
  onProgramDrag,
  getHeightFromSeconds,
  smallDuration,
  smallOffset,
  prependMargin = 0,
}) {
  const [isResizing, setIsResizing] = React.useState(false);
  const [showResizeInput, setResizeInput] = React.useState(false);
  const [programResizedDuration, setProgramResizedDuration] = React.useState(
    formatSeconds(program.__gstvMeta.total_duration_seconds),
  );
  const resizingDuration = React.useRef();
  const resizingTill = React.useRef();
  const programRef = React.useRef();
  const resizeInputRef = React.useRef();
  const { getRenderTime } = useSchedulerContext();

  const { onMouseDown } = useResizableSchedulerContent({
    content: program,
    contentRef: programRef,
    updateDuration,
    segment,
    setIsResizing,
    onClick: onResizeClick,
    pixelsPerSection,
    secondsPerSection,
    getHeightFromSeconds,
    formatTimeForTimezone: getRenderTime,
    resizingLabelRef: resizingTill,
    resizingDurationRef: resizingDuration,
  });

  // ensure programResizedDuration is always up to date
  React.useEffect(() => {
    setProgramResizedDuration(formatSeconds(program.__gstvMeta.total_duration_seconds));
  }, [program.__gstvMeta.total_duration_seconds]);

  function getHeightStyleFromSeconds(seconds) {
    return `${getHeightFromSeconds(seconds)}px`;
  }

  function programExceedsSegmentLength(segmentEnd, programEnd) {
    return programEnd > segmentEnd;
  }

  function programInactive(program) {
    return !program.isActive;
  }

  function onResizeInputKeyPress(e) {
    if (e.key === "Enter") {
      commitResize(getSecondsFromHis(resizeInputRef.current.value));
    }

    if (e.key === "Escape") {
      cancelResize();
    }
  }

  function onResizeClick(e) {
    e.preventDefault();
    if (isPast(program.since)) {
      toast.error("Cannot change programs in the past.");
      return;
    }

    setResizeInput(true);
    setIsResizing(true);

    resizeInputRef.current.focus();
    resizeInputRef.current.addEventListener("keydown", onResizeInputKeyPress);
    window.addEventListener("keypress", onResizeInputKeyPress);
    window.addEventListener("mouseup", cancelResize);
  }

  function cancelResize() {
    setResizeInput(false);
    setIsResizing(false);
    window.removeEventListener("mouseup", cancelResize);
  }

  function updateProgramResizedDuration(duration) {
    // resize duration cannot be longer than program, and cannot be less han 0
    if (
      getSecondsFromHis(duration) > program.__gstvMeta.original_total_duration_seconds ||
      getSecondsFromHis(duration) < 0
    ) {
      setProgramResizedDuration(formatSeconds(program.__gstvMeta.original_total_duration_seconds));
      return;
    }

    setProgramResizedDuration(duration);
  }

  function commitResize(commitValue = null) {
    const nextDuration = commitValue !== null ? commitValue : getSecondsFromHis(programResizedDuration);
    updateDuration(program.id, nextDuration);
    closeResizeInput();
  }

  function closeResizeInput() {
    resizeInputRef.current.removeEventListener("keypress", onResizeInputKeyPress);
    window.removeEventListener("keypress", onResizeInputKeyPress);
    window.removeEventListener("mouseup", cancelResize);
    setResizeInput(false);
    setIsResizing(false);
  }

  const isSmall = getHeightFromSeconds(program.__gstvMeta.total_duration_seconds) < smallDuration;
  const isPastState = isPast(program.since);
  const isTrimmed =
    Math.round(program.__gstvMeta.total_duration_seconds) <
    Math.round(getMaximumDuration(program.__gstvMeta.original_duration_seconds, program.__gstvMeta.ad_breaks));
  let transformStyle = {};

  if (isSmall) {
    const offsets = [-150, -50, 50];
    // offset a promo when it's at the start of a segment and the previous segment ended with a promo
    const promoOffset = program.hasPreviousPromo && index === 0 ? 1 : 0;
    transformStyle = {
      transform: `translateX(${offsets[(smallOffset[0] + 1 + promoOffset) % offsets.length]}%) translateY(${-50 + 100 * smallOffset[1]}%)`,
    };
  }

  return (
    <div className="vertical-scheduler__program">
      <Draggable
        key={program.id}
        draggableId={`vertical-epg-${program.id}`}
        index={index}
        isDragDisabled={isPast(program.since) || isResizing}
      >
        {(provided) => {
          // programRef.current = provided.innerRef.current;
          return (
            <div
              ref={composeRefs(programRef, provided.innerRef)}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                height: getHeightStyleFromSeconds(program.__gstvMeta.total_duration_seconds),
                marginTop: `${prependMargin}px`,
                ...provided.draggableProps.style,
              }}
              onMouseDown={onProgramDrag}
              className={`vertical-scheduler-program ${isSmall ? "vertical-scheduler-program--small" : ""} ${
                programExceedsSegmentLength(segment.end, program.till) ? "vertical-scheduler-program--exceeded" : ""
              } ${programInactive(program) ? "vertical-scheduler-program--inactive" : ""} ${
                isPastState ? "vertical-scheduler-program--is-past" : ""
              }`}
              {...(programInactive(program)
                ? {
                    "aria-label": "Program is inactive and must be removed.",
                    "data-balloon-pos": "up",
                  }
                : {
                    onClick: onProgramClick.bind(null, program),
                  })}
            >
              {isSmall ? (
                <div
                  className={`vertical-scheduler-program__inner vertical-scheduler-program__inner--small ${program.Type !== "promo" ? "vertical-scheduler-program__inner--primary" : ""}`}
                  style={transformStyle}
                >
                  <div className="vertical-scheduler-program__content">
                    <span>{getRenderTime(program.till, "HH:mm:ss")}</span>
                    <div className="vertical-scheduler-program__actions">
                      {!isPastState && (
                        <button
                          className="btn--text-primary vertical-scheduler__remove-button"
                          onClick={() => {
                            removeProgram(program);
                          }}
                        >
                          <CancelRoundedIcon />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className="vertical-scheduler-program__inner">
                    <div className="vertical-scheduler-program__overflow-container">
                      <div className="vertical-scheduler-program__content">
                        <span>{`${program.title} (${getRenderTime(program.since, "HH:mm:ss")} to `}</span>
                        {isResizing ? (
                          <span ref={resizingTill}>{getRenderTime(program.till, "HH:mm:ss")}</span>
                        ) : (
                          <span>{getRenderTime(program.till, "HH:mm:ss")}</span>
                        )}
                        {")"}
                      </div>
                      <div className="vertical-scheduler-program__actions">
                        {!isPastState && (
                          <button
                            className="btn--text-primary vertical-scheduler__remove-button"
                            onClick={() => {
                              removeProgram(program);
                            }}
                          >
                            <CancelRoundedIcon />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {program.Type !== "promo" ? (
                    <div
                      className={`vertical-scheduler-program__bottom ${isResizing ? "vertical-scheduler-program__left--active" : ""} ${isTrimmed ? "vertical-scheduler-program__bottom--trimmed" : ""}`}
                    >
                      <div className="vertical-scheduler-program__bottom__left">
                        <button
                          className="btn btn--reset vertical-scheduler-program__bottom__btn"
                          onMouseDown={onMouseDown}
                        >
                          <span className="vertical-scheduler-program__info">
                            {isResizing ? (
                              <span
                                className={`vertical-scheduler-program__info__text vertical-scheduler-program__info__text--${isResizing ? "visible" : "hidden"}`}
                                ref={resizingDuration}
                              >
                                {getRenderTime(program.till, "HH:mm:ss")}
                              </span>
                            ) : null}
                            <span
                              className={`vertical-scheduler-program__info__text vertical-scheduler-program__info__text--${isResizing ? "hidden" : "visible"}`}
                            >
                              {getRenderTime(program.till, "HH:mm:ss")}
                            </span>
                          </span>
                          {!isPastState && (
                            <React.Fragment>
                              <span
                                className={`vertical-scheduler-program__resize ${isTrimmed ? "vertical-scheduler-program__resize--trimmed" : ""}`}
                              >
                                <StraightIcon />
                              </span>
                            </React.Fragment>
                          )}
                        </button>
                        <div
                          className={`vertical-scheduler-program__resize-input ${
                            showResizeInput ? "vertical-scheduler-program__resize-input--active" : ""
                          }`}
                        >
                          <ReactInputMask
                            mask={"99:99:99"}
                            value={programResizedDuration}
                            onChange={(e) => {
                              updateProgramResizedDuration(e.target.value);
                            }}
                            ref={resizeInputRef}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              )}
            </div>
          );
        }}
      </Draggable>
    </div>
  );
}

export default DraggableProgram;
