import React from "react";
import { intervalToDuration } from "date-fns";
import formatDuration from "date-fns/formatDuration";
import { Draggable } from "react-beautiful-dnd";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";

export default function TemplateSeriesItem({ item, index, addItem }) {
  const [isAdded, setAdded] = React.useState(false);

  function addItemWrapper(...params) {
    setAdded(true);
    addItem(...params);
  }

  return (
    <Draggable draggableId={`template_content_list_item-${index}`} index={index}>
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="library-list__item"
          style={{
            ...provided.draggableProps.style,
          }}
          // onDoubleClick
        >
          <span className="content-item__description">
            <span className="content-item__heading">{item.series_name}</span>

            <span className="content-item__provider">
              <span className="content-item__provider__inner">{item.title.provider.provider_name}</span>
            </span>

            <span className="content-item__sub-heading">
              <span>
                {`Max duration: ${formatDuration(
                  intervalToDuration({
                    start: 0,
                    end: item.duration * 1000,
                  }),
                )} (${formatDuration(
                  intervalToDuration({
                    start: 0,
                    end: item.total_duration * 1000,
                  }),
                )})`}
              </span>
            </span>
          </span>
          <div className="content-item__col">
            <span className="content-item__actions">
              <span className={`content-item__action ${!item.duration ? "content-item__action--disabled" : ""}`}>
                <button
                  className="btn--text-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addItemWrapper(item);
                    return true;
                  }}
                >
                  {isAdded ? <CheckBoxRoundedIcon /> : <AddBoxRoundedIcon />}
                </button>
              </span>
            </span>
          </div>
        </li>
      )}
    </Draggable>
  );
}
