import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ChannelHeadingChannelDropdown from "./channel-heading/channel-heading-channel-dropdown.jsx";
import ChannelHeadingDatepicker from "./channel-heading/channel-heading-datepicker.jsx";
import ChannelHeadingTimezone from "./channel-heading/channel-heading-timezone.jsx";

function SimpleChannelHeading({ channelName, planDate, changePlanDate, toggleTimezone, channelGuid, timezone }) {
  if (!planDate) {
    return;
  }

  return (
    <div className="channel-heading" key={"header"}>
      <div className="channel-heading__back">
        <Link className="link--base" to={`/channels/${channelGuid}`}>
          <ArrowBackIosRoundedIcon />
        </Link>
      </div>

      <ChannelHeadingChannelDropdown label={channelName} />

      <ChannelHeadingDatepicker planDate={planDate} updatePlanDate={changePlanDate} />

      <ChannelHeadingTimezone timezone={timezone} toggleTimezone={toggleTimezone} />
    </div>
  );
}

export default SimpleChannelHeading;
