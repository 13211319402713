import React from "react";
import { Epg, Layout, useEpg } from "planby";
import { theme } from "./theme.js";
import EpgProgram from "./epg-program.jsx";
import EpgChannel from "./epg-channel.jsx";
import TimelineWrapper from "./timeline-wrapper.jsx";
import { useSchedulerContext } from "../../../../providers/scheduler-context.jsx";

export default function EpgTimeline({
  isLoading,
  epg,
  channels,
  setActiveProgram = () => null,
  setActiveChannel = () => null,
  removeProgram,
  onPlay,
  mode = "",
}) {
  const { scheduleStart, scheduleEnd, getRenderTime } = useSchedulerContext();
  const epgData = epg.map((epgProgram) => ({
    ...epgProgram,
    since: epgProgram.since,
    till: epgProgram.till,
  }));

  // planby component props
  const { getEpgProps, getLayoutProps } = useEpg({
    channels,
    epg: epgData,
    dayWidth: 19200,
    itemHeight: 80,
    isSidebar: true,
    isTimeline: true,
    isLine: true,
    startDate: getRenderTime(scheduleStart, "y-MM-dd'T'HH:mm:ss"),
    endDate: getRenderTime(scheduleEnd, "y-MM-dd'T'HH:mm:ss"),
    isBaseTimeFormat: true,
    theme,
  });

  return (
    <Epg isLoading={isLoading} {...getEpgProps()}>
      <Layout
        {...getLayoutProps()}
        isProgramVisible={() => {
          return true;
        }}
        isChannelVisible={() => true}
        renderTimeline={(props) => <TimelineWrapper {...props} numberOfHoursInDay={24} hourWidth={800} />}
        renderProgram={({ program, ...rest }) => {
          return (
            <EpgProgram
              key={program.data.id}
              program={program}
              onProgramClick={setActiveProgram}
              removeProgram={removeProgram}
              onPlay={onPlay}
              mode={mode}
              {...rest}
            />
          );
        }}
        renderChannel={({ channel }) => (
          <div key={channel.uuid}>
            <EpgChannel key={channel.uuid} channel={channel} setActiveChannel={setActiveChannel} />
          </div>
        )}
      />
    </Epg>
  );
}
