import React, { createContext } from "react";
import axios from "../requests/axios";
import { useAuth } from "./auth-provider.jsx";

function useAppProvider(authUser) {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    if (authUser) {
      axios
        .get(`api/users/me`)
        .then((user) => {
          setUser(user);
        })
        .catch(() => {});
    }
  }, [authUser]);

  return {
    user,
  };
}

const AppContext = createContext({});

export function useAppContext() {
  return React.useContext(AppContext);
}

export default function AppProvider({ children }) {
  const authContext = useAuth();
  const context = useAppProvider(authContext.user);
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}
