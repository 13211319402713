import React from "react";
import axios, { errorHandler, responseHandler } from "../requests/axios.js";
import { toast } from "react-toastify";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import { AgGridReact } from "ag-grid-react";
import ReactModal from "react-modal";
import Loader from "../common/loader.jsx";
import ConfirmationDialog from "../components/confirmation-dialog.jsx";
import { BASE_URL } from "../requests/api-requests.js";

const defaultViewResponse = {
  visible: false,
  loading: false,
  channelKey: null,
  epgId: null,
  data: null,
};

const defaultExportEpgState = {
  visible: false,
  channelName: null,
  channelKey: null,
  epgDate: null,
};

export function EpgDeliveryHistory({ channelGuid = null }) {
  const [colDefs, setColDefs] = React.useState();
  const gridRef = React.useRef();
  const [viewResponse, setViewResponse] = React.useState(defaultViewResponse);
  const [exportEpg, setExportEpg] = React.useState(defaultViewResponse);

  function openResponse(channelKey, epgId) {
    setViewResponse({
      visible: true,
      loading: true,
      channelKey: channelKey,
      epgId: epgId,
      data: null,
    });
  }

  function onClose() {
    setViewResponse(defaultViewResponse);
  }

  function submitExport(channelKey, epgDate) {
    // do submit
    const notification = toast.loading("Exporting EPG...");
    setExportEpg(defaultExportEpgState);
    axios
      .post(`/api/everyone-tv/${channelKey}/export/${epgDate}`)
      .then(
        responseHandler(() => {
          toast.dismiss(notification);
          setTimeout(() => {
            toast.success("Exported successfully, refresh the page to see status updates");
          }, 350);
        }),
      )
      .catch(
        errorHandler((err) => {
          console.error(err);
          toast.dismiss(notification);
          // window.location.reload();
          setTimeout(() => {
            toast.error("Unable to export epg");
          }, 350);
        }),
      );
  }

  function closeExportEpg() {
    setExportEpg(defaultExportEpgState);
  }

  function openExportEpg(channelName, channelKey, epgDate) {
    setExportEpg({
      visible: true,
      channelName,
      channelKey,
      epgDate,
    });
  }

  // On load, fetch all data
  React.useEffect(() => {
    async function populateData() {
      const params = channelGuid ? `?channel_id=${channelGuid}` : "";
      axios
        .get(`/api/everyone-tv${params}`)
        .then((response) => {
          gridRef.current.api.applyTransaction({
            add: response.data,
          });
        })
        .catch((e) => {
          toast.error("Unable to reach API");
          console.error(e);
        });
    }

    populateData().then().catch();
  }, [channelGuid]);

  React.useEffect(() => {
    if (viewResponse.channelKey && viewResponse.epgId) {
      axios
        .get(`/api/everyone-tv/${viewResponse.channelKey}/${viewResponse.epgId}`)
        .then((response) => {
          setViewResponse((prev) => ({
            ...prev,
            data: response.data,
            loading: false,
          }));
        })
        .catch(() => {
          toast.error("Unable to fetch response for EPG");
          setViewResponse((prev) => ({
            ...prev,
            loading: false,
          }));
        });
    }
  }, [viewResponse.channelKey, viewResponse.epgId]);

  React.useEffect(() => {
    setColDefs([
      {
        field: "channel_name",
        headerName: "Channel Name",
        // width: 180,
      },
      {
        field: "epg_date",
        headerName: "EPG Date",
        // width: 120,
      },
      {
        field: "epg_version",
        headerName: "Version",
        cellDataType: "text",
      },
      {
        field: "epg_versioned_date",
        headerName: "Upload Date",
      },
      {
        field: "epg_status",
        headerName: "Status",
        // width: 120,
      },
      {
        field: "epg_id",
        headerName: "External ID",
        // width: 200,
      },
      {
        field: "action_1",
        headerName: "Response",
        cellRenderer: (props) =>
          props.data.channel_key && props.data.epg_id !== "-" ? (
            <button
              type="type"
              className="btn btn--text-primary"
              onClick={() => openResponse(props.data.channel_key, props.data.epg_id)}
            >
              Click to view
            </button>
          ) : (
            <div>-</div>
          ),
      },
      {
        field: "action_2",
        headerName: "Export",
        cellRenderer: (props) => (
          <button
            type="type"
            className="btn btn--text-primary"
            onClick={() => {
              openExportEpg(props.data.channel_name, props.data.channel_key, props.data.epg_date);
            }}
          >
            Export to EveryoneTV
          </button>
        ),
      },
      {
        field: "action_3",
        headerName: "GoSeeTV EPG",
        cellRenderer: (props) => (
          <a
            className="u-fc--primary"
            href={`${BASE_URL}/feeds/epgs/everyonetv?channel=${props.data.channel_key}&date=${props.data.epg_date}&days=0`}
            download
          >
            Click to download
          </a>
        ),
      },
    ]);
  }, []);

  const defaultColDef = React.useMemo(() => {
    return {
      filter: true,
      editable: false,
    };
  }, []);

  return (
    <React.Fragment>
      <div className="ag-theme-material gstv-ag-grid">
        <AgGridReact
          rowData={undefined}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          rowSelection="multiple"
          ref={gridRef}
        />
      </div>
      <ReactModal isOpen={viewResponse.visible} ariaHideApp={false}>
        <div className="v-react-modal__inner modal-size-huge">
          <div className="v-react-modal__content">
            <div className="v-react-modal__heading v-react-modal__heading--flexbox">
              <div>EPG Response</div>
              <div className="v-react-modal__heading__actions">
                <button className="btn btn--inverse-primary" onClick={onClose}>
                  Close
                </button>
              </div>
            </div>
            <div className="v-react-modal__body">
              {viewResponse.loading && <Loader />}
              {!viewResponse.loading && viewResponse.visible && viewResponse.data && (
                <pre className="u-text--start u-fs--milli">{JSON.stringify(viewResponse.data, null, 2)}</pre>
              )}
            </div>
            <div className="v-react-modal__footer"></div>
          </div>
        </div>
      </ReactModal>
      <ConfirmationDialog
        isOpen={exportEpg.visible}
        heading="Export EPG to Freeview"
        body={`This action will export the EPG for ${exportEpg.channelName} on ${exportEpg.epgDate}`}
        submit={() => submitExport(exportEpg.channelKey, exportEpg.epgDate)}
        cancel={closeExportEpg}
      />
    </React.Fragment>
  );
}

export default function EpgDeliveryHistoryPage() {
  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            title: "EPG Delivery",
            link: "/",
          },
        ]}
      />
      <AppBody>
        <EpgDeliveryHistory />
      </AppBody>
    </React.Fragment>
  );
}
