import React from "react";
import { useSchedulerContext } from "../../providers/scheduler-context.jsx";
import useSegments from "./hooks/useSegments.jsx";
import CalendarMarkers from "./calendarMarkers.jsx";
import CurrentTimeMarker from "../../pages/epg-editor/components/current-time-marker.jsx";
import SimpleSchedulerProgram from "./simple-scheduler-program.jsx";
import { programToSimpleEpg } from "../../pages/epg-editor/utils/epg-transformers.js";
import {
  getHeightFromSeconds,
  getSmallOffset,
  getPrependedMargin,
  getMarkerPopOutWidth,
  getTimeRemainingInSegmentHis,
  getSchedulerSegmentStyle,
} from "./helpers/scheduler-helpers.js";

const SMALL_PROGRAM_MAX_HEIGHT = 30;

function SimplifiedVerticalScheduler({ programs, planBreaks, channelId, planDate }) {
  const [markerPopoutWidth, setMarkerPopoutWidth] = React.useState();
  const [epg, setEpg] = React.useState([]);
  const calendarDiv = React.useRef();
  const timelineContainerRef = React.useRef();
  const { getRenderTime, scheduleStart, scheduleEnd } = useSchedulerContext();

  const pixelsPerSection = 4;
  const secondsPerSection = 60; // change this when changing active availableMarkerMinuteSpread value
  const pixelsPerSecond = (1 / secondsPerSection) * pixelsPerSection;

  React.useEffect(() => {
    const next = programs.map((program) => programToSimpleEpg(program));
    setEpg(next);
  }, [channelId, programs]);

  React.useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      setMarkerPopoutWidth(getMarkerPopOutWidth(calendarDiv));
    });

    if (calendarDiv.current) {
      observer.observe(calendarDiv.current);
    } else {
      // hack to make sure we avoid timing issues
      setTimeout(() => {
        if (calendarDiv.current) {
          observer.observe(calendarDiv.current);
        }
      }, 300);
    }

    return () => observer.disconnect();
  }, []);

  const segments = useSegments(planDate, planBreaks, epg);

  // early exit if we don't have all the information
  if (planBreaks.length === 0) {
    return null;
  }

  return (
    <div className="vertical-scheduler" ref={timelineContainerRef}>
      <CalendarMarkers
        planDate={planDate}
        addBreak={() => {}}
        containerRef={timelineContainerRef}
        secondsPerSection={secondsPerSection}
        pixelsPerSection={pixelsPerSection}
        popoutWidth={markerPopoutWidth}
        changeZoomLevel={() => {}}
        readOnlyMode={true}
      />
      <div className="vertical-scheduler-calendar" ref={calendarDiv}>
        <CurrentTimeMarker
          timeRange={[scheduleStart, scheduleEnd]}
          pixelsPerSecond={pixelsPerSecond}
          date={planDate}
          direction="horizontal"
          considerRange={false}
        />
        {segments.map((seg, sIndex) => (
          <div
            className="vertical-scheduler-calendar__segment"
            key={sIndex}
            style={getSchedulerSegmentStyle(segments, sIndex, secondsPerSection, pixelsPerSection)}
          >
            <div className={`vertical-scheduler-calendar__plan`}>
              <div className="vertical-scheduler-calendar__bottom">
                <div className="vertical-scheduler-calendar__pill vertical-scheduler-calendar__pill--secondary">
                  <div className="vertical-scheduler-calendar__pill__text">
                    {getTimeRemainingInSegmentHis(seg, epg)}
                  </div>
                </div>
                <div className="vertical-scheduler-calendar__pill">
                  <span className="vertical-scheduler-calendar__pill__text">{getRenderTime(seg.end)}</span>
                </div>
              </div>
              {seg.items.map((program, index) => (
                <SimpleSchedulerProgram
                  program={program}
                  segment={seg}
                  smallDuration={SMALL_PROGRAM_MAX_HEIGHT}
                  smallOffset={getSmallOffset(
                    index,
                    seg.items,
                    SMALL_PROGRAM_MAX_HEIGHT,
                    secondsPerSection,
                    pixelsPerSection,
                  )}
                  getHeightFromSeconds={getHeightFromSeconds.bind(null, secondsPerSection, pixelsPerSection)}
                  prependMargin={getPrependedMargin(
                    index,
                    seg.items,
                    SMALL_PROGRAM_MAX_HEIGHT,
                    secondsPerSection,
                    pixelsPerSection,
                  )}
                  key={index}
                />
              ))}
            </div>
            <div className="vertical-scheduler__extra"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SimplifiedVerticalScheduler;
