import React from "react";
import useApiRequest from "../../hooks/use-api-request.js";
import axios, { errorHandler, responseHandler } from "../../requests/axios.js";
import { toast } from "react-toastify";
import { AppHeading } from "../../layout/parts/app-heading.jsx";
import AppBody from "../../layout/parts/app-body.jsx";
import Select from "../../common/select.jsx";
import ContentCard from "../library/components/content-card.jsx";
import DataTable from "../../components/data-table/data-table.jsx";
import DashboardNav from "./dashboard-nav.jsx";

export default function Dashboard({ links, breadcrumbs, filter = { defaultValue: {} }, statistics, recentTable }) {
  const [selectedFilter, setSelectedFilter] = React.useState([filter.defaultValue]);
  const [stats, setStats] = React.useState([Array.from([]).fill({}, 10)]);
  const { isLoading, response } = useApiRequest(filter.queryPath);

  React.useEffect(() => {
    axios
      .get(`${statistics.endpoint}${selectedFilter?.value ? `?provider=${selectedFilter.value}` : ""}`)
      .then(
        responseHandler((response) => {
          setStats(response.data.stats);
        }),
      )
      .catch(
        errorHandler(() => {
          toast.error("Could not load dashboard. Please contact support");
        }),
      );
  }, [selectedFilter?.value, statistics.endpoint]);

  const filters = [
    filter.defaultValue,
    ...(response.data?.data
      ? response.data.data.map((provider) => ({
          label: provider.provider_name,
          value: provider.provider_id,
        }))
      : []),
  ];

  const colours = ["u-bgc--primary-lt", "u-bgc--secondary-lt", "u-bgc--tertiary-lt", "u-bgc--danger", "u-bgc--info"];

  return (
    <React.Fragment>
      <AppHeading breadcrumbs={breadcrumbs} />
      <AppBody loading={isLoading}>
        {!isLoading && (
          <div className="gw">
            <div className="g g--3-of-6">
              <div className="island--smaller">
                <DashboardNav links={links} />
              </div>
              {filter.queryPath ? (
                <div className="island--smaller">
                  <div className="gw">
                    <div className="g g--1-of-2">
                      <Select
                        name={"dashboard_filter"}
                        value={selectedFilter}
                        options={filters}
                        onChange={setSelectedFilter}
                        containerClass="react-select-container--light"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="island--smaller">
                <div className="gw">
                  {statistics.actions?.length &&
                    statistics.actions.map((action) => (
                      <React.Fragment key={action.key}>{action.render()}</React.Fragment>
                    ))}
                  {stats.map((stat, index) => (
                    <div className="g g--1-of-2 g--desk-wide-1-of-3" key={index}>
                      <div className={`info-block `}>
                        <div className={`info-block__inner ${colours[index % 5]}`}>
                          <div className="info-block__label">{stat.label}</div>
                          <div className="info-block__value">{stat.value}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="g g--3-of-6">
              <ContentCard noPadding>
                <DataTable
                  columns={recentTable.columns}
                  url={`${recentTable.url}${selectedFilter?.value ? `?provider=${selectedFilter.value}` : ""}`}
                  pagination={false}
                />
              </ContentCard>
            </div>
          </div>
        )}
      </AppBody>
    </React.Fragment>
  );
}
