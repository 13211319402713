import React from "react";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import KeywordMeta from "../../../pages/library/components/keyword-meta.jsx";

export default function SidebarContentKeywords({ keywords, onClose, onSet, id }) {
  const [keywordsList, setKeywords] = React.useState(keywords);

  function updateKeywords(list) {
    setKeywords(list.keywords);
  }

  function onSave() {
    onSet(id, "keywords", [...keywordsList]);
    onClose();
  }

  return (
    <div className="gstv-ag-grid-sidebar-content">
      <div className="gstv-ag-grid-sidebar-content__section">
        <div className="gstv-ag-grid-sidebar-content__label"></div>
        <div className="gstv-ag-grid-sidebar-content__group">
          <KeywordMeta
            metadata={{ keywords: keywordsList }}
            isEditing={true}
            updateForm={updateKeywords}
            form={{ keywords: keywordsList }}
            noTitle={true}
            menuPlacement="bottom"
          />
        </div>
        <div className="gstv-ag-grid-sidebar-content__group"></div>
      </div>
      <div className="gstv-ag-grid-sidebar-content__footer">
        <button onClick={onClose} className="gstv-ag-grid-sidebar-content__footer__button">
          <CancelRoundedIcon /> Close
        </button>
        <button onClick={onSave} className="gstv-ag-grid-sidebar-content__footer__button">
          <SaveRoundedIcon /> Save
        </button>
      </div>
    </div>
  );
}
