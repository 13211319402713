import React from "react";
import { embedDashboard } from "@preset-sdk/embedded";
import axios from "../requests/axios.js";
import { toast } from "react-toastify";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import DashboardNav from "./dashboards/dashboard-nav.jsx";
import { DvrRounded, EqualizerRounded, OndemandVideoRounded, PaidRounded } from "@mui/icons-material";
import InsightsIcon from "@mui/icons-material/Insights";
import TableChartIcon from "@mui/icons-material/TableChart";

const NAV_LINKS = [
  { url: "/analytics/overview", icon: EqualizerRounded, label: "Overview", key: "overview" },
  { url: "/analytics/performance", icon: OndemandVideoRounded, label: "Performance", key: "performance" },
  { url: "/analytics/monetization", icon: PaidRounded, label: "Monetization", key: "monetization" },
  { url: "/analytics/provider", icon: DvrRounded, label: "Provider", key: "provider" },
  { url: "/analytics/custom", icon: InsightsIcon, label: "Custom", key: "custom" },
  { url: "/analytics/reports", icon: TableChartIcon, label: "Reports", key: "reports" },
];

function AnalyticsPage({ dashboardKey }) {
  const [dashboard, setDashboard] = React.useState();
  const dashboardRef = React.useRef();

  React.useEffect(() => {
    axios
      .get(`/api/analytics/${dashboardKey}`)
      .then((resp) => {
        setDashboard({
          dashboard_id: resp.data.dashboard_id,
          token: resp.data.token,
          domain: resp.data.domain,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.warn("Unable to fetch Dashboard");
      });
  }, [dashboardKey]);

  React.useEffect(() => {
    const embed = async () => {
      await embedDashboard({
        id: dashboard.dashboard_id,
        supersetDomain: dashboard.domain,
        mountPoint: dashboardRef.current,
        fetchGuestToken: () => dashboard.token,
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: false,
          hideTab: true,
        },
      });
    };

    if (dashboardRef.current && dashboard?.token) {
      embed();
    }
  }, [dashboard?.domain, dashboard?.token, dashboard?.dashboard_id]);

  const dashboardNavLinks = React.useMemo(() => {
    return NAV_LINKS.map((l) => (l.key === dashboardKey ? { ...l, active: true } : l));
  }, [dashboardKey]);

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/analytics",
            title: "Analytics",
          },
          {
            link: "",
            title: NAV_LINKS.find((l) => l.key === dashboardKey)?.label ?? "Dashboard",
          },
        ]}
      />
      <AppBody>
        <div className="analytics">
          <div className="u-margin--small--block-end">
            <DashboardNav links={dashboardNavLinks} />
          </div>
          <div className="analytics__dashboard" ref={dashboardRef}></div>
        </div>
      </AppBody>
    </React.Fragment>
  );
}

export default AnalyticsPage;
