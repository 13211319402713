import React from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import axios, { errorHandler, responseHandler } from "../../../requests/axios.js";
import Loader from "../../../common/loader.jsx";
import Select from "../../../common/select.jsx";
import { useNavigate } from "react-router-dom";

export default function AddTemplateDialog({ isOpen, onClose, channels, channelGuid }) {
  const [isLoading, setLoading] = React.useState(false);
  const [channelOptions, setOptions] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [form, setForm] = React.useState({ template_name: "", channel_id: null });
  const navigate = useNavigate();

  React.useEffect(() => {
    if (channels.length) {
      setOptions(
        channels.map((channel) => ({
          value: channel.channel_id,
          label: channel.display_name,
        })),
      );
    }
  }, [channels]);

  function updateForm(key, value) {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  }

  function validateForm() {
    let message = "";

    if (!form.template_name) {
      message = "Template name cannot be blank";
      setMessage(message);
      return message;
    }

    if (!form.channel_id && !channelGuid) {
      message = "Please select a channel";
      setMessage(message);
      return message;
    }

    return message;
  }

  function onConfirm() {
    setLoading(true);

    if (validateForm()) {
      setLoading(false);
      return;
    }

    if (!form.channel_id && channelGuid) {
      form.channel_id = channelGuid;
    }

    axios
      .post(`/api/templates`, form)
      .then(
        responseHandler((resp) => {
          onClose();
          navigate(`${window.location.pathname}/${resp.data.template_id}`);
          toast.success(`New template added: ${form.template_name}`);
        }),
      )
      .catch(
        errorHandler((err) => {
          if (err.response && err.response.data.error) {
            setMessage(err.response.data.error);
          } else {
            console.error(err);
            setMessage("Something went wrong, please contact support.");
          }
        }),
      )
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-copy">
        <div className="v-react-modal__heading">Create Template</div>
        <div className="v-react-modal__body modal-copy__body">
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Template name:</div>
            <div className="modal-copy__dropdown">
              <input
                type="text"
                name="templateName"
                id="templateName"
                placeholder="Add a name for this template"
                onChange={(e) => updateForm("template_name", e.target.value)}
              />
            </div>
          </div>
          {!channelGuid ? (
            <React.Fragment>
              <div className="modal-copy__dropdown-group">
                <div className="modal-copy__body__text">Channel:</div>
                <div className="modal-copy__dropdown">
                  <Select
                    name={"selectChannel"}
                    options={[{ value: "", label: "Select a channel" }, ...channelOptions]}
                    onChange={(input) => updateForm("channel_id", input.value)}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    selectedValue={channelOptions[0]}
                  />
                </div>
              </div>
              {message ? (
                <p className="u-fc--danger-dk u-text--center" style={{ marginBottom: "4px" }}>
                  {message}
                </p>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            [
              <button className="btn btn--inverse-primary" onClick={onClose} key="btn-back">
                Cancel
              </button>,
              <button className="btn btn--primary" onClick={onConfirm} key="btn-yes">
                Create
              </button>,
            ]
          )}
        </div>
      </div>
    </ReactModal>
  );
}
