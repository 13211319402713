import { captureException } from "@sentry/browser";

export function handleVideoEvents(videoElement, trackingEvents, callback) {
  const adState = {
    impression: false,
    started: false,
    firstQuartile: false,
    midpoint: false,
    thirdQuartile: false,
    complete: false,
  };

  const handlers = {
    click: () => {},
    timeupdate: () => trackAdProgress(videoElement, trackingEvents, adState),
    error: () => {
      removeVideoEventListeners(videoElement, handlers);
      callback();
    },
    ended: () => {
      removeVideoEventListeners(videoElement, handlers);
      if (trackingEvents.complete && !adState.complete) {
        if (Array.isArray(trackingEvents.complete)) {
          Promise.all(trackingEvents.complete.map((url) => fireEvent(url).catch(() => null))).then(() => true);
        } else {
          fireEvent(trackingEvents.complete).catch(() => null);
        }
      }

      callback();
    },
  };

  Object.keys(handlers).forEach((event) => {
    videoElement.addEventListener(event, handlers[event]);
  });
}

function fireEvent(url) {
  return new Promise((resolve) => {
    if (url.includes("blackhole")) {
      resolve();
      return;
    }

    fetch(url)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        captureException(e);
        console.error(e);
        resolve(); // resolve here because error handling is done locally
      });
  });
}

function removeVideoEventListeners(videoElement, handlers) {
  Object.keys(handlers).forEach((event) => {
    videoElement.removeEventListener(event, handlers[event]);
  });
}

function trackAdProgress(videoElement, trackingEvents, adState) {
  const progressChecks = [
    { key: "impression", point: 0.01, event: "impression" },
    { key: "started", point: 0.01, event: "start" },
    { key: "first", point: 0.25, event: "firstQuartile" },
    { key: "mid", point: 0.5, event: "midpoint" },
    { key: "third", point: 0.75, event: "thirdQuartile" },
  ];

  progressChecks.forEach((check) => {
    if (
      !adState[check.key] &&
      videoElement.currentTime >= (check.point - 0.01) * videoElement.duration &&
      videoElement.currentTime <= (check.point + 0.01) * videoElement.duration &&
      !!trackingEvents[check.event]
    ) {
      adState[check.key] = true;

      if (Array.isArray(trackingEvents[check.event])) {
        Promise.all(trackingEvents[check.event].map((url) => fireEvent(url).catch(() => null))).then(() => true);
      } else {
        fireEvent(trackingEvents[check.event]).catch(() => null);
      }
    }
  });
}
