import React from "react";
import InputTagList from "../input-tag-list.jsx";
import ProviderFilter from "./filters/provider-filter.jsx";
import ContentTypeFilter from "./filters/content-type-filter.jsx";
import DurationFilter from "./filters/duration-filter.jsx";
import SeasonFilter from "./filters/season-filter.jsx";
import SeriesFilter from "./filters/series-filter.jsx";
import SortOptions from "./sorting/sort-options.jsx";
import getTagsFromFilters from "./getTagsFromFilters.jsx";
import debounce from "../../common/debounce.js";
// import isDeepEqual from "fast-deep-equal/react";
import RatingFilter from "./filters/rating-filter.jsx";
import GenreFilter, { SubgenreFilter } from "./filters/genre-filter.jsx";
import KeywordFilter from "./filters/keyword-filter.jsx";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const contentTypes = [
  {
    value: "features",
    label: "Features",
  },
  {
    value: "episodes",
    label: "Episodes",
  },
  {
    value: "promos",
    label: "Promos",
  },
  {
    value: "series",
    label: "Series",
  },
];
const defaultContentType = contentTypes[0];

const CACHE_KEY = "__gstv__content_list__cache";
const CACHE_BUST_KEY = "__gstv_content_list__cache_buster";
const CACHE_BUST_VALUE = "2024-12-24 15:27:00";

function getFiltersFromCache(key) {
  if (window.localStorage.getItem(CACHE_BUST_KEY) !== CACHE_BUST_VALUE) {
    window.localStorage.setItem(CACHE_BUST_KEY, CACHE_BUST_VALUE);
    window.localStorage.removeItem(CACHE_KEY);
    return false;
  }

  if (window.localStorage.getItem(CACHE_KEY)) {
    if (JSON.parse(window.localStorage.getItem(CACHE_KEY))) {
      const cache = JSON.parse(window.localStorage.getItem(CACHE_KEY));
      if (cache[key]) {
        return cache[key].filters;
      }
    }
  }

  return false;
}

function setFilterCache(key, filters) {
  const current = window.localStorage.getItem(CACHE_KEY);
  let next = { [key]: { filters } };

  if (current) {
    let decoded = {};
    try {
      decoded = JSON.parse(current);
    } catch {
      // do nothing
    }
    next = {
      ...decoded,
      [key]: { filters },
    };
  }

  window.localStorage.setItem(CACHE_KEY, JSON.stringify(next));
}

function getInitialState(forChannelId = null, providerList) {
  return {
    search: "",
    providers: [{ value: providerList[0].provider_guid, label: providerList[0].provider_name }],
    contentType: defaultContentType,
    forChannelId: forChannelId,
    ratings: [],
    keywords: [],
    genres: [],
    subgenres: [],
    durationMin: "",
    durationMax: "",
    series: {},
    season: {},
    sortOrder: [{ value: "title", order: "asc" }],
  };
}

export default React.forwardRef(function ContentListFilters(
  {
    providers,
    getPrograms,
    overridePagesize = false,
    overrideSeriesGuid = false,
    displayAllFilters = false,
    alignSortRight = false,
    flexible = false,
    showInactiveContent = false,
    showRestrictedContent = false,
    cacheOnPath = false,
    series = {},
    forChannelId = null,
    resetSeries,
  },
  ref,
) {
  const getProgramsOverrides = {
    ...(overridePagesize ? { pageSize: overridePagesize } : {}),
    ...(overrideSeriesGuid ? { seriesGuid: overrideSeriesGuid } : {}),
  };
  const [filtersVisible, setFiltersVisible] = React.useState(displayAllFilters);
  const initialState = getInitialState(forChannelId, providers);
  const cachedFilters = getFiltersFromCache(window.location.pathname);
  const [filters, setFilters] = React.useState(
    cachedFilters && cacheOnPath ? { ...initialState, ...cachedFilters } : initialState,
  );

  const filtersChangeRef = React.useRef({ ...filters, ...getProgramsOverrides });
  const tagsMemo = React.useMemo(() => getTagsFromFilters(filters), [filters]);

  React.useEffect(() => {
    if (filters.contentType) {
      let correctedSorts = [...filters.sortOrder];
      if (filters.contentType.value === "episodes") {
        // we need to patch the value when sortOrder value of title is used, since a bit extra is required in the url
        let titleIndex = correctedSorts.findIndex((srt) => srt.value === "title");
        if (titleIndex !== -1) {
          const order = correctedSorts[titleIndex].order;
          correctedSorts = [
            ...correctedSorts.slice(0, titleIndex),
            ...[
              {
                value: "series_name",
                order,
              },
              {
                value: "season_number",
                order: "asc",
              },
              {
                value: "episode_number",
                order: "asc",
              },
            ],
            ...correctedSorts.slice(titleIndex + 1),
          ];
        }
      }
      let sorting = correctedSorts.map((sortItem) => `${sortItem.value}.${sortItem.order}`);

      // default sorting for episodes
      if (!sorting.length && filters.contentType.value === "episodes") {
        sorting = ["series_name.asc", "season_number.asc", "episode_number.asc"];
      }

      debounce(() => {
        getPrograms({
          content: filters.contentType.value,
          search: filters.search,
          providerGuids: filters.providers.map((provider) => provider.value),
          ratings: filters.ratings.map((rating) => rating.value),
          keywords: filters.keywords.map((keyword) => keyword.value),
          genres: filters.genres.map((genre) => genre.value),
          subgenres: filters.subgenres.map((subgenre) => subgenre.value),
          seriesGuid: filters.series.value,
          seasonGuid: filters.season.value,
          minDuration: filters.durationMin ? filters.durationMin * 60 : null,
          maxDuration: filters.durationMax ? filters.durationMax * 60 : null,
          sorting,
          displayInactive: showInactiveContent,
          displayRestricted: showRestrictedContent,
          ...(overridePagesize ? { pageSize: overridePagesize } : {}),
          ...(overrideSeriesGuid ? { seriesGuid: overrideSeriesGuid } : {}),
        });

        if (cacheOnPath) {
          setFilterCache(window.location.pathname, filters);
        }
      }, 300);
    }
  }, [
    filtersChangeRef,
    filters,
    cacheOnPath,
    getPrograms,
    overrideSeriesGuid,
    overridePagesize,
    showInactiveContent,
    showRestrictedContent,
  ]);

  React.useEffect(() => {
    if (series.label && series.value) {
      setFilters((prev) => ({
        ...prev,
        contentType: {
          value: "episodes",
          label: "Episodes",
        },
        series: {
          label: series.label,
          value: series.value,
        },
      }));
    }
  }, [series.label, series.value]);

  // Content List Filters API
  React.useImperativeHandle(
    ref,
    () => {
      return {
        setFilterOptions(options) {
          const defaultState = getInitialState(forChannelId);
          setFilters((prev) => ({
            forChannelId: prev.forChannelId,
            search: options.search ?? defaultState.search,
            providers: options.providers ?? defaultState.providers,
            contentType: options.contentType ?? defaultState.contentType,
            ratings: options.ratings ?? defaultState.ratings,
            genres: options.genres ?? defaultState.genres,
            keywords: options.keywords ?? defaultState.keywords,
            subgenres: options.subgenres ?? defaultState.subgenres,
            durationMin: options.durationMin ?? defaultState.durationMin,
            durationMax: options.durationMax ?? defaultState.durationMax,
            series: options.series ?? defaultState.series,
            season: options.season ?? defaultState.season,
            sortOrder: options.sortOrder ?? defaultState.sortOrder,
          }));
        },
      };
    },
    [forChannelId],
  );

  // Filter setters
  function setSearch(input) {
    setFilters((prev) => ({
      ...prev,
      search: input,
    }));
  }

  function toggleAdditionalFilters() {
    setFiltersVisible((prev) => !prev);
  }

  function setProvider(input) {
    let nextProviders = input;
    if (!nextProviders) {
      nextProviders = [];
    }

    setFilters((prev) => ({
      ...prev,
      providers: nextProviders,
    }));
  }

  function setContentType(input) {
    resetSeries();
    setFilters((prev) => ({
      ...prev,
      contentType: input,
      series: input.value === "episodes" ? prev.series : {},
      season: input.value === "episodes" ? prev.season : {},
    }));
  }

  function setRatings(rating) {
    setFilters((prev) => ({
      ...prev,
      ratings: prev.ratings.concat(rating),
    }));
  }

  function setKeywords(keyword) {
    setFilters((prev) => ({
      ...prev,
      keywords: prev.keywords.concat(keyword),
    }));
  }

  function setGenre(genre) {
    setFilters((prev) => ({
      ...prev,
      genres: [genre],
      subgenres: [],
    }));
  }

  function setSubgenre(subgenre) {
    setFilters((prev) => ({
      ...prev,
      subgenres: [subgenre],
    }));
  }

  function setDurationMin(event) {
    const durationValue = Math.min(Math.max(0, event.target.value), 999);
    event.target.value = durationValue; // for visibility of changes to value outside range
    setFilters((prev) => ({
      ...prev,
      durationMin: durationValue ? durationValue : "",
    }));
  }

  function setDurationMax(event) {
    const durationValue = Math.min(Math.max(0, event.target.value), 999);
    event.target.value = durationValue; // for visibility of changes to value outside range
    setFilters((prev) => ({
      ...prev,
      durationMax: durationValue ? durationValue : "",
    }));
  }

  function setSeries(input) {
    setFilters((prev) => ({
      ...prev,
      series: input,
      season: {},
    }));
  }

  function setSeason(input) {
    setFilters((prev) => ({
      ...prev,
      season: input,
    }));
  }

  function setSortOrder(option, order) {
    setFilters((prev) => {
      const prevOptions = prev.sortOrder;

      // create a new array with any updated sort options
      let newOptions = prevOptions.map((sortOption) => {
        return {
          ...sortOption,
          ...(sortOption.value === option ? { order } : {}),
        };
      });

      // add sort option if it doesn't exist
      if (prevOptions.findIndex((sortOption) => sortOption.value === option) === -1) {
        newOptions = newOptions.concat({ value: option, order: order });
      }

      // remove any options with a null value and set the array to state
      return {
        ...prev,
        sortOrder: newOptions.filter((sortOption) => !!sortOption.order),
      };
    });
  }

  function onRemoveTag(tag) {
    const keys = Array.isArray(tag) ? tag : [tag];
    const keyOptions = keys.map((tagKey) => {
      const [key, option] = tagKey.split(".");
      return {
        key,
        option,
      };
    });

    setFilters((prev) => {
      const next = keyOptions
        .map((keyOption) => ({
          key: [keyOption.key],
          value: keyOption.option
            ? prev[keyOption.key].filter((v) => v.value !== keyOption.option)
            : initialState[keyOption.key],
        }))
        .reduce((previousValue, currentValue) => {
          return {
            ...previousValue,
            [currentValue.key]: currentValue.value,
          };
        }, {});

      // clear subgenres
      if (next.genres) {
        next.subgenres = [];
      }

      return {
        ...prev,
        ...next,
      };
    });
  }

  return (
    <div className={`content-list__filter content-list-filter ${flexible ? "content-list-filter--flexible" : ""}`}>
      <InputTagList tags={tagsMemo} onRemove={onRemoveTag} />

      <div className="content-list-filter__group content-list-filter__group--fixed">
        <button className="btn btn--icon" onClick={toggleAdditionalFilters}>
          <FilterAltRoundedIcon />
          <span className="content-list-filter-button__counter">{tagsMemo.length ?? 50}</span>
        </button>
        <div className="input-group input-group--with-icon content-list-filter__search">
          <input
            type="text"
            className="app-text-input"
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            value={filters.search}
          />
          <SearchRoundedIcon />
        </div>
      </div>
      <div className={`content-list-filter__group${filtersVisible ? "" : "--hidden"}`}>
        <div className="content-list-filter__provider">
          <ProviderFilter providers={providers} selectedProviders={filters.providers} onChange={setProvider} />
        </div>
        <div className="content-list-filter__content-type">
          <ContentTypeFilter contentType={filters.contentType} contentTypes={contentTypes} onChange={setContentType} />
        </div>
        <div className="content-list-filter__ratings content-list-filter__ratings--thirds">
          <RatingFilter selectedRatings={filters.ratings} onChange={setRatings} />
        </div>
        <div className="content-list-filter__genres content-list-filter__genres--thirds">
          <KeywordFilter selectedKeywords={filters.keywords} onChange={setKeywords} />
        </div>
        <div className="content-list-filter__genres content-list-filter__genres--thirds">
          <GenreFilter selectedGenres={filters.genres} onChange={setGenre} />
        </div>
        <div className="content-list-filter__ratings">
          <SubgenreFilter
            selectedGenres={filters.genres}
            selectedSubgenres={filters.subgenres}
            onChange={setSubgenre}
          />
        </div>
        <div className="content-list-filter__duration">
          <DurationFilter
            onChangeMax={setDurationMax}
            onChangeMin={setDurationMin}
            min={filters.durationMin}
            max={filters.durationMax}
          />
        </div>
        {filters.contentType.value === "episodes" ? (
          <div className="content-list-filter__series">
            <SeriesFilter
              providerGuids={filters.providers.map((p) => p.value)}
              selectedSeries={filters.series}
              onChange={setSeries}
              channelId={forChannelId}
            />
          </div>
        ) : null}
        {filters.contentType.value === "episodes" ? (
          <div className="content-list-filter__seasons">
            <SeasonFilter seriesId={filters.series.value} season={filters.season} onChange={setSeason} />
          </div>
        ) : null}
        <div className={`content-list-filter__sort ${alignSortRight ? "content-list-filter__sort--right" : ""}`}>
          <SortOptions onChange={setSortOrder} sortOrder={filters.sortOrder} />
        </div>
      </div>
    </div>
  );
});
