import React from "react";
import {
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
  subSeconds,
} from "date-fns";
import dateIsBetween from "../../../common/date-is-between";
import { useSchedulerContext } from "../../../providers/scheduler-context.jsx";

const POSITION_UPDATE_INTERVAL_MS = 30000; //  currently 30 sec

function setCurrentTimeOn(date, offsetCb) {
  let now = new Date();
  let current = addHours(addMinutes(addSeconds(date, now.getSeconds()), now.getMinutes()), now.getHours());
  if (offsetCb(current) !== 0) {
    const dayOffset = differenceInSeconds(date, startOfDay(current)) * -1;
    current = addSeconds(current, dayOffset);
  }

  return current;
}

function CurrentTimeMarker({ timeRange, pixelsPerSecond, date, direction = "vertical", considerRange = true }) {
  const { scheduleStart, getTimezoneOffsetSeconds } = useSchedulerContext();
  const [thisDay, setThisDay] = React.useState(setCurrentTimeOn(scheduleStart, getTimezoneOffsetSeconds));

  React.useEffect(() => {
    let timer = setInterval(() => {
      setThisDay(setCurrentTimeOn(scheduleStart, getTimezoneOffsetSeconds));
    }, POSITION_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(timer);
    };
  }, [date, scheduleStart, getTimezoneOffsetSeconds]);

  // render nothing if current time is outside zoom range and we are rendering vertically
  if (!dateIsBetween(thisDay, timeRange[0], timeRange[1], "()") && considerRange) {
    return;
  }

  const startComparison = considerRange ? timeRange[0] : scheduleStart;
  let offsetPixels = differenceInSeconds(thisDay, startComparison) * pixelsPerSecond;

  const styles = {
    ...(direction === "horizontal" ? { top: `${offsetPixels}px` } : {}),
    ...(direction === "vertical" ? { left: `${offsetPixels}px` } : {}),
  };

  return (
    <div
      className={`scheduler-timeline__content__current-time scheduler-timeline__content__current-time--${direction}`}
      style={styles}
    />
  );
}

export default CurrentTimeMarker;
