import { Ad } from "./Ad.js";
import parseUserAgent from "../utils/parse-user-agent.js";
import generateHashFrom from "../utils/generate-hash-from.js";
import { getCookie, TCF_CONSENT_BASE } from "../utils/cookies.js";
import { captureException } from "@sentry/browser";
import axios from "axios";

export default class HbbTvAdverts {
  constructor(adSource, logger, channel) {
    this.log = logger;
    this.adSource = adSource;
    this.tcStringCookieName = TCF_CONSENT_BASE + channel;
    this.deviceIdCookieName = TCF_CONSENT_BASE + channel + "_did";
  }

  async fetchAds(adDuration, macros = {}) {
    this.log.info("[HbbTv Adverts] Fetch Ads");

    if (typeof macros === "object" && !Array.isArray(macros) && macros !== null) {
      macros.pod_max_dur = adDuration;
    } else {
      // invalid
      macros = {
        pod_max_dur: adDuration,
      };
    }

    try {
      const url = this._applyMacros(this.adSource, macros);
      const response = await axios.get(`${url}&accept_application=json`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = response.data;

      const ads = this._parseAds(data);
      const formattedAds = ads.map((ad) => this._formatAd(ad));

      return formattedAds;
    } catch (err) {
      this.log.error("[HbbTv Adverts] Fetch Ads FAILED", JSON.stringify(err));
      captureException(err);
      return [];
    }
  }

  _parseAds(adList) {
    return adList
      .filter((ad) => ad.duration > 2)
      .map((ad) => {
        return new Ad(ad.id, ad.sequence, ad.adTitle, ad.creativeId, ad.duration, ad.trackingEvents, {
          bitrate: ad.bitrate,
          height: ad.height,
          width: ad.width,
          source: ad.source,
          type: ad.type,
        });
      });
  }

  _formatAd(ad) {
    return {
      source: ad.media.source,
      start: 0,
      realtime: 0,
      end: ad.duration,
      duration: ad.duration,
      loaded: false,
      hasAd: false,
      meta: {
        type: "ad",
        title: ad.adTitle,
        ad: ad,
      },
    };
  }

  _applyMacros(adSource, macros) {
    let separator = "?";
    let macroString;

    if (adSource.includes("?")) {
      separator = "&";
    }

    const combinedMacros = {
      ...macros,
      ...this._buildClientSideMacros(),
    };

    macroString = this._getMacroUrlString(combinedMacros);

    // now apply client side macros
    return adSource + separator + macroString;
  }

  _buildClientSideMacros() {
    const deviceId = getCookie(this.deviceIdCookieName);
    const tcString = getCookie(this.tcStringCookieName);

    const allowed = !!tcString;

    const ua = navigator.userAgent;
    const device = parseUserAgent(ua);
    const sessionId = generateHashFrom(deviceId, "" + +new Date());

    return {
      w: 1280,
      h: 720,
      gdpr: 1,
      cb: +new Date() + Math.floor(Math.random() * 1000) + 1,
      device_make: allowed ? device.make : "",
      device_model: allowed ? device.model : "",
      brand_name: allowed ? device.brand : "",
      ua: allowed ? ua : "",
      gdpr_consent: allowed ? tcString : "null",
      ipa_type: "ppid",
      did: allowed ? deviceId : "",
      session_id: allowed ? sessionId : "",
      loc: window?.location?.host ?? "",
      lmt: allowed ? 0 : 1, // limited tracking
    };
  }

  _getMacroUrlString(macros) {
    const caseSensitiveMacros = ["gdpr_consent", "app_store_url", "app_store_id"];
    return Object.keys(macros)
      .map((macro) => {
        if (!macros[macro]) {
          return null;
        }

        let value;
        if ((macros[macro] + "").includes("{{")) {
          // do not include placeholders
          value = "";
        } else {
          value = macros[macro]; // use provided
        }

        value = "" + value; // ensure it's a string
        value = caseSensitiveMacros.includes(macro) ? value : value.toLowerCase();

        return `${macro}=${encodeURIComponent(value)}`;
      })
      .filter((test) => !!test)
      .join("&");
  }
}
