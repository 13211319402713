import React from "react";
import Loader from "../common/loader";
import ContentCard from "././library/components/content-card";
import ChangePasswordDialog from "./change-password-dialog";
import { v4 as uuid } from "uuid";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import getToken from "../functions/getToken.js";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import AppBody from "../layout/parts/app-body.jsx";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import axios, { errorHandler, responseHandler } from "../requests/axios.js";

function User() {
  const [user, setUser] = React.useState({});
  const [showPasswordDialog, setShowPasswordDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const getUserGuid = React.useCallback(() => {
    if (!getToken(false)) {
      return "";
    }

    const token = getToken();
    const userGuid = jwt_decode(token).user.id;
    return userGuid;
  }, []);

  React.useEffect(() => {
    setIsLoading(true);

    axios
      .get(`/api/users/${getUserGuid()}`)
      .then(responseHandler((data) => setUser(data)))
      .catch(
        errorHandler((error) => {
          console.error(error);
          toast.error("Error fetching data for User");
        }),
      )
      .finally(() => setIsLoading(false));
  }, [getUserGuid]);

  function openPasswordDialog() {
    setShowPasswordDialog(true);
  }

  function closePasswordDialog() {
    setShowPasswordDialog(false);
  }

  return isLoading ? (
    <div className="page-loader-wrapper">
      <Loader />
    </div>
  ) : (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/user",
            title: user.display_name ? user.display_name : "...",
          },
        ]}
        rightActions={[
          {
            icon: KeyRoundedIcon,
            onClick: openPasswordDialog,
            balloonLabel: "Change password",
          },
        ]}
      />
      <AppBody loading={isLoading}>
        {!isLoading && (
          <div className="gw">
            <div className="g g--1-of-1">
              <ContentCard>
                <div className="program-field">
                  <div className="program-field__label program-field__label--largest">Email</div>
                  <div className="program-field__value program-field__value--large-font">{user.email}</div>
                </div>
                <div className="program-field">
                  <div className="program-field__label program-field__label--largest">Organisation</div>
                  <div className="program-field__value program-field__value--large-font">
                    {user?.organisation?.display_name ?? "No Organisation"}
                  </div>
                </div>
                <div className="program-field">
                  <div className="program-field__label program-field__label--largest">Role</div>
                  <div className="program-field__value program-field__value--large-font">{user?.role ?? "None"}</div>
                </div>
                <div className="program-field">
                  <div className="program-field__label program-field__label--largest">Last Login</div>
                  <div className="program-field__value program-field__value--large-font">{user?.last_login ?? ""}</div>
                </div>
              </ContentCard>
            </div>
          </div>
        )}
      </AppBody>
      <ChangePasswordDialog
        isOpen={showPasswordDialog}
        onClose={closePasswordDialog}
        onCancel={closePasswordDialog}
        userGuid={user.user_guid}
        key={`password-dialog__${uuid()}`}
      />
    </React.Fragment>
  );
}

export default User;
