import React from "react";
import Loader from "../../common/loader";
import hbbtvPlayerModule from "../../modules/hbbtv-player/js";
import { toast } from "react-toastify";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import "../../modules/hbbtv-player/css/hbbtv.css";

export default function HbbtvPlayer({
  channelName,
  debugTimeTravel = null, // null | Date
}) {
  const [loaderDims, setLoaderDims] = React.useState({ width: "100%", height: "auto" });
  const [isFullscreen, setFullscreen] = React.useState(false);
  const [muted, setMuted] = React.useState(true);
  const loaderRef = React.useRef();

  const enterFullscreen = React.useCallback(() => {
    const playerContainer = document.getElementById("gstv-hbbtv-video-container");
    const adContainer = document.getElementById("gstv-hbbtv-ad-container");

    if (!playerContainer.classList.contains("fullscreen")) {
      playerContainer.classList.add("fullscreen");
    }

    if (!adContainer.classList.contains("fullscreen")) {
      adContainer.classList.add("fullscreen");
    }
    setFullscreen(true);
  }, []);

  const exitFullscreen = React.useCallback(() => {
    const playerContainer = document.getElementById("gstv-hbbtv-video-container");
    const adContainer = document.getElementById("gstv-hbbtv-ad-container");

    if (playerContainer.classList.contains("fullscreen")) {
      playerContainer.classList.remove("fullscreen");
    }

    if (adContainer.classList.contains("fullscreen")) {
      adContainer.classList.remove("fullscreen");
    }
    setFullscreen(false);
  }, []);

  const onEscapeKeyPressed = React.useCallback(
    (event) => {
      if (event.key === "Escape") {
        exitFullscreen();
      }
    },
    [exitFullscreen],
  );

  const toggleMute = React.useCallback(() => {
    setMuted((prev) => !prev);
  }, []);

  React.useEffect(() => {
    window.addEventListener("keydown", onEscapeKeyPressed);

    return () => window.removeEventListener("keydown", onEscapeKeyPressed);
  }, [onEscapeKeyPressed]);

  React.useLayoutEffect(() => {
    const { startApplication, destroyApplication } = hbbtvPlayerModule(channelName, debugTimeTravel);

    try {
      startApplication(channelName);
    } catch (error) {
      console.error(error);
      toast.error("Error initialising hbbtv player");
    }

    return () => {
      try {
        return destroyApplication();
      } catch {
        // do nothing
      }
    };
  }, [channelName, debugTimeTravel]);

  React.useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (loaderRef.current?.parentElement) {
        let parentElement = loaderRef.current.parentElement;
        let loaderWidth = parentElement.getBoundingClientRect().width;
        let loaderHeight = parentElement.getBoundingClientRect().width / (16 / 9);

        if (loaderHeight > parentElement.getBoundingClientRect().height) {
          loaderHeight = parentElement.getBoundingClientRect().height;
          loaderWidth = parentElement.getBoundingClientRect().height * (16 / 9);
        }

        setLoaderDims({ height: `${loaderHeight}px`, width: `${loaderWidth}px` });
      }
    });

    if (loaderRef.current?.parentElement) {
      observer.observe(loaderRef.current.parentElement);
    }
    return () => observer.disconnect();
  }, []);

  return (
    <React.Fragment>
      <div id="gst-hbbtv-cover-image" ref={loaderRef} style={loaderDims}>
        <Loader />
      </div>
      <div id="gstv-hbbtv-video-container">
        <div className="hbbtv-player-overlay">
          <div className="hbbtv-player-overlay__inner">
            <button
              className="btn btn--icon"
              onClick={(e) => {
                e.preventDefault();
                toggleMute();
                document.activeElement.blur();
              }}
            >
              {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </button>
            <button
              className="btn btn--icon"
              onClick={(e) => {
                e.preventDefault();
                !isFullscreen ? enterFullscreen() : exitFullscreen();
                document.activeElement.blur();
              }}
            >
              <OpenInFullIcon />
            </button>
          </div>
        </div>
        <video className="test-vid-hidden" autoPlay="autoplay" muted={muted}></video>
      </div>
      <div id="gstv-hbbtv-ad-container">
        <video className="test-vid-hidden" preload="auto" muted={muted}></video>
      </div>
    </React.Fragment>
  );
}
