import React from "react";
import Select from "../../common/select";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function CalendarToolbar({ label, onNavigate, updateDate }) {
  const date = React.useMemo(() => new Date(label), [label]);

  const onChangeYear = React.useCallback(
    (yearInput) => {
      const nextDate = new Date(date);
      nextDate.setFullYear(yearInput.value);
      updateDate(nextDate);
    },
    [date, updateDate],
  );

  const onChangeMonth = React.useCallback(
    (monthInput) => {
      const nextDate = new Date(date);
      nextDate.setMonth(monthInput.value);
      updateDate(nextDate);
    },
    [date, updateDate],
  );

  const yearOptions = Array.from({ length: 4 }, (x, i) => 2025 + i).map((d) => ({
    label: d,
    value: d,
  }));

  return (
    <div className="big-calendar-toolbar">
      <button
        className="btn btn--base--svg big-calendar-toolbar__nav-button big-calendar-toolbar__nav-button--left"
        onClick={() => onNavigate("PREV")}
      >
        <ChevronLeftRoundedIcon />
      </button>

      <div className="big-calendar-toolbar__select">
        <Select
          name={"month"}
          defaultValue={{ label: monthsOfYear[date.getMonth()], value: date.getMonth() }}
          onChange={(input) => onChangeMonth(input)}
          options={monthsOfYear.map((month, index) => ({ label: month, value: index }))}
          containerClass="react-select-container--light react-select-container--small"
        />
      </div>

      <div className="big-calendar-toolbar__select">
        <Select
          name={"year"}
          defaultValue={{ label: date.getFullYear(), value: date.getFullYear() }}
          onChange={(input) => onChangeYear(input)}
          options={yearOptions}
          containerClass="react-select-container--light react-select-container--small"
        />
      </div>

      <button
        className="btn btn--base--svg big-calendar-toolbar__nav-button big-calendar-toolbar__nav-button--right"
        onClick={() => onNavigate("NEXT")}
      >
        <ChevronRightRoundedIcon />
      </button>
    </div>
  );
}
