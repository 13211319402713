import { getDurationFromHourMinutesSeconds } from "../duration-formatting.js";

export function getMaximumDuration(totalDuration, adBreaks) {
  return (
    totalDuration +
    adBreaks.reduce((prev, curr) => {
      return prev + getDurationFromHourMinutesSeconds(curr.ad_break);
    }, 0)
  );
}
