import React from "react";
import { Link } from "react-router-dom";

function DashboardNav({ links }) {
  return (
    <div className="dashboard-nav">
      {links.map((link, index) => (
        <div className={`dashboard-nav__link ${link.active ? "dashboard-nav__link--active" : ""}`} key={index}>
          <Link to={link.url} className="btn btn--base--svg btn--wide">
            <link.icon />
            {link.label}
          </Link>
        </div>
      ))}
    </div>
  );
}

export default DashboardNav;
