import React from "react";
import ReactModal from "react-modal";
import axios, { errorHandler, responseHandler } from "../../../../requests/axios.js";
import { addHours, addMinutes, setSeconds, format } from "date-fns";
import Loader from "../../../../common/loader.jsx";
import { toast } from "react-toastify";

function TrashPlanDialog({ isOpen, onClose, onSuccess, planDate, channelId, updateActiveDate, breakSource }) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  function onSubmit() {
    setIsLoading(true);

    // overwrite with an 'empty' plan object
    const payload = {};
    payload.plan_breaks = breakSource.map((pb) => {
      const [hours, minutes, seconds] = pb.start.split(":");
      const [endHours, endMinutes, endSeconds] = pb.end.split(":");
      let start = addHours(addMinutes(setSeconds(planDate, seconds), minutes), hours);
      let end = addHours(addMinutes(setSeconds(planDate, endSeconds), endMinutes), endHours);

      return {
        type: pb.type,
        end,
        start,
      };
    });
    payload.programs = [];
    payload.filters = [];

    axios
      .put(`/api/channels/${channelId}/plans/${format(planDate, "yyyy-MM-dd")}`, payload)
      .then(
        responseHandler((response) => {
          onClose();
          const plan = response.data;
          plan.plan_breaks = plan.plan_breaks.map((planBreak) => {
            const start = new Date(planBreak.start);
            const end = new Date(planBreak.end);
            return {
              type: planBreak.type,
              start,
              end,
            };
          });

          onSuccess(plan);
          toast.success("Plan cleared successfully");
          updateActiveDate(planDate);
        }),
      )
      .catch(
        errorHandler((e) => {
          console.error(e);
          setErrorMessage("There was an error clearing the plan, please contact support.");
        }),
      )
      .finally(() => setIsLoading(false));
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__content">
          <div className="v-react-modal__heading">Delete Plan Confirmation</div>
          <div className="v-react-modal__body modal-body">
            Are you sure you want to remove this plan?
            <br />
            It will not be possible for you to reverse this action.
            {errorMessage ? <p className="u-fc--warning">{errorMessage}</p> : null}
          </div>
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            [
              <button className="btn btn--primary" onClick={onClose} key="trash-plan-dialog-cancel">
                Nevermind
              </button>,
              <button className="btn btn--inverse-primary" onClick={onSubmit} key="trash-plan-dialog-submit">
                Yes I am sure
              </button>,
            ]
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default TrashPlanDialog;
