import React from "react";
import ReactInputMask from "react-input-mask";
import { useSchedulerContext } from "../../../providers/scheduler-context.jsx";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import RotateLeftRoundedIcon from "@mui/icons-material/RotateLeftRounded";
import { areAdBreaksSpaced } from "../utils/cuepoint-spacing-validator.js";
import { isPast } from "date-fns";

function PlannerMetaCuepoints({ program, saveAdBreaks }) {
  const [adBreaks, setAdBreaks] = React.useState(program?.__gstvMeta.ad_breaks);
  const [allowSave, setAllowSave] = React.useState(false);
  const isMutable = !isPast(program.since);
  const { getRenderTime } = useSchedulerContext();

  const updateAdBreak = React.useCallback((index, value) => {
    setAdBreaks((ads) => {
      return ads.map((a, key) => {
        if (key === index) {
          a.cuepoint = value;
        }

        return a;
      });
    });
  }, []);

  const attemptSave = React.useCallback(
    (...args) => {
      if (areAdBreaksSpaced(adBreaks, program, getRenderTime()) && allowSave) {
        saveAdBreaks(...args);
      }
    },
    [adBreaks, allowSave, program, saveAdBreaks, getRenderTime],
  );

  React.useEffect(() => {
    if (adBreaks && adBreaks.length) {
      // validate each input
      const result = adBreaks.every((ad) => ad.cuepoint !== "00:00:00" && !ad.cuepoint.includes("_"));
      setAllowSave(result);
    }
  }, [adBreaks]);

  React.useEffect(() => {
    if (program) {
      setAdBreaks(program.__gstvMeta.ad_breaks);
    }
  }, [program]);

  function insertAdBreak(index) {
    setAdBreaks((ads) => {
      return [...ads.slice(0, index + 1), { ad_break: "00:02:02", cuepoint: "00:00:00" }, ...ads.slice(index + 1)];
    });
  }

  function removeAdBreak(index) {
    setAdBreaks((ads) => {
      return [...ads.slice(0, index), ...ads.slice(index + 1)];
    });
  }

  function resetAdBreaks() {
    setAdBreaks(program.__gstvMeta.ad_breaks);
  }

  return (
    <>
      <div className="planner-meta-cuepoints__heading">
        <span className="planner-meta-cuepoints__heading__text">Cuepoints</span>
        <span className="planner-meta-cuepoints__actions">
          <button className="btn btn--icon" onClick={() => isMutable && resetAdBreaks()} disabled={!isMutable}>
            <RotateLeftRoundedIcon />
          </button>
          <button
            className="btn btn--icon"
            onClick={() => isMutable && attemptSave(program.id, adBreaks)}
            disabled={!isMutable}
          >
            <SaveRoundedIcon />
          </button>
        </span>
      </div>
      <div className="ad-breaks">
        {adBreaks &&
          adBreaks.map((ad, key) => (
            <div className="ad-breaks__item" key={key}>
              <div className="ad-breaks__item__actions">
                <button
                  onClick={() => isMutable && insertAdBreak(key)}
                  className="ad-breaks__action-btn"
                  disabled={!isMutable}
                >
                  <AddCircleRoundedIcon />
                </button>
                <button
                  onClick={() => isMutable && removeAdBreak(key)}
                  className="ad-breaks__action-btn"
                  disabled={!isMutable}
                >
                  <RemoveCircleRoundedIcon />
                </button>
              </div>
              <div className="ad-breaks__item__input">
                <ReactInputMask
                  disabled={!isMutable}
                  readOnly={!isMutable}
                  mask={"99:99:99"}
                  value={ad.cuepoint}
                  onChange={(e) => {
                    updateAdBreak(key, e.target.value);
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default PlannerMetaCuepoints;
