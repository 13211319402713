import React from "react";
import { differenceInSeconds } from "date-fns";
import { getMaximumDuration } from "../../common/programs/utils.js";
import getSecondsFromHeightFactory from "../../pages/epg-editor/utils/get-seconds-from-height.js";

let TRACK_MOUSE_IS_DOWN = false;
let TRACK_MOUSE_IS_DRAGGING = false;
let TRACK_SHIFT_IS_DOWN = false;

export default function useResizableSchedulerContent({
  content,
  contentRef,
  resizingLabelRef,
  resizingDurationRef,
  updateDuration,
  segment,
  setIsResizing,
  onClick,
  pixelsPerSection,
  secondsPerSection,
  getHeightFromSeconds,
  formatTimeForTimezone,
}) {
  const getSecondsFromHeight = getSecondsFromHeightFactory.bind(null, pixelsPerSection, secondsPerSection);

  React.useEffect(() => {
    function onDownListener(event) {
      if (event.key === "Shift") {
        TRACK_SHIFT_IS_DOWN = true;
      }
    }

    function onUpListener(event) {
      if (event.key === "Shift") {
        TRACK_SHIFT_IS_DOWN = false;
      }
    }

    window.addEventListener("keydown", onDownListener);
    window.addEventListener("keyup", onUpListener);

    return () => {
      window.removeEventListener("keydown", onDownListener);
      window.removeEventListener("keyup", onUpListener);
    };
  });

  const resizeHandler = React.useCallback(
    (content, mouseDownEvent) => {
      setIsResizing(true);

      const maximumDurationToNextBreak = differenceInSeconds(segment.end, content.since);
      const maximumContentDuration =
        content.__gstvMeta.original_total_duration_seconds ??
        getMaximumDuration(content.__gstvMeta.original_duration_seconds, content.__gstvMeta.ad_breaks);

      const maximumDuration = Number.isNaN(maximumContentDuration)
        ? maximumDurationToNextBreak
        : Math.min(maximumContentDuration, maximumDurationToNextBreak);

      const initialHeight = getHeightFromSeconds(maximumDuration);
      const initialMouseY = mouseDownEvent.pageY;

      function onMouseMove(mouseMoveEvent) {
        const currentY = mouseMoveEvent.pageY;
        let nextHeight = currentY - contentRef.current.getBoundingClientRect().top;

        if (currentY <= contentRef.current.getBoundingClientRect().top) {
          nextHeight = initialHeight / 4; // minimum height
        } else if (currentY > contentRef.current.getBoundingClientRect().top + initialHeight) {
          nextHeight = initialHeight;
        }

        window.requestAnimationFrame(() => {
          if (!TRACK_MOUSE_IS_DOWN) {
            return;
          }

          let effectiveSeconds = getSecondsFromHeight(nextHeight) * 1000;
          if (TRACK_SHIFT_IS_DOWN) {
            // snap to nearest 5 minutes
            let looseEndTime = new Date(content.since.getTime() + effectiveSeconds);
            let minute = looseEndTime.getMinutes();

            let fixedEndTime = new Date(looseEndTime);
            fixedEndTime.setSeconds(0);

            if (minute % 5 !== 0) {
              const minuteDiff = minute % 5;
              let nextMinutes = looseEndTime.getMinutes() + minuteDiff;
              if (nextMinutes % 5 !== 0) {
                nextMinutes = looseEndTime.getMinutes() - minuteDiff;
              }

              if (nextMinutes === 0 && looseEndTime.getMinutes() > 5) {
                // snap to next hour
                fixedEndTime.setHours(fixedEndTime.getHours() + 1);
              }

              fixedEndTime.setMinutes(nextMinutes);
            }

            nextHeight = getHeightFromSeconds((fixedEndTime - content.since) / 1000);
            effectiveSeconds = getSecondsFromHeight(nextHeight) * 1000;
          }

          // modify ui
          if (resizingLabelRef.current) {
            resizingLabelRef.current.innerHTML = formatTimeForTimezone(
              new Date(content.since.getTime() + effectiveSeconds),
              "HH:mm:ss",
            );
          }

          if (resizingDurationRef.current) {
            resizingDurationRef.current.innerHTML = formatTimeForTimezone(
              new Date(content.since.getTime() + effectiveSeconds),
              "HH:mm:ss",
            );
          }

          contentRef.current.style.height = `${nextHeight}px`;
        });
      }

      function onMouseUp(mouseUpEvent) {
        // ignore clicks for this process
        if (mouseUpEvent.pageY !== initialMouseY) {
          const nextDuration = getSecondsFromHeight(contentRef.current.getBoundingClientRect().height);
          updateDuration(content.drag_id ?? content.id, nextDuration);
        }

        setIsResizing(false);
        document.body.removeEventListener("mousemove", onMouseMove);
        document.body.removeEventListener("mouseup", onMouseUp);
      }

      document.body.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("mouseup", onMouseUp, { once: true });
    },
    [
      formatTimeForTimezone,
      getHeightFromSeconds,
      segment?.end,
      setIsResizing,
      updateDuration,
      contentRef,
      resizingDurationRef,
      resizingLabelRef,
      getSecondsFromHeight,
    ],
  );

  const onMouseUp = React.useCallback(() => {
    TRACK_MOUSE_IS_DOWN = false;
    window.removeEventListener("mouseup", onMouseUp);

    if (!TRACK_MOUSE_IS_DRAGGING) {
      onClick();
    }

    TRACK_MOUSE_IS_DRAGGING = false;
  }, [onClick]);

  const onMouseDown = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      TRACK_MOUSE_IS_DOWN = true;

      window.addEventListener("mouseup", onMouseUp);

      // are we dragging or clicking?
      const timeout = window.setTimeout(() => {
        if (TRACK_MOUSE_IS_DOWN) {
          TRACK_MOUSE_IS_DRAGGING = true;
          resizeHandler(content, event);
        }
      }, 200);

      return () => {
        window.clearTimeout(timeout);
      };
    },
    [content, onMouseUp, resizeHandler],
  );

  return {
    // onMouseUp,
    onMouseDown,
  };
}
