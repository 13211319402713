import { intervalToDuration } from "date-fns";

export function secondsToHourMinutesSeconds(seconds) {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  return `${duration.hours.toString().padStart(2, "0")}:${duration.minutes.toString().padStart(2, "0")}:${duration.seconds.toString().padStart(2, "0")}`;
}

export function secondsToHourMinutes(seconds) {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  return `${duration.hours.toString().padStart(2, "0")}:${duration.minutes.toString().padStart(2, "0")}`;
}

export function dateToHourMinutesSeconds(date) {
  return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
}

export function getDurationFromHourMinutesSeconds(his) {
  const [hours, minutes, seconds] = his.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}
