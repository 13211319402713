import React from "react";
import ReactModal from "react-modal";
import Select from "../../common/select";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";
import { addDays } from "date-fns";
import Loader from "../../common/loader";
import { toast } from "react-toastify";
import axios, { errorHandler, responseHandler } from "../../requests/axios";

// should probably api call for this but this list shouldn't change hopefully
const FREQUENCIES = ["weekly", "monthly", "yearly", "repeating", "selection"];
const DAYS_OF_THE_WEEK = ["S", "M", "T", "W", "Th", "F", "S"];

export default function AddTemplateScheduleDialog({ onClose, onSuccess, isOpen, channelGuid, templates }) {
  const [loading, setLoading] = React.useState(false);

  const [form, setForm] = React.useState({
    startDate: addDays(new Date(), 1),
    endDate: addDays(new Date(), 1),
    frequency: "repeating",
    days: [],
  });

  function validateForm() {
    if (!form.template) {
      toast.error("Cannot save without selecting a template");
      return false;
    }

    if (!form.template.days.length) {
      toast.error("Cannot use a template without any days");
      return false;
    }

    return true;
  }

  function onCreate() {
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    const payload = {
      template_id: form.template.template_id,
      channel_id: channelGuid,
      frequency: form.frequency,
      days_of_week: form.days,
      starts_at: form.startDate,
      ends_at: form.endDate,
      priority: "normal", // @TODO priority system
    };

    axios
      .post(`api/channels/${channelGuid}/template-schedules`, payload)
      .then(
        responseHandler(() => {
          toast.success("Template Schedule created successfully");
          onClose();
          onSuccess();
        }),
      )
      .catch(
        errorHandler((e) => {
          toast.error("Error creating new template schedule");
          console.error(e);
        }),
      )
      .finally(() => setLoading(false));
  }

  function updateForm(key, value) {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function updateDaysOfWeek(day) {
    setForm((prev) => {
      const daysCopy = [...prev.days];
      if (daysCopy.includes(day)) {
        daysCopy.splice(daysCopy.indexOf(day), 1);
      } else {
        daysCopy.push(day);
      }

      return {
        ...prev,
        days: daysCopy,
      };
    });
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-copy">
        <div className="v-react-modal__heading">Add Template Schedule</div>
        <div className="v-react-modal__body modal-copy__body">
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Select Template</div>
            <div className="modal-copy__dropdown">
              <Select
                name={"template"}
                onChange={(input) => {
                  updateForm(
                    "template",
                    templates.find((template) => template.template_id === input.value),
                  );
                }}
                options={templates.map((template) => ({ label: template.template_name, value: template.template_id }))}
                // containerClass="react-select-container--light react-select-container--small" //  maybe use some of these
              />
            </div>
          </div>
          <p className="modal-copy__body__subtext">Days in template: {form.template?.days.length ?? 0}</p>

          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Frequency</div>
            <div className="modal-copy__dropdown">
              <Select
                defaultValue={{
                  label: String(form.frequency).charAt(0).toUpperCase() + String(form.frequency).slice(1),
                  value: form.frequency,
                }}
                name={"frequency"}
                onChange={(input) => updateForm("frequency", input.value)}
                options={FREQUENCIES.map((freq) => ({
                  label: String(freq).charAt(0).toUpperCase() + String(freq).slice(1),
                  value: freq,
                }))}
                // containerClass="react-select-container--light react-select-container--small" //  maybe use some of these
              />
            </div>
          </div>

          {form.frequency === "selection" ? (
            <div className="modal-copy__dropdown-group">
              <div className="modal-copy__body__text"></div>
              <div className="days-of-week-selector">
                {DAYS_OF_THE_WEEK.map((day, index) => (
                  <div
                    className={`days-of-week-selector__segment ${form.days.includes(index) ? "days-of-week-selector__segment--selected" : ""}`}
                    onClick={() => updateDaysOfWeek(index)}
                    key={`day-${index}`}
                  >
                    {day}
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Start date:</div>
            <div className="modal-copy__dropdown">
              <DatePicker
                selected={form.startDate}
                onChange={(date) => updateForm("startDate", new Date(date))}
                minDate={addDays(new Date(), 1)}
                popperContainer={({ children }) => createPortal(children, document.body)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>

          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">End date:</div>
            <div className="modal-copy__dropdown">
              <DatePicker
                selected={form.endDate}
                onChange={(date) => updateForm("endDate", new Date(date))}
                minDate={new Date(form.startDate)}
                popperContainer={({ children }) => createPortal(children, document.body)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
        </div>
        <div className="v-react-modal__footer" style={{ marginTop: "4px" }}>
          {loading ? (
            <React.Fragment>
              {/* <div className="modal-copy__load-text">{loadMessage}</div> */}
              <Loader width={45} height={45} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <button className="btn btn--inverse-primary" onClick={onClose}>
                Cancel
              </button>
              <button className="btn btn--primary" onClick={onCreate}>
                Create Template Schedule
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </ReactModal>
  );
}
