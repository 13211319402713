import React from "react";
import { AppHeading } from "../layout/parts/app-heading";
import AppBody from "../layout/parts/app-body";
import HbbtvPlayer from "../components/hbbtv-player/hbbtv-player";
import Select from "../common/select";
import axios, { errorHandler, responseHandler } from "../requests/axios";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import { format, setHours, setMinutes, setSeconds } from "date-fns";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";

export default function HbbtvQcPage() {
  const [channel, setChannel] = React.useState();
  const [channels, setChannels] = React.useState();
  const [playerDate, setPlayerDate] = React.useState(new Date());
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [playerActive, setPlayerActive] = React.useState(false);

  const updatePlayer = React.useCallback(() => {
    setPlayerDate(new Date(selectedDate));
    setPlayerActive(true);
  }, [selectedDate]);

  React.useEffect(() => {
    axios
      .get(`api/channels`)
      .then(
        responseHandler((resp) => {
          setChannels(
            resp.data.data
              .filter((c) => {
                if (!c.playouts || !c.playouts.length) {
                  return false;
                }

                return c.playouts.map((c) => c.destination_output).includes("dash");
              })
              .map((c) => ({ label: c.display_name, value: c.name })),
          );
        }),
      )
      .catch(
        errorHandler((e) => {
          console.error(e);
          toast.error("Error loading channels");
        }),
      );
  }, []);

  function onDateChange(date) {
    setSelectedDate((prev) => {
      const newDate = new Date(date);
      newDate.setSeconds(prev.getSeconds());
      newDate.setMinutes(prev.getMinutes());
      newDate.setHours(prev.getHours());

      return newDate;
    });
  }

  function onTimeChange(time) {
    setSelectedDate((prev) => {
      let input = time;
      if (input.length < 6) {
        input += ":00";
      }

      const [hours, minutes, seconds] = input.split(":");
      const newDate = setHours(setMinutes(setSeconds(new Date(prev), seconds), minutes), hours);
      return newDate;
    });
  }

  return (
    <React.Fragment>
      <AppHeading breadcrumbs={[]} />
      <AppBody loading={false}>
        <div>
          <div className="gw">
            <div className="g g--2-of-4 u-flex--rows--wrap">
              <button className="btn btn--icon" onClick={updatePlayer}>
                <LoopRoundedIcon />
              </button>
              <div style={{ flexGrow: 1, marginInlineStart: "12px" }}>
                <Select
                  name={"channel"}
                  options={channels}
                  onChange={(item) => setChannel(channels.find((c) => c.value === item.value))}
                ></Select>
              </div>
            </div>
            <div className="g g--1-of-4">
              <ReactDatePicker
                selected={selectedDate}
                onChange={(date) => onDateChange(date)}
                clearIcon={null}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="g g--1-of-4">
              <input
                type="time"
                id="time"
                name="time"
                min="00:00:00"
                max="23:59:59"
                step="1"
                onChange={(e) => onTimeChange(e.target.value)}
                value={format(selectedDate, "HH:mm:ss")}
              />
            </div>
          </div>
          <div className="gw">
            <div className="g g--tall g--1-of-1" style={{ position: "relative" }}>
              {playerActive && channel && playerDate ? (
                <HbbtvPlayer channelName={channel.value} debugTimeTravel={playerDate} />
              ) : null}
            </div>
          </div>
        </div>
      </AppBody>
    </React.Fragment>
  );
}
