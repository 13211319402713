import React, { createRef } from "react";
import DataTable from "../../../components/data-table/data-table.jsx";
import ContentCard from "../components/content-card.jsx";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import { v4 as uuid } from "uuid";
import AddPromoDialog from "../components/dialogs/add-promo-dialog.jsx";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Link } from "react-router-dom";
import { formatInTimeZone } from "date-fns-tz";
import ManagePromoLinkDialog from "../components/dialogs/manage-promo-link-dialog.jsx";

export default function PromosTab({ groups }) {
  const [addPromoDialog, setAddPromoDialog] = React.useState({
    open: false,
  });
  const [linkDialog, setLinkDialog] = React.useState({
    open: false,
  });

  const tablesRef = React.useRef(groups.map(() => createRef()));

  function formatDates(date) {
    return formatInTimeZone(date, "UTC", "Y-MM-dd HH:mm:ss");
  }

  function openAddDialog(group) {
    setAddPromoDialog({
      open: true,
      contentType: group.type,
      contentGuid: group.contentGuid,
    });
  }

  function closeAddDialog() {
    setAddPromoDialog({ open: false });
  }

  function openLinkDialog(linkData, action) {
    let contentType = linkData.link_type;
    if (contentType !== "series") {
      contentType += "s";
    }

    setLinkDialog({
      open: true,
      promoGuid: linkData.promo_guid,
      contentGuid: linkData.link_guid,
      contentType,
      action,
    });
  }

  function closeLinkDialog() {
    setLinkDialog({
      open: false,
    });
  }

  function getMessageForAction(action) {
    switch (action) {
      case "delete":
        return "Are you sure you want to unlink this promo?";
      case "restore":
        return "Are you sure you want to restore this promo link?";
      default:
        return "";
    }
  }

  const columns = [
    {
      label: "Promo Name",
      key: "promo_name",
      value: "promo_name",
      width: 40,
      renderValue: (row) => (
        <Link
          to={`/content/library/promo/${row.promo_guid}`}
          className={`data-table__cell__value ${row.link_deleted ? "data-table__cell__value--inactive" : ""}`}
          target="_blank"
          rel="noreferrer"
          onClick={() => document.activeElement.blur()}
        >
          {row.promo_name}
        </Link>
      ),
    },
    {
      label: "Date Added",
      key: "created_at",
      value: "created_at",
      width: 20,
      renderValue: (row) => (
        <span className={`data-table__cell__value ${row.link_deleted ? "data-table__cell__value--inactive" : ""}`}>
          {formatDates(row.created_at)}
        </span>
      ),
    },
    {
      label: "Provider",
      key: "provider_name",
      value: "promo.provider.provider_name",
      width: 25,
      renderValue: (row) => (
        <span className={`data-table__cell__value ${row.link_deleted ? "data-table__cell__value--inactive" : ""}`}>
          {row.promo.provider.provider_name}
        </span>
      ),
    },
    {
      label: "",
      value: "",
      key: "0",
      width: 15,
      renderValue: (row) => (
        <span className="data-table__action-cell">
          {!row.link_deleted ? (
            <React.Fragment>
              <span
                className="data-table__action-cell__item data-table__icon"
                onClick={() => openLinkDialog(row, "delete")}
              >
                <DeleteForeverRoundedIcon />
              </span>
            </React.Fragment>
          ) : (
            <span
              className="data-table__action-cell__item data-table__icon"
              onClick={() => openLinkDialog(row, "restore")}
            >
              <RestoreFromTrashRoundedIcon />
            </span>
          )}
        </span>
      ),
    },
  ];

  return (
    <div>
      <div className="u-margin--small--block-end">
        {groups.map((group, key) => (
          <div className="u-margin--block-end" key={key}>
            <ContentCard>
              {group.label && (
                <div className="content-page__title content-page__title--separator">
                  <span>{group.label}</span>
                  <span>
                    <button className="btn btn--icon" onClick={() => openAddDialog(group)}>
                      <AddRoundedIcon />
                    </button>
                  </span>
                </div>
              )}
              {tablesRef.current ? (
                <DataTable url={group.url} pagination={false} columns={columns} ref={tablesRef.current[key]} />
              ) : null}
            </ContentCard>
          </div>
        ))}
      </div>
      <AddPromoDialog
        isOpen={addPromoDialog.open}
        contentGuid={addPromoDialog.contentGuid}
        contentType={addPromoDialog.contentType}
        message={getMessageForAction(addPromoDialog.action)}
        onClose={closeAddDialog}
        onConfirm={() => {
          if (tablesRef.current) {
            tablesRef.current[groups.findIndex((g) => g.type === addPromoDialog.contentType)]?.current.refreshList();
          }
        }}
        key={`asset_dialog-${uuid()}`}
      />
      <ManagePromoLinkDialog
        isOpen={linkDialog.open}
        contentGuid={linkDialog.contentGuid}
        contentType={linkDialog.contentType}
        promoGuid={linkDialog.promoGuid}
        action={linkDialog.action}
        message={getMessageForAction(linkDialog.action)}
        onClose={closeLinkDialog}
        onConfirm={() => {
          if (tablesRef.current) {
            tablesRef.current[groups.findIndex((g) => g.type === linkDialog.contentType)]?.current.refreshList();
          }
        }}
        key={`asset_dialog-${uuid()}`}
      />
    </div>
  );
}
