import React from "react";
import Select from "../../../common/select";
import axios from "../../../requests/axios";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { toast } from "react-toastify";
import debounce from "../../../common/debounce";

export default function KeywordMeta({
  isEditing,
  form,
  updateForm,
  metadata,
  classes,
  menuPlacement = "top",
  horizontal = false,
  noTitle = false,
}) {
  const data = { ...metadata, ...(isEditing ? form : {}) };
  const [query, setQuery] = React.useState("");

  const updateKeywords = React.useCallback(
    (list) => {
      updateForm({ keywords: list });
    },
    [updateForm],
  );

  const addKeyword = React.useCallback(
    (item) => {
      updateKeywords([...data.keywords, { keyword: item }]);
    },
    [data.keywords, updateKeywords],
  );

  const removeKeyword = React.useCallback(
    (value) => {
      updateKeywords([...data.keywords].filter((kw) => kw.keyword !== value));
    },
    [data.keywords, updateKeywords],
  );

  const addKeywordFromInput = React.useCallback(() => {
    if (query !== "" && !data.keywords.find((kw) => kw.keyword.toLowerCase() === query.toLowerCase())) {
      addKeyword(query);
    }
    setQuery("");
    document.activeElement.blur();
  }, [addKeyword, data.keywords, query]);

  React.useLayoutEffect(() => {
    function onEnterKeyPressed(event) {
      if (isEditing && event.key === "Enter") {
        addKeywordFromInput();
      }
    }
    document.addEventListener("keydown", onEnterKeyPressed);

    return () => {
      document.removeEventListener("keydown", onEnterKeyPressed);
    };
  }, [addKeywordFromInput, isEditing]);

  const defaultClasses = {
    heading: "planner-meta-cuepoints__heading__text", // maybe use a different default
  };

  const classLists = {
    ...defaultClasses,
    ...classes,
  };

  const loadOptions = () => {
    return new Promise((resolve, reject) => {
      debounce(() => {
        axios
          .get(`api/keywords?search=%${query}%`)
          .then((response) => {
            resolve(() => {
              const results = response.data
                .filter((item) => !!item)
                .map((item) => ({
                  label: item.charAt(0).toUpperCase() + item.slice(1),
                  value: item,
                }))
                .filter((option) => !data.keywords.find((kw) => kw.keyword === option.value));
              return results;
            });
          })
          .catch((e) => {
            console.error(e);
            toast.error("Error loading keywords. Please contact support");
            reject(e);
          });
      }, 200);
    });
  };

  return (
    <div className={`keyword-meta ${horizontal ? "keyword-meta--horizontal" : ""}`}>
      {noTitle ? null : (
        <div className="keyword-meta__heading">
          <span className={classLists.heading}>Keywords</span>
        </div>
      )}
      <div className="keyword-meta__body">
        {isEditing ? (
          <React.Fragment>
            <div className="keyword-meta__select">
              <Select
                placeholder={"Search Keywords..."}
                name={"keyword"}
                isSearchable={true}
                onChange={(item) => {
                  addKeyword(item.value);
                  setQuery("");
                }}
                containerClass="react-select-container--light react-select-container--small"
                controlShouldRenderValue={false}
                onInputChange={(value) => {
                  if (value !== "") setQuery(value);
                }}
                noOptionsMessage={() => null}
                isAsync={true}
                loadOptions={loadOptions}
                menuPlacement={menuPlacement}
                components={{
                  DropdownIndicator: () => null,
                  LoadingMessage: () => "",
                }}
              />
              <button className="btn btn--icon" onClick={addKeywordFromInput}>
                <AddRoundedIcon />
              </button>
            </div>
            <div className="keyword-meta__pill-list">
              {data?.keywords.map((kw, index) => (
                <button
                  className="keyword-meta__pill-list__pill keyword-meta__pill-list__pill--clickable"
                  onClick={() => removeKeyword(kw.keyword)}
                  key={index}
                >
                  <span className="keyword-meta__pill-list__label">{kw.keyword}</span>
                  <CancelRoundedIcon />
                </button>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <div className="keyword-meta__body__text">
            {data?.keywords?.length
              ? [...data.keywords].map((kw) => kw.keyword.charAt(0).toUpperCase() + kw.keyword.slice(1)).join(", ")
              : "None"}
          </div>
        )}
      </div>
    </div>
  );
}
