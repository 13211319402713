import React from "react";
import ReactSelect, { createFilter, components } from "react-select";
import AsyncSelect from "react-select/async";

// disables mouse move and hover events to improve performance when list has large number of items
// styles are added back in scss
function CustomOption({ children, ...props }) {
  const { _onMouseMove, _onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className="react-select-option">
      {children}
    </components.Option>
  );
}

export default function Select({
  noPortal = false,
  isAsync = false,
  loadOptions = () => {},
  containerClass,
  ...props
}) {
  let attrs = {
    menuPortalTarget: document.querySelector("#ReactSelectPortal"),
  };

  if (isAsync) {
    return (
      <div className={`react-select-container ${containerClass}`}>
        <AsyncSelect
          classNamePrefix="react-select"
          loadOptions={() => loadOptions()}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{ Option: CustomOption }}
          {...props}
          {...(noPortal ? {} : attrs)}
        />
      </div>
    );
  }

  return (
    <div className={`react-select-container ${containerClass}`}>
      <ReactSelect
        classNamePrefix="react-select"
        filterOption={createFilter({ ignoreAccents: false })}
        components={{ Option: CustomOption }}
        {...props}
        {...(noPortal ? {} : attrs)}
      />
    </div>
  );
}
