import React from "react";
import ReactModal from "react-modal";
import axios, { errorHandler, responseHandler } from "../../../../requests/axios.js";
import { toast } from "react-toastify";
import Loader from "../../../../common/loader.jsx";

export default function ProcessPlanDialog({ isOpen, onClose, channelId, planId }) {
  const [isLoading, setLoader] = React.useState(false);
  const [message, setMessage] = React.useState("");
  function onConfirm() {
    setLoader(true);
    axios
      .post(`/api/channels/${channelId}/plans/${planId}/process`, {})
      .then(
        responseHandler(() => {
          onClose();
          toast.success("Successfully uploaded channel plan to s3");
        }),
      )
      .catch(
        errorHandler((err) => {
          if (err.response && err.response.data.error) {
            setMessage(err.response.data.error);
          } else {
            console.error(err);
            setMessage("Something went wrong, please contact support.");
          }
        }),
      )
      .finally(() => {
        setLoader(false);
      });
  }

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          width: "600px",
          height: "360px",
        },
      }}
      ariaHideApp={false}
    >
      <div className="v-react-modal__inner">
        <div className="v-react-modal__content">
          <div className="v-react-modal__heading">Manually Process Plan</div>
          <div className="v-react-modal__body v-react-modal__body--left">
            <p>
              This will upload the MCRList and the EPG to S3, any existing files will be overwritten by this process.
              <br />
              This will not trigger the automated scripts which apply these changes to Media Tailor.
            </p>
            <p className="u-text--center">
              <b>Please make sure you have saved the channel plan before starting this process</b>
            </p>
            {message ? <p className="u-fc--danger-dk u-text--center">{message}</p> : null}
          </div>
        </div>
        <div className="v-react-modal__footer">
          {isLoading ? (
            <Loader width={45} height={45} />
          ) : (
            [
              <button className="btn btn--primary" onClick={onClose} key="btn-back">
                Go back
              </button>,
              <button className="btn btn--inverse-primary" onClick={onConfirm} key="btn-yes">
                I understand, start the process
              </button>,
            ]
          )}
        </div>
      </div>
    </ReactModal>
  );
}
