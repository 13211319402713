import React from "react";
import { getDurationInSecondsWithAdBreaks } from "../../epg-editor/utils/create-ad-breaks";
import TranscodingStatusText from "../../components/transcoding-status-text";
import getClassList from "../../../functions/get-class-list";
import { Draggable } from "react-beautiful-dnd";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import { secondsToHourMinutesSeconds } from "../../../common/duration-formatting.js";

export default function TemplateContentItem({ item, index, addItem }) {
  const [isAdded, setAdded] = React.useState(false);

  function addItemWrapper(...params) {
    setAdded(true);
    addItem(...params);
  }

  function hasError(item) {
    if (item.series_guid) {
      return false;
    }

    return !item.asset_duration && !item.duration;
  }

  function getItemClass(item) {
    return getClassList({
      "library-list__item": true,
      "library-list__item--warning": hasError(item),
    });
  }

  const cuepoints = item?.cuepoints
    ? item.cuepoints.map((contentCuepoint) => ({
        ad_break: contentCuepoint.ad_break,
        cuepoint: contentCuepoint.cuepoint,
      }))
    : [];

  return (
    <Draggable draggableId={`template_content_list_item_${index}`} index={index}>
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={getItemClass(item)}
          style={{
            ...provided.draggableProps.style,
          }}
          {...(hasError(item)
            ? {
                ["aria-label"]: "No video found for this content",
                ["data-balloon-pos"]: "up",
              }
            : {})}
          // onDoubleClick
        >
          <span className="content-item__description">
            <span className="content-item__heading">{item.formatted_name}</span>
            <span className="content-item__sub-heading">
              <span>
                {`${secondsToHourMinutesSeconds(getDurationInSecondsWithAdBreaks(item.trimmed_duration ?? item.duration, []))} (${secondsToHourMinutesSeconds(
                  getDurationInSecondsWithAdBreaks(item.trimmed_duration ?? item.duration, cuepoints),
                )})`}
              </span>
            </span>

            <span className="content-item__provider">
              <span className="content-item__provider__inner">
                {item.feature_id ? item.title.provider.provider_name : null}
                {item.episode_id ? item.season.series.title.provider.provider_name : null}
                {item.promo_id ? item.provider.provider_name : null}
              </span>
            </span>

            <span className="content-item__transcoding-status">
              <TranscodingStatusText status={item?.content_video?.hls_status} popupPosition="left" type={"hls"} />
              <TranscodingStatusText status={item.content_video?.dash_status} popupPosition="left" type={"dash"} />
            </span>
          </span>
          <div className="content-item__col">
            <span className="content-item__actions">
              <span className={`content-item__action ${!item.duration ? "content-item__action--disabled" : ""}`}>
                <button
                  className="btn--text-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addItemWrapper(item);
                    return true;
                  }}
                >
                  {isAdded ? <CheckBoxRoundedIcon /> : <AddBoxRoundedIcon />}
                </button>
              </span>
            </span>
          </div>
        </li>
      )}
    </Draggable>
  );
}
