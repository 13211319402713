import React from "react";
import ReactModal from "react-modal";

export default function TemplateScheduleDeleteConfirmationDialog({ isOpen, onClose, onConfirm }) {
  function onConfirmDelete() {
    onClose();
    onConfirm();
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-copy">
        <div className="v-react-modal__heading">Delete Schedule Confirmation</div>
        <div className="v-react-modal__body modal-copy__body">
          Are you sure you wish to delete this schedule?
          <br />
          For historical data purposes, this schedule will still be visible on past days.
        </div>
        <div className="v-react-modal__footer">
          <button className="btn btn--primary" onClick={onClose}>
            Close
          </button>
          <button className="btn btn--danger-inverse" onClick={onConfirmDelete}>
            Delete Schedule
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
