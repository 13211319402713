import React from "react";
import SimplifiedVerticalScheduler from "./simplified-vertical-scheduler";
import Loader from "../../common/loader";

export default function SimpleSchedulerColumn({ plan, style, openSchedulingView, timezone }) {
  return (
    <div style={{ ...style, ...{ height: "100%", maxHeight: "100%" } }}>
      <div className="weekly-scheduler__header">
        <div
          className="weekly-scheduler__header__label"
          onClick={() => {
            if (plan) {
              openSchedulingView(plan.plan_date);
            }
          }}
        >
          {plan ? plan.plan_date : ""}
        </div>
      </div>
      {plan && plan.plan_breaks ? (
        <div
          className="scheduler-layout__calendar scheduler-layout__calendar--multi"
          onClick={() => openSchedulingView(plan.plan_date)}
        >
          <SimplifiedVerticalScheduler
            programs={plan?.programs}
            planBreaks={plan?.plan_breaks || []}
            planDate={new Date(plan?.plan_date)}
            timezone={timezone}
          />
        </div>
      ) : (
        <div className="weekly-scheduler__loader">
          <Loader />
        </div>
      )}
    </div>
  );
}
