import { EventEmit } from "../utils/EventEmit.js";
import isBetween from "../utils/is-between.js";
import HbbTvAdverts from "./HbbTvAdverts.js";

// import getTestPayload from "../utils/get-test-payload.js";

export class HbbTvScheduler {
  constructor(scheduleEndpoint, adEndpoint, logger, channelName, debugTimeTravel = null) {
    this.events = new EventEmit();
    this.adverts = new HbbTvAdverts(adEndpoint, logger, channelName);
    this.scheduleSource = scheduleEndpoint;
    this.log = logger;
    this.ad_duration = 122; // default value
    this.has_initialised = false;
    this.freeze_updates = false;
    this.tick_count = 0;

    this.initialTime = new Date().getTime();
    this.debugTimeTravel = debugTimeTravel ? new Date(debugTimeTravel).getTime() : null;

    this._initializeSchedule().then((schedule) => {
      this.log.info("[HbbTv Scheduler] INITIALIZE SCHEDULE");
      this.ad_duration = schedule.ad_break_duration;
      this.playlist = this._formatPlaylist(schedule);
      this.currentProgramIndex = 0;
      this.currentProgram = this.playlist[0];
      this.nextProgram = this.playlist[1];

      this.log.info("[HbbTv Scheduler] READY EVENT EMITTING");
      this.events.emit("READY", this.playlist[0]);
    });
  }

  getInformation() {
    return {
      initialised: this.has_initialised ? "yes" : "no",
      currentProgramName: this.currentProgram?.title ?? "-",
      currentProgramStart: this.currentProgram?.stamp_human ?? "-",
      schedulerSecondsSinceStart: this.tick_count,
      isFrozen: this.freeze_updates ? "yes" : "no",
    };
  }

  async _initializeSchedule() {
    try {
      this.log.info("[HbbTv Scheduler] Fetching schedules");
      return await this._fetchPrograms();
    } catch (err) {
      this.log.error("[HbbTv Scheduler] UNABLE TO FETCH PROGRAM ");
      return [];
    }
  }

  async _fetchPrograms() {
    const response = await fetch(this.scheduleSource);
    return await response.json();

    // return new Promise((resolve, reject) => {
    //   resolve(getTestPayload());
    // })
  }

  _tick() {
    this.tick_count += 1;
    if (!this.currentProgram) {
      // nothing to do on this tick, no programs have started
      return;
    }

    const now = this.getCurrentTime();
    const AD_BREAK_MARGIN = 4;

    if (this.currentProgram.cuepoints && this.currentProgram.cuepoints.length) {
      // if we are within ad duration of a previous ad, don't try to play another
      if (!(this.previousAdBreakTime && isBetween(now - this.previousAdBreakTime, 0, this.ad_duration))) {
        // are we close or in any cuepoints?
        let cuepointStart = null;
        let cuepoint = null;
        this.currentProgram.cuepoints.map((marker, index) => {
          const programMarkerTime = this.currentProgram.stamp + marker + index * this.ad_duration; // is a cuepoint upcoming?

          if (isBetween(programMarkerTime - now, 0, AD_BREAK_MARGIN)) {
            cuepointStart = 0;
            cuepoint = marker;
          } else if (isBetween(now - programMarkerTime, 0, this.ad_duration - AD_BREAK_MARGIN)) {
            // are we in a cuepoint? - use lower than ad duration for margin
            cuepointStart = now - programMarkerTime;
            cuepoint = marker;
          }
        });

        if (cuepointStart !== null) {
          this.previousAdBreakTime = now;
          this._triggerAdBreak(cuepointStart, cuepoint);
        }
      }
    }

    // is it time to switch to the next program?
    if (
      this.nextProgram &&
      (isBetween(this.nextProgram.stamp - now, -1, 2) || this.nextProgram.stamp < now) // program has already started, switch immediately
    ) {
      this.playNextProgram();
    }

    // do we need to update the schedule?
    if (
      !this.freeze_updates &&
      !this.playlist[this.currentProgramIndex + 1] &&
      isBetween(this.currentProgram.stamp + this.currentProgram.duration - now, 0, 3)
    ) {
      this.freeze_updates = true;
      this._updatePlaylist()
        .then(() => {
          this.log.info("[HbbTv Scheduler] Playlist updated");
        })
        .catch((e) => {
          this.log.error(e.toString());
        })
        .finally(() => {
          this.freeze_updates = false;
        });
    }
  }

  _nextProgram() {
    if (this.playlist[this.currentProgramIndex + 1]) {
      this.log.info("[HbbTv Scheduler] Next Program Called");
      this.currentProgramIndex += 1;
      this.currentProgram = this.playlist[this.currentProgramIndex];
      this.nextProgram = this.playlist[this.currentProgramIndex + 1];

      this.events.emit("CHANGE_PROGRAM", this.currentProgram);
    } else {
      this.log.info("[HbbTv Scheduler] Next Program Called | No Program to Play");
    }
  }

  playNextProgram() {
    this._nextProgram();
  }

  _formatPlaylist(schedule) {
    if (!schedule || !schedule.playlist || !schedule.playlist.length) {
      return [];
    }

    return schedule.playlist.map((program) => ({
      ...program,
      // @note temporarily using cloudfront instead of cdn77 for ssl cert work around -- THIS IS ONLY FOR MBC TESTING
      streamurl: program.streamurl.replace("1569747740.rsc.cdn77.org", "mbc.freeview.fntvchannel.com"),
    }));
  }

  async _updatePlaylist() {
    this.log.info("[HbbTv Scheduler] Updating Playlist");

    // @todo clean up this freeze updates implementation
    try {
      const schedule = await this._fetchPrograms();
      const formatted = this._formatPlaylist(schedule);

      // there are no updates that need to be triggered
      if (!formatted || !formatted.length) {
        return true;
      }

      if (!this.currentProgram) {
        this.playlist = formatted;
      } else {
        this.playlist =
          this.currentProgram.mid === formatted[0].mid
            ? [this.currentProgram, ...formatted.slice(1)]
            : [this.currentProgram, ...formatted];
      }

      this.ad_duration = schedule.ad_break_duration;
      this.currentProgramIndex = 0;
      if (this.playlist[1]) {
        this.nextProgram = this.playlist[1];
      }

      return true;
    } catch (error) {
      this.log.error(error);
      return false;
    }
  }

  getCurrentProgram() {
    return this.currentProgram;
  }

  getSeekTime(program) {
    const now = this.getCurrentTime();
    const programStart = program.stamp;
    const cuepoints = program.cuepoints;

    if (now < programStart) {
      return 0;
    }

    if (!cuepoints || !cuepoints.length) {
      return now - programStart;
    }

    let programNow = now - programStart; // Current time relative to the program start
    let adTimeElapsed = 0; // Total time spent in ads

    for (let i = 0; i < cuepoints.length; i++) {
      adTimeElapsed += this.ad_duration;

      if (programNow < cuepoints[i] + adTimeElapsed) {
        // assume that the whole cuepoint is invalid
        let adTimeDeduction = this.ad_duration;
        // but maybe we only need to remove part of the ad duration
        let withinAdRuntime = this.ad_duration - Math.max(cuepoints[i] + adTimeElapsed - programNow, 0);
        adTimeElapsed -= Math.max(adTimeDeduction - withinAdRuntime, 0);
        break;
      }
    }

    let seekPosition = Math.max(programNow - adTimeElapsed, 0);

    this.log.info("[HbbTv Scheduler] Get Seek Returns", seekPosition, adTimeElapsed);
    return seekPosition;
  }

  _triggerAdBreak(seekTime, cuepoint, retry = true) {
    this.log.info(`[HbbTv Scheduler] Ad Break Triggered at seek time [${seekTime}] for cuepoint [${cuepoint}]`);
    this.adverts
      .fetchAds(this.ad_duration - seekTime, this.currentProgram.macros)
      .then((data) => {
        if (data) {
          this.events.emit("AD_BREAK", { adverts: data, cuepoint });
        }
      })
      .catch((error) => {
        this.log.error(error);
        if (retry) {
          setTimeout(() => {
            this._triggerAdBreak(seekTime, cuepoint, false);
          }, 1000);
        }
      });
  }

  didInitialise() {
    // start ticker
    if (!this.has_initialised) {
      setInterval(() => {
        this._tick();
      }, 1000);
    }
  }

  getCurrentTime() {
    if (this.debugTimeTravel) {
      return Math.floor((this.debugTimeTravel + (new Date().getTime() - this.initialTime)) / 1000);
    }

    return Math.floor(new Date().getTime() / 1000);
  }
}
