import React from "react";
import axios from "../requests/axios.js";
import AppBody from "../layout/parts/app-body.jsx";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import { Link } from "react-router-dom";
import { format, addDays } from "date-fns";
import ImageWithFallback from "../components/image-with-fallback.jsx";
import { toast } from "react-toastify";
import { CalendarMonthRounded } from "@mui/icons-material";

export function getPlanFullness(channel, planIndex) {
  if (!channel.plans[planIndex].status) {
    return { value: "unstarted", label: "Not started" };
  }

  switch (channel.plans[planIndex].status) {
    case "DRAFT":
      return { value: "unstarted", label: "Not started" };

    case "PLANNING":
      return { value: "started", label: "Started" };

    case "PLANNED":
    case "PUBLISHED":
      return { value: "complete", label: "Complete" };

    case "ERROR":
      return { value: "error", label: "Error" };

    default:
      return { value: "unstarted", label: "Not started" };
  }
}

function ChannelListPage() {
  const [isPageLoading, setPageLoading] = React.useState(true);
  const [channels, setChannels] = React.useState(null);

  React.useEffect(() => {
    axios
      .get(`api/channels/upcoming`)
      .then(({ data }) => {
        setPageLoading(false);
        setChannels(data);
      })
      .catch((e) => {
        console.error(e);
        toast.error("There was an error loading channel data. Please contact support");
      });
  }, []);

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/channels",
            title: "Channels",
          },
        ]}
      />
      <AppBody loading={isPageLoading && channels !== null && channels.length}>
        <div>
          <div className="gw">
            {channels?.map((channel, index) => (
              <div
                className="g g--tall g--1-of-1 g--desk-ultra-wide-1-of-3 g--desk-wide-1-of-2 g--desk-1-of-2"
                key={index}
              >
                <div className="link-preview-box-container">
                  <Link className="link-preview-box" to={channel.channel_id}>
                    <span
                      className={`link-preview-box__image ${!channel.poster ? "link-preview-box__image--missing-image" : ""}`}
                    >
                      <ImageWithFallback url={channel.poster} />
                    </span>
                  </Link>
                  <span className="link-preview-box__footer">
                    <span className="u-display--flex-apart u-width-100 u-flex--rows--wrap">
                      <span>
                        {channel.plans.map((plan, idx) => {
                          const status = getPlanFullness(channel, idx);
                          return (
                            <Link
                              className={`forecast-dot forecast-dot--${status.value}`}
                              aria-label={`${format(addDays(new Date(), idx), "MMM do")} - ${status.label}`}
                              data-balloon-pos="up-left"
                              to={`${channel.channel_id}/scheduler/${plan.plan_date}`}
                              key={idx}
                            ></Link>
                          );
                        })}
                      </span>
                      <span>
                        <Link className={"btn btn--icon"} to={`${channel.channel_id}/scheduler`}>
                          <CalendarMonthRounded />
                        </Link>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </AppBody>
    </React.Fragment>
  );
}

export default ChannelListPage;
