import React from "react";
import Loader from "../common/loader.jsx";
import fetcher from "../common/fetcher.js";
import EpgEditor from "./epg-editor/epg-editor.jsx";
import ChannelHeading from "./epg-editor/components/channel-heading.jsx";
import dateIsBetween from "../common/date-is-between.js";
import { addSeconds, endOfDay, isAfter, startOfDay, format } from "date-fns";
import axios, { errorHandler, responseHandler } from "../requests/axios.js";
import { toast } from "react-toastify";
import ErrorBoundary from "../components/error-boundary.jsx";
import UnsavedChangesDialog from "./epg-editor/components/dialogs/unsaved-changes-dialog.jsx";
import { v4 as uuid } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import SchedulerProvider, { getRenderTimeForTimezone } from "../providers/scheduler-context.jsx";
import { areEpgProgramCuepointsSpaced } from "./epg-editor/utils/cuepoint-spacing-validator.js";
import RemoveBreakWithFilterDialog from "./epg-editor/components/dialogs/remove-break-with-filter-dialog.jsx";
import differenceInSeconds from "date-fns/differenceInSeconds";

function SchedulerPage() {
  const DEFAULT_DESTINATION = "media-tailor";

  const [isFetchingChannel, setFetchingChannel] = React.useState(true);
  const [isFetchingPlan, setFetchingPlan] = React.useState(true);
  const [channel, setChannel] = React.useState(null);
  const [plan, setPlan] = React.useState(null);
  const [lastChanged, setLastChanged] = React.useState(null);
  const [lastSaved, setLastSaved] = React.useState();
  const saveToastRef = React.useRef();
  const { channelGuid, planDate } = useParams();
  const [clearPlanToggle, setClearPlanToggle] = React.useState(0);
  const [timezone, setTimezone] = React.useState(window.localStorage.getItem("__gstv_timezone") ?? "Europe/London");
  const [activePlayback, setActivePlayback] = React.useState({});
  const [isSaving, setSaving] = React.useState(false);
  const [autosaveEnabled, setAutosaveEnabled] = React.useState(false);
  const [isAutosaving, setIsAutosaving] = React.useState(false); // trigger the autosave
  const navigate = useNavigate();

  const epgEditorRef = React.useRef();

  function resetLayout() {
    epgEditorRef.current.resetLayout();
  }

  function resetFilter() {
    epgEditorRef.current.resetFilter();
  }

  const notifyUpdated = React.useCallback(() => {
    setLastChanged(new Date());
  }, []);

  const [year, month, day] = planDate.split("-");
  const referencePlanDate = React.useMemo(() => new Date(planDate), [planDate]);
  referencePlanDate.setFullYear(year, month - 1, day);

  const [changesDialog, setChangesDialog] = React.useState({
    open: false,
  });

  const [removeBreakDialog, setRemoveBreakDialog] = React.useState({
    open: false,
  });

  function updateActiveDate(input) {
    navigate(`/channels/${channelGuid}/scheduler/${format(input, "y-MM-dd")}`);
  }

  function toggleAutosaving() {
    setAutosaveEnabled((prev) => !prev);
  }

  function notifyPlanCleared() {
    setClearPlanToggle(clearPlanToggle + 1);
  }

  function resetClearPlanNotifier() {
    setClearPlanToggle(0);
  }

  function clearPlan(plan) {
    setPlan(plan);
    notifyPlanCleared();
  }

  function openChangesDialog(action, message, newDate = null) {
    setChangesDialog({
      open: true,
      action,
      message,
      newDate,
    });
  }

  function closeChangesDialog() {
    setChangesDialog({});
  }

  function openRemoveBreakDialog(segment, deleteIndex) {
    setRemoveBreakDialog({
      open: true,
      segment,
      deleteIndex,
    });
  }

  function closeRemoveBreakDialog() {
    setRemoveBreakDialog({});
  }

  const scheduleUpdated = React.useCallback(() => {
    return lastChanged.getTime() > new Date(lastSaved).getTime();
  }, [lastChanged, lastSaved]);

  // fetch the channel
  async function getChannel(id) {
    return await fetcher(`/api/channels/${id}`);
  }

  const verifyPlanDoesNotExceedDay = React.useCallback(
    (epg) => {
      const scheduleEnd = plan?.plan_end ? new Date(plan.plan_end) : addSeconds(endOfDay(new Date()), 1);

      const postInsertDuration = epg.reduce((prev, curr) => {
        return prev + curr.__gstvMeta.total_duration_seconds;
      }, 0);

      const lastProgram = epg[epg.length - 1];
      if (lastProgram && isAfter(lastProgram.till, scheduleEnd)) {
        return false;
      }

      return postInsertDuration <= 86400;
    },
    [plan?.plan_end],
  );

  const saveChannelPlan = React.useCallback(() => {
    // this is a really bad idea, but lets go
    if (window.__gstv_get_current_epg) {
      const epg = window.__gstv_get_current_epg();

      if (hasInactivePrograms(epg)) {
        toast.error("You cannot save a plan with inactive programs.");
        return;
      }

      if (validatePrograms(epg, plan.plan_breaks).length) {
        toast.error("You cannot save a plan with programs overlapping breaks.");
        return;
      }

      if (!verifyPlanDoesNotExceedDay(epg)) {
        toast.error("Scheduled items cannot exceed 24 hours");
        return;
      }

      let cuepointsCorrectlySpaced = true;
      epg.forEach((epgProgram) => {
        if (!areEpgProgramCuepointsSpaced(epgProgram, getRenderTimeForTimezone)) {
          cuepointsCorrectlySpaced = false;
        }
      });
      if (!cuepointsCorrectlySpaced) {
        return;
      }

      setSaving(true);
      const payload = {};
      payload.programs = epg.map((program) => {
        return {
          ad_breaks: program.__gstvMeta.ad_breaks,
          duration_seconds: program.__gstvMeta.duration_seconds,
          total_duration_seconds: program.__gstvMeta.total_duration_seconds,
          link_id: program.__gstvMeta.link_guid,
          link_type: program.__gstvMeta.link_type,
          program_id: program.__gstvMeta.program_id,
          program_start: program.since,
          program_end: program.till,
        };
      });

      payload.plan_breaks = plan.plan_breaks.map((pb) => ({
        type: pb.type,
        end: pb.end,
        start: pb.start,
      }));

      payload.filters = plan.filters;

      saveToastRef.current = toast.loading("Saving schedule...");
      axios
        .post(`/api/channels/${channel.channel_id}/plans/${plan.channel_plan_id}`, payload)
        .then(
          responseHandler((t) => {
            setLastChanged(t.data.updated_at);
            setLastSaved(addSeconds(new Date(t.data.updated_at), 5)); // buffer time for last saved comparisons
            const plan = t.data;
            plan.programs.forEach((planProgram) => {
              planProgram.program_start = new Date(planProgram.program_start);
              planProgram.program_end = new Date(planProgram.program_end);
              return planProgram;
            });

            plan.plan_breaks = plan.plan_breaks.map((planBreak) => {
              const start = new Date(planBreak.start);
              const end = new Date(planBreak.end);
              return {
                type: planBreak.type,
                start,
                end,
                duration: differenceInSeconds(end, start),
              };
            });

            setPlan(plan);
            epgEditorRef.current.forceUpdatePlan(plan);

            toast.update(saveToastRef.current, {
              render: "Scheduled saved successfully",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
          }),
        )
        .catch(
          errorHandler((e) => {
            console.error(e);
            let message = "Failed to save schedule";
            if (e?.data?.message) {
              message = e.data.message;
            } else if (e?.data?.errors) {
              message = "" + e.data.errors;
            }
            toast.update(saveToastRef.current, {
              render: message,
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
          }),
        )
        .finally(() => setSaving(false));
    }
  }, [channel?.channel_id, plan?.channel_plan_id, plan?.plan_breaks, plan?.filters, verifyPlanDoesNotExceedDay]);

  React.useEffect(() => {
    setFetchingChannel(true);
    getChannel(channelGuid)
      .then((channel) => {
        setChannel(channel);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setFetchingChannel(false);
      });
  }, [channelGuid]);

  React.useEffect(() => {
    setFetchingPlan(true);

    const controller = new AbortController();
    axios
      .get(`/api/channels/${channelGuid}/plans?plan_date=${planDate}`, { signal: controller.signal })
      .then((resp) => {
        const plan = resp.data;
        plan.programs.forEach((planProgram) => {
          planProgram.program_start = new Date(planProgram.program_start);
          planProgram.program_end = new Date(planProgram.program_end);
          return planProgram;
        });

        plan.plan_breaks = plan.plan_breaks.map((planBreak) => {
          const start = new Date(planBreak.start);
          const end = new Date(planBreak.end);
          return {
            type: planBreak.type,
            start,
            end,
            duration: differenceInSeconds(end, start),
          };
        });
        setPlan(plan);
        setLastSaved(plan.updated_at);
        setLastChanged(new Date(plan.updated_at));
        setFetchingPlan(false);
      })
      .catch((e) => {
        if (e.name && e.name === "CanceledError") {
          // do nothing, cancelling old request
        } else {
          console.error(e);
          setFetchingPlan(false);
        }
      });
    return () => controller.abort();
  }, [channelGuid, planDate, referencePlanDate]);

  React.useEffect(() => {
    let timer;
    if (lastChanged) {
      timer = setTimeout(() => {
        if (scheduleUpdated()) {
          openChangesDialog(
            "timeoutWithChanges",
            "It's been 10 minutes without saving. Don't forget to save your changes.",
          );
        }
      }, 600000);
    }
    return () => clearTimeout(timer);
  }, [lastSaved, lastChanged, scheduleUpdated]);

  // when channel changes, decide which url to use
  React.useEffect(() => {
    if (channel?.channel_id && window.localStorage.getItem(`__gstv-ui__channel-playback-url__${channel.channel_id}`)) {
      const playbackData = window.localStorage
        .getItem(`__gstv-ui__channel-playback-url__${channel.channel_id}`)
        .split("___");

      setActivePlayback({
        url: playbackData[0],
        type: playbackData[1],
        destination: playbackData[2] ?? DEFAULT_DESTINATION,
      });
    } else if (channel?.url) {
      setActivePlayback({ url: channel.url, type: "hls", destination: DEFAULT_DESTINATION }); // assume media tailor by default
    }
  }, [channel?.channel_id, channel?.url]);

  // when selected playback_url changes
  React.useEffect(() => {
    // save non-null urls to localStorage
    if (channel?.channel_id && activePlayback?.url) {
      window.localStorage.setItem(
        `__gstv-ui__channel-playback-url__${channel.channel_id}`,
        `${activePlayback.url}___${activePlayback.type}___${activePlayback.destination}`,
      );
    }
  }, [activePlayback?.url, activePlayback?.type, activePlayback?.destination, channel?.channel_id]);

  // create Interval for autosaving
  React.useEffect(() => {
    const autosave = setInterval(() => {
      setIsAutosaving(true);
    }, 60 * 1000); // runs once a minute
    return () => {
      setIsAutosaving(false);
      clearInterval(autosave); // cleanup interval
    };
  }, []);

  // using two effects to avoid stale data
  React.useEffect(() => {
    if (autosaveEnabled && isAutosaving) {
      saveChannelPlan();
      setIsAutosaving(false);
    }
  }, [autosaveEnabled, isAutosaving, saveChannelPlan]);

  async function addPlanBreak(startTime) {
    const breaksCopy = plan.plan_breaks.map((pb) => ({ ...pb }));
    let referenceDate = new Date(planDate);
    const [year, month, day] = planDate.split("-");
    referenceDate.setFullYear(year, month - 1, day);
    referenceDate = startOfDay(referenceDate);

    const targetBreakIndex = breaksCopy.findIndex((pb) => {
      return dateIsBetween(startTime, pb.start, pb.end);
    });

    const end = breaksCopy[targetBreakIndex].end;
    const newBreak = {
      start: startTime,
      end,
      type: "plan",
    };
    breaksCopy[targetBreakIndex].end = startTime;
    breaksCopy.splice(targetBreakIndex + 1, 0, newBreak);

    setPlan((plan) => ({
      ...plan,
      plan_breaks: breaksCopy,
    }));
  }

  function onDeletePlanBreakCall(segment) {
    const filters = plan?.filters.length ? [...plan.filters] : [];
    const filterIndex = -1; // @todo fix when we get to plan breaks

    if (filterIndex > -1) {
      openRemoveBreakDialog(segment, filterIndex);
    } else {
      deletePlanBreak(segment);
    }
  }

  async function deletePlanBreak(segment) {
    const breaksCopy = plan.plan_breaks.map((pb) => ({ ...pb }));
    const planBreakIndex = breaksCopy.findIndex((pb) => pb.start === segment.start);
    breaksCopy[planBreakIndex - 1].end = breaksCopy[planBreakIndex].end;
    breaksCopy.splice(planBreakIndex, 1);

    setPlan((plan) => {
      return {
        ...plan,
        plan_breaks: breaksCopy,
      };
    });
  }

  async function deletePlanBreakWithFilter(segment, deleteIndex) {
    const planBreaks = [...plan.plan_breaks];
    const planBreakIndex = planBreaks.findIndex((pb) => pb.start === segment.label);
    planBreaks[planBreakIndex - 1].end = planBreaks[planBreakIndex].end;
    planBreaks.splice(planBreakIndex, 1);

    const filters = [...plan.filters];
    filters.splice(deleteIndex, 1);

    setPlan((plan) => {
      return {
        ...plan,
        filters,
        plan_breaks: planBreaks,
      };
    });
    resetFilter();
  }

  function hasInactivePrograms(plan) {
    return !plan.every((program) => !!program.isActive);
  }

  function validatePrograms(epg, planBreaks) {
    return epg.filter((program) => {
      return planBreaks.some((pBreak) => {
        const result = dateIsBetween(pBreak.end, program.since, program.till, "()");
        return result;
      });
    });
  }

  function toggleTimezone() {
    const nextTimezone = timezone === "Etc/UTC" ? "Europe/London" : "Etc/UTC";
    setTimezone(nextTimezone);
    window.localStorage.setItem("__gstv_timezone", nextTimezone);
  }

  function handleError() {
    console.error("Irrecoverable error");
    window.location.reload();
  }

  return (
    <SchedulerProvider
      currentTimezone={timezone}
      scheduleStart={plan?.plan_start ? new Date(plan.plan_start) : startOfDay(new Date())}
      scheduleEnd={plan?.plan_end ? new Date(plan.plan_end) : addSeconds(endOfDay(new Date()), 1)}
    >
      <ErrorBoundary onError={handleError}>
        {(hasError, resolveError) =>
          isFetchingChannel || !plan ? (
            <div className="scheduler-loader">
              <Loader />
            </div>
          ) : (
            <div className="spread-container">
              <div className="spread-container__top">
                <ChannelHeading
                  channel={channel}
                  planDate={referencePlanDate}
                  changePlanDate={updateActiveDate}
                  clearPlan={clearPlan}
                  saveChannelPlan={saveChannelPlan}
                  planId={plan?.channel_plan_id}
                  planUpdated={lastSaved}
                  scheduleUpdated={scheduleUpdated}
                  openChangesDialog={openChangesDialog}
                  toggleTimezone={toggleTimezone}
                  playoutUrl={activePlayback}
                  setPlayoutUrl={setActivePlayback}
                  isSaving={isSaving}
                  autosaveEnabled={autosaveEnabled}
                  toggleAutosave={toggleAutosaving}
                  resetLayout={resetLayout}
                />
              </div>
              <div className="spread-container__middle">
                {!isFetchingPlan ? (
                  <EpgEditor
                    channel={channel}
                    plan={plan}
                    activeDate={referencePlanDate}
                    addPlanBreak={addPlanBreak}
                    deletePlanBreak={onDeletePlanBreakCall}
                    hasError={hasError}
                    resolveError={resolveError}
                    notifyUpdated={notifyUpdated}
                    clearPlanNotifier={clearPlanToggle}
                    resetClearNotifier={resetClearPlanNotifier}
                    playbackData={activePlayback}
                    ref={epgEditorRef}
                  />
                ) : (
                  <Loader />
                )}
              </div>
              <UnsavedChangesDialog
                newDate={changesDialog.newDate}
                isOpen={changesDialog.open}
                onClose={closeChangesDialog}
                updatePlanDate={updateActiveDate}
                message={changesDialog.message}
                action={changesDialog.action}
                key={`unsaved_changes_dialog-${uuid()}`}
              />
              <RemoveBreakWithFilterDialog
                isOpen={removeBreakDialog.open}
                segment={removeBreakDialog.segment}
                deleteIndex={removeBreakDialog.deleteIndex}
                onConfirm={deletePlanBreakWithFilter}
                onClose={closeRemoveBreakDialog}
                key={`remove_break_with_filter_dialog-${uuid()}`}
              />
            </div>
          )
        }
      </ErrorBoundary>
    </SchedulerProvider>
  );
}

export default SchedulerPage;
