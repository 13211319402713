import React from "react";
import Sidebar from "./parts/sidebar.jsx";
import { useAuth } from "../providers/auth-provider.jsx";
import { Navigate, useLocation } from "react-router-dom";
import BasePage from "./base-page.jsx";

const DefaultLayout = ({ sidebarId, children, title = "" }) => {
  const { user } = useAuth();
  const location = useLocation();

  React.useEffect(() => {
    document.title = title;
  }, [sidebarId, title]);

  if (!user) {
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  return (
    <BasePage>
      <div className={`app-route ${sidebarId}`}>
        <Sidebar currentPage={sidebarId} />
        <div className="app-wrapper">{children}</div>
        {/*<div className="island island--smaller">*/}
        {/*  <div className="wrap wrap--larger">{children}</div>*/}
        {/*</div>*/}
      </div>
    </BasePage>
  );
};

export default DefaultLayout;
