import React, { createContext } from "react";
import { getTimezoneOffset } from "date-fns-tz";
import { addSeconds, format } from "date-fns";

window.getTimezoneOffset = getTimezoneOffset;

const SchedulerContext = createContext({
  selectedTimezone: "", // "UTC" || "Europe/London"
  scheduleStart: new Date(),
  scheduleEnd: new Date(),
  getRenderTime: (_t, _f) => "", // GMT +2 ==> (Selected TZ Offset * -1) + Browser Offset <-- only in renders
  getTimezoneOffsetSeconds: (_date) => 0,
});

export function getRenderTimeForTimezone(date, timezone, dateFormat = "HH:mm:ss", debug) {
  const selectedOffset = getTimezoneOffset(timezone, date) / 1000;
  const browserOffset = date.getTimezoneOffset() * 60;

  const result = addSeconds(date, selectedOffset + browserOffset);

  // despite best efforts we still need to do some magic during GMT --> BST
  if (timezone === "Etc/UTC" && result.getTimezoneOffset() !== date.getTimezoneOffset()) {
    // manually build template
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");

    if (dateFormat === "HH:mm:ss") {
      return `${hours}:${minutes}:${seconds}`;
    } else if (dateFormat === "HH:mm") {
      return `${hours}:${minutes}`;
    }
  }

  if (debug) {
    console.debug(result, format(result, dateFormat));
  }

  return format(result, dateFormat);
}

function useSchedulerProvider(currentTimezone, scheduleStart, scheduleEnd) {
  const [selectedTimezone, setTimezone] = React.useState(currentTimezone || "UTC");
  const scheduleTimes = React.useMemo(
    () => ({
      start: scheduleStart,
      end: scheduleEnd,
    }),
    [scheduleEnd, scheduleStart],
  );

  // takes in date in user's timezone and returns the associated date in the selected timezone
  const getRenderTime = React.useCallback(
    (date, dateFormat = "HH:mm:ss", debug) => {
      return getRenderTimeForTimezone(date, selectedTimezone, dateFormat, debug);
    },
    [selectedTimezone],
  );

  const getTimezoneOffsetSeconds = React.useCallback(
    (date = new Date()) => {
      const selectedOffset = getTimezoneOffset(selectedTimezone, date) / 1000;
      const browserOffset = date.getTimezoneOffset() * 60;
      return selectedOffset + browserOffset;
    },
    [selectedTimezone],
  );

  React.useEffect(() => {
    setTimezone(currentTimezone ?? "Etc/UTC");
  }, [currentTimezone]);

  return {
    selectedTimezone,
    scheduleStart: scheduleTimes.start,
    scheduleEnd: scheduleTimes.end,
    getRenderTime,
    getTimezoneOffsetSeconds,
  };
}

export function useSchedulerContext() {
  return React.useContext(SchedulerContext);
}

export default function SchedulerProvider({ children, currentTimezone, scheduleStart, scheduleEnd }) {
  const context = useSchedulerProvider(currentTimezone, scheduleStart, scheduleEnd);
  return <SchedulerContext.Provider value={context}>{children}</SchedulerContext.Provider>;
}
