import React from "react";
import ContentList from "../components/content-list/content-list.jsx";
import ContentListFilters from "../components/content-list/content-list-filters.jsx";
import ContentListResults from "../components/content-list/content-list-results.jsx";
import ContentListPagination from "../components/content-list/content-list-pagination.jsx";
import useContentList from "../components/content-list/use-content-list.js";
import Loader from "../common/loader.jsx";
import { toast } from "react-toastify";
import CreatePromoWindow from "././library/create-promo.jsx";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import ExportContentDialog from "./components/dialogs/export-content-dialog.jsx";
import LibraryList from "./library/components/library-list/library-list.jsx";
import axios, { errorHandler, responseHandler } from "../requests/axios.js";

function LibraryPage({ exportDialogVisible, closeExportDialog }) {
  const [providers, setProviders] = React.useState([]);
  const [series, setSeries] = React.useState({});
  const [pageSize, setPageSize] = React.useState(50); // 50 items per page by default
  const [isFetchingProviders, setIsFetchingProviders] = React.useState(true);
  const { programs, isLoading: contentListLoading, getPrograms, requestedUrl } = useContentList({ planDate: null });

  const resetSeries = React.useCallback(() => {
    setSeries({});
  }, []);

  React.useEffect(() => {
    setIsFetchingProviders(true);
    axios
      .get(`api/providers`)
      .then(
        responseHandler((response) => {
          const newProviders = response.data.data
            .sort((a, b) => (a.provider_active > b.provider_active ? -1 : 1))
            .map((provider) => {
              if (provider.provider_active === 0) {
                provider.provider_name = provider.provider_name + " (Inactive)";
              }
              return provider;
            });

          setProviders(newProviders);
          setIsFetchingProviders(false);
        }),
      )
      .catch(
        errorHandler((error) => {
          toast.error("There was an error loading providers");
          console.error(error);
        }),
      );
  }, []);

  return isFetchingProviders ? (
    <div className="page-loader-wrapper">
      <Loader />
    </div>
  ) : (
    <React.Fragment>
      <ContentList>
        <ContentListFilters
          providers={providers}
          getPrograms={getPrograms}
          overridePagesize={pageSize}
          displayAllFilters={true}
          alignSortRight={true}
          flexible={true}
          showInactiveContent={true}
          showRestrictedContent={true}
          cacheOnPath={true}
          series={series}
          resetSeries={resetSeries}
        />
        <ContentListResults isLoading={contentListLoading} results={programs?.data}>
          {(results) => <LibraryList results={results} />}
        </ContentListResults>
        <ContentListPagination
          getPrograms={getPrograms}
          prevUrl={programs?.prev_page_url}
          nextUrl={programs?.next_page_url}
          size={pageSize}
          setSize={setPageSize}
          isLoading={contentListLoading}
        />
      </ContentList>
      <ExportContentDialog isOpen={exportDialogVisible} onClose={() => closeExportDialog()} url={requestedUrl} />
    </React.Fragment>
  );
}

function LibraryPageState({ ...props }) {
  const [showCreate, setShowCreate] = React.useState(false);
  const [showExportDialog, setShowExportDialog] = React.useState(false);

  function toggleCreate() {
    setShowCreate((prev) => !prev);
  }

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/content",
            title: "Content",
          },
          {
            link: "/content/library",
            title: "Library",
          },
        ]}
        rightActions={[
          {
            icon: SimCardDownloadRoundedIcon,
            onClick: () => setShowExportDialog(true),
            balloonLabel: "Export Content",
          },
          {
            icon: AddCircleRoundedIcon,
            onClick: toggleCreate,
            balloonLabel: "Create Promo",
          },
        ]}
      />
      <AppBody>
        <div className="sliding-panel-container">
          <div className="sliding-panel sliding-panel--full">
            <LibraryPage
              exportDialogVisible={showExportDialog}
              closeExportDialog={() => setShowExportDialog(false)}
              {...props}
            />
          </div>
        </div>
        <CreatePromoWindow
          isOpen={showCreate}
          onDone={toggleCreate}
          onSuccess={() => {
            toast.success("Promo processing...This process may take up to an hour.");
          }}
        />
      </AppBody>
    </React.Fragment>
  );
}

export default LibraryPageState;
