import React from "react";
import getClassList from "../../../functions/get-class-list.js";

function ContentCard({
  noPadding = false,
  hasOverlay = false,
  withBackground = false,
  withDarkestBackground = false,
  maxHeight = null,
  children,
}) {
  const classList = getClassList({
    card: true,
    "card--no-padding": noPadding,
    "card--background": withBackground,
    "card--background-dkst": withDarkestBackground,
    "card--has-overlay": hasOverlay,
  });

  const styles = {
    ...(maxHeight ? { maxHeight: maxHeight } : {}),
  };

  return (
    <div className={classList} style={styles}>
      {children}
    </div>
  );
}

export default ContentCard;
