import React from "react";
import ReactModal from "react-modal";
import Select from "../../../common/select.jsx";

export default function CopyTemplateDayDialog({ isOpen, onClose, onSubmit, dayNumber = 0, dayOptions }) {
  const [sourceDay, setSourceDay] = React.useState();
  const [targetDay, setTargetDay] = React.useState();
  const [error, setError] = React.useState();

  React.useEffect(() => {
    setSourceDay({ value: dayNumber, label: `Day ${dayNumber}` });
  }, [dayNumber]);

  function beforeSubmit() {
    if (sourceDay.value === targetDay.value) {
      setError("You cannot copy a day onto itself");
      return;
    }

    if (!sourceDay.value) {
      setError("Please select a source day.");
      return;
    }

    if (!targetDay.value) {
      setError("Please select a target day.");
      return;
    }

    onSubmit(sourceDay.value, targetDay.value);
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-width">
        <div className="v-react-modal__content">
          <div className="v-react-modal__heading">Copy Day</div>
          <div className="v-react-modal__body modal-body">
            <div>Select the source day to use in the copy:</div>
            <Select
              options={dayOptions.map((value) => ({ value, label: `Day ${value}` }))}
              placeholder="Select source day"
              onChange={setSourceDay}
              value={sourceDay}
            />
            <div>Select the target day:</div>
            <Select
              options={dayOptions
                .map((value) => ({
                  value,
                  label: `Day ${value}`,
                }))
                .concat({ value: dayOptions.length + 1, label: `Day ${dayOptions.length + 1}` })}
              placeholder="Select target day"
              onChange={setTargetDay}
              defaultValue={dayNumber}
            />
          </div>
          {error ? <span className="u-fc--warning">{error}</span> : null}
        </div>

        <div className="v-react-modal__footer">
          <button className="btn btn--inverse-primary" onClick={onClose}>
            Nevermind
          </button>
          <button className="btn btn--primary" onClick={beforeSubmit}>
            Copy Day
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
