import React from "react";
import { useSchedulerContext } from "../../providers/scheduler-context.jsx";
import { isPast } from "date-fns";

function SimpleSchedulerProgram({
  program,
  segment,
  getHeightFromSeconds,
  smallDuration,
  smallOffset,
  prependMargin = 0,
}) {
  const programRef = React.useRef();
  const { getRenderTime } = useSchedulerContext();

  function getHeightStyleFromSeconds(seconds) {
    return `${getHeightFromSeconds(seconds)}px`;
  }

  function programExceedsSegmentLength(segmentEnd, programEnd) {
    return programEnd > segmentEnd;
  }

  function programInactive(program) {
    return !program.isActive;
  }

  const isSmall = getHeightFromSeconds(program.total_duration_seconds) < smallDuration;
  const isPastState = isPast(program.since);
  let transformStyle = {};

  if (isSmall) {
    const offsets = [-150, -50, 50];
    transformStyle = {
      transform: `translateX(${offsets[smallOffset[0] + 1]}%) translateY(${-50 + 100 * smallOffset[1]}%)`,
    };
  }

  return (
    <div className="vertical-scheduler__program">
      <div
        ref={programRef}
        style={{
          height: getHeightStyleFromSeconds(program.total_duration_seconds),
          marginTop: `${prependMargin}px`,
        }}
        className={`vertical-scheduler-program vertical-scheduler-program--wide ${isSmall ? "vertical-scheduler-program--small" : ""} ${
          programExceedsSegmentLength(segment.end, program.till) ? "vertical-scheduler-program--exceeded" : ""
        } ${programInactive(program) ? "vertical-scheduler-program--inactive" : ""} ${
          isPastState ? "vertical-scheduler-program--is-past" : ""
        }`}
        {...(programInactive(program)
          ? {
              "aria-label": "Program is inactive and must be removed.",
              "data-balloon-pos": "up",
            }
          : {})}
      >
        {isSmall ? (
          <div
            className={`vertical-scheduler-program__inner vertical-scheduler-program__inner--small ${program.Type !== "promo" ? "vertical-scheduler-program__inner--primary" : ""}`}
            style={transformStyle}
          >
            <div className="vertical-scheduler-program__content">
              <span>{getRenderTime(program.till)}</span>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className="vertical-scheduler-program__inner">
              <div className="vertical-scheduler-program__overflow-container">
                <div className="vertical-scheduler-program__content">
                  <span>{`${program.title} (${getRenderTime(program.since)} to `}</span>
                  <span>{getRenderTime(program.till)}</span>
                  {")"}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default SimpleSchedulerProgram;
