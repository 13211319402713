export const TCF_CONSENT_BASE = "__gstv_";
export const LAST_CONSENT_COOKIE_NAME = "__gstv_last_consent";
export const DATA_RETENTION_DAYS = 180;

export function getCookie(name) {
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      // +1 because cookies are expected in the format {name}={value}
      return c.substring(name.length + 1, c.length);
    }
  }

  return "";
}

export function setCookie(name, value) {
  let d = new Date();
  d.setTime(d.getTime() + DATA_RETENTION_DAYS * 24 * 60 * 60 * 1000); // days * hours * minutes * seconds * milliseconds
  let expires = "expires=" + d.toUTCString();

  document.cookie = name + "=" + value + "; " + expires + ";path=/";
}

export function expireCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
