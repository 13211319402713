export default class Logger {
  constructor(type = null, externalLogsEnabled = false) {
    const baseEndpoint = "webhook.site/ab5d24f5-25a1-4a71-98f4-1b9b995ac9c2";
    this.dispatchEndpoint = externalLogsEnabled ? `https://${baseEndpoint}` : null;
    switch (type) {
      case "console":
        this.logger = console;
        break;
      case "screen":
        this.logger = new ScreenLogger();
        break;
      case null:
      default:
        this.logger = {
          log() {},
          warn() {},
          error() {},
        };
        break;
    }
  }

  info(...messages) {
    this.logger.log(...messages);
  }

  warn(...messages) {
    this.logger.warn(...messages);
  }

  error(...messages) {
    this.logger.error(...messages);
  }

  dispatch(...messages) {
    if (!this.dispatchEndpoint) {
      this.info(...messages);
      return;
    }

    fetch(this.dispatchEndpoint, {
      method: "POST",
      body: JSON.stringify(messages),
    })
      .then(() => null)
      .catch(() => null);
  }
}

class ScreenLogger {
  constructor() {
    this.element = document.getElementById("safe_area");
  }

  log(...messages) {
    if (!this.canLog()) {
      return;
    }

    this.write(">>> ", ...messages);
  }

  warn(...messages) {
    if (!this.canLog()) {
      return;
    }

    this.write(">>> WARNING", ...messages);
  }

  error(...messages) {
    if (!this.canLog()) {
      return;
    }

    this.write(">>> ERROR", ...messages);
  }

  canLog() {
    return !!this.element;
  }

  write(...messages) {
    if (!messages.length || messages.length === 1) {
      return;
    }

    if (messages.length === 2) {
      this.element.innerHTML += "<div>" + messages[0] + " " + messages[1] + "</div>";
    } else if (messages.length > 2) {
      this.element.innerHTML += "<div>" + messages[0] + " " + messages[1] + "</div>";
      messages.slice(2).map((message) => {
        this.element.innerHTML += "<div>" + JSON.stringify(message) + "</div>";
      });
    }

    this.element.scrollTop = this.element.scrollHeight;
  }
}
