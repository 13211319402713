import React from "react";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import DashboardNav from "./dashboards/dashboard-nav.jsx";
import { DvrRounded, EqualizerRounded, OndemandVideoRounded, PaidRounded } from "@mui/icons-material";
import InsightsIcon from "@mui/icons-material/Insights";
import TableChartIcon from "@mui/icons-material/TableChart";

function AnalyticsHomePage() {
  const dashboardNavLinks = React.useMemo(() => {
    return [
      { url: "/analytics/overview", icon: EqualizerRounded, label: "Overview", key: "overview" },
      { url: "/analytics/performance", icon: OndemandVideoRounded, label: "Performance", key: "performance" },
      { url: "/analytics/monetization", icon: PaidRounded, label: "Monetization", key: "monetization" },
      { url: "/analytics/provider", icon: DvrRounded, label: "Provider", key: "provider" },
      { url: "/analytics/custom", icon: InsightsIcon, label: "Custom", key: "custom" },
      { url: "/analytics/reports", icon: TableChartIcon, label: "Reports", key: "reports" },
    ];
  }, []);

  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "",
            title: "Analytics",
          },
        ]}
      />
      <AppBody>
        <div className="analytics">
          <DashboardNav links={dashboardNavLinks} />
        </div>
      </AppBody>
    </React.Fragment>
  );
}

export default AnalyticsHomePage;
