import React, { forwardRef } from "react";
import useApiRequest from "../../hooks/use-api-request.js";
import { toast } from "react-toastify";
import Pagination from "../pagination.jsx";
import debounce from "../../common/debounce.js";
import { DataTableHeading } from "./data-table-heading.jsx";
import { DataTableBody } from "./data-table-body.jsx";

function generateFilledColumns(columns) {
  // we need to apply the width property to all the columns that do not have it,
  const columnsWithoutWidth = columns.filter((col) => !col.width);
  if (columnsWithoutWidth.length === 0) {
    return columns; // nothing to auto-generate
  }

  const explicitWidthSum = columns.reduce((prev, curr) => prev + (curr.width ?? 0), 0);
  if (explicitWidthSum >= 100) {
    // we can't specify an explicit width on the remaining items
    return columns;
  }

  const evenWidthPercentage = (100 - explicitWidthSum) / columnsWithoutWidth.length;
  return columns.map((col) => {
    if (col.width) {
      return col;
    }
    return {
      ...col,
      width: evenWidthPercentage,
    };
  });
}

/**
 * @param columns Array<{ label: string, key: string, value: string sortable: boolean, renderValue: Function}>
 * @param url string
 * @param withSearch boolean
 * @param pagination boolean
 * @param noDataComponent jsx component
 * @returns {JSX.Element}
 * @constructor
 */
export default forwardRef(function DataTable(
  {
    columns,
    url,
    withSearch = false,
    pagination = true,
    noDataComponent = null,
    expandedRowIndex,
    expandedRowRender,
    withHeadings = true,
    withBackground = true,
  },
  externalRef,
) {
  // state
  const [requestUrl, setRequestUrl] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filledColumns, setFilledColumns] = React.useState(generateFilledColumns(columns));
  const [sortOrder, setSortOrder] = React.useState([]);
  const debounceRef = React.useRef({ track: undefined });

  // hooks
  const { isLoading, response, error, paginationControls, refreshRequest } = useApiRequest(requestUrl);

  React.useImperativeHandle(externalRef, () => ({
    refreshList: () => refreshRequest(),
  }));

  // handle column changes
  React.useEffect(() => {
    setFilledColumns(generateFilledColumns(columns));
  }, [columns]);

  // handle error state
  React.useEffect(() => {
    if (error) {
      toast.error("Could not load data");
    }
  }, [error]);

  // make a search request
  React.useEffect(() => {
    debounce(
      () => {
        let components = [];
        if (searchTerm) {
          components.push([`search=${searchTerm}`]);
        }

        if (sortOrder.length) {
          components.push([`sort=${encodeURIComponent(JSON.stringify(sortOrder))}`]);
        }

        const urlQueryPart = url.includes("?") ? "&" : "?";

        let nextUrl = `${url}${urlQueryPart}${components.join("&")}`;
        setRequestUrl(nextUrl);
      },
      360,
      false,
      debounceRef.current,
    );
  }, [url, searchTerm, sortOrder]);

  const onColumnClick = React.useCallback((column) => {
    if (column.sortable) {
      const directionOrder = ["desc", "asc"];
      setSortOrder((prevState) => {
        const index = prevState.findIndex((sortedColumn) => sortedColumn.key === column.key);
        if (index === -1) {
          // insert entry
          return [...prevState, { key: column.key, direction: directionOrder[0] }];
        }

        if (prevState[index].direction === directionOrder[1]) {
          // remove entry
          return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
        }

        // set direction order to index 1
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            direction: directionOrder[1],
          },
          ...prevState.slice(index + 1),
        ];
      });
    }
  }, []);

  const actions = [];
  if (withSearch) {
    actions.push("search");
  }

  return (
    <div className={`data-table ${withBackground ? "" : "data-table--no-bg"}`}>
      {actions.length ? (
        <div className="data-table__actions">
          {withSearch ? (
            <div className="data-table__search">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <DataTableHeading
        columns={filledColumns}
        onColumnClick={onColumnClick}
        sortOrder={sortOrder}
        hasActions={actions.length}
        withHeadings={withHeadings}
      />
      <DataTableBody
        isLoading={isLoading}
        rows={response.data?.data}
        columns={filledColumns}
        noDataComponent={noDataComponent}
        expandedRowIndex={expandedRowIndex}
        expandedRowRender={expandedRowRender}
      ></DataTableBody>
      <div className="data-table__footer">
        {pagination ? (
          <Pagination
            prevUrl={response.data?.prev_page_url}
            nextUrl={response.data?.next_page_url}
            onPageClick={(event) => paginationControls.navigate(event.url)}
            onPageSizeClick={(value) => paginationControls.changePageSize(value)}
            pageSize={response.data?.data?.per_page || 30}
          />
        ) : null}
      </div>
    </div>
  );
});
