// @see https://www.hbbtv.org/wp-content/uploads/2015/07/HbbTV-SPEC15-00002-000-specification_with_errata_3_integrated_for_publication.pdf
export default function parseUserAgent(userAgent) {
  let make = "";
  let model = "";
  let brand = "";

  const hbbtvIndex = userAgent.indexOf("HbbTV/");
  if (hbbtvIndex !== -1) {
    const start = userAgent.indexOf("(", hbbtvIndex);
    if (start !== -1) {
      const end = userAgent.indexOf(")", start);
      if (end !== -1) {
        const infoPart = userAgent.substring(start + 1, end).split(";");

        if (infoPart.length > 1) {
          make = infoPart[1];
        }

        if (infoPart.length > 2) {
          model = infoPart[2];
        }

        // known brand/make handling
        switch (true) {
          case userAgent.includes("VIDAA"):
            make = "VIDAA";
            brand = "Hisense";
            break;
          case userAgent.includes("Amazon") && userAgent.includes("JVC"):
            brand = "JVC";
            break;
          case userAgent.includes("Hisense"):
            brand = "Hisense";
            break;
          default:
            brand = make;
            break;
        }
      }
    }
  }

  return { make, model, brand };
}
