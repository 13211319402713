import React from "react";
import ContentCard from "../library/components/content-card.jsx";
import { Link } from "react-router-dom";
import { addDays, format } from "date-fns";
import useApiRequest from "../../hooks/use-api-request.js";
import Loader from "../../common/loader.jsx";
import { getPlanFullness } from "../channel-list-page.jsx";

function getEpgClassValue(epg) {
  let value;

  switch (epg.epg_status) {
    case "Pending":
      value = "started";
      break;
    case "Manually Exported":
    case "Success":
      value = "complete";
      break;
    case "Not Exported":
      value = "unstarted";
      break;
    default:
      value = "error";
      break;
  }

  return value;
}

export default function OverviewTab({ guid }) {
  // unfortunately this was rushed, ideally this all gets back to us via the api
  const { isLoading: qcLoading, response: qcResponse } = useApiRequest(
    `/api/channels/quality-control?channel_id=${guid}`,
  );

  const { isLoading: plansLoading, response: plansResponse } = useApiRequest(
    `/api/channels/upcoming?channel_id=${guid}`,
  );

  const { isLoading: epgLoading, response: epgResponse } = useApiRequest(
    `/api/everyone-tv?channel_id=${guid}&days_ahead=20`,
  );

  const [stats, setStats] = React.useState([
    {
      label: "Critical Severity Problems",
      value: "-",
    },
    {
      label: "High Severity Problems",
      value: "-",
    },
    {
      label: "EPGs Published",
      value: "-",
    },
    {
      label: "Fully scheduled days",
      value: "-",
    },
  ]);

  React.useEffect(() => {
    if (plansResponse.data && qcResponse.data && epgResponse.data) {
      setStats([
        {
          label: "Critical Severity Problems",
          value: qcResponse.data.filter((item) => item.severity_code === 4).length,
        },
        {
          label: "High Severity Problems",
          value: qcResponse.data.filter((item) => item.severity_code === 3).length,
        },
        {
          label: "EPGs Published",
          value: epgResponse.data.filter((item) => ["Success", "Manually Imported"].includes(item.epg_status)).length,
        },
        {
          label: "Fully scheduled days",
          value: plansResponse.data.plans.filter((item) => ["PLANNED", "SCHEDULED"].includes(item.status)).length,
        },
      ]);
    }
  }, [plansResponse, qcResponse, epgResponse]);

  const colours = ["u-bgc--primary-lt", "u-bgc--secondary-lt", "u-bgc--tertiary-lt", "u-bgc--danger", "u-bgc--info"];
  const isLoading = qcLoading && plansLoading && epgLoading;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ContentCard>
      <div className="island--smaller">
        <h2>21 Day Overview</h2>
        <div className="gw">
          {stats.map((stat, index) => (
            <div className={`g g--1-of-${stats.length}`} key={stat.label}>
              <div className={`info-block info-block--with-min`}>
                <div className={`info-block__inner ${colours[index % 5]}`}>
                  <div className="info-block__label">{stat.label}</div>
                  <div className="info-block__value">{stat.value}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="gw">
        <div className="g g--1-of-2">
          <h2>Channel Status</h2>
          <div>
            {plansResponse.data?.plans.map((plan, idx) => {
              const status = getPlanFullness(plansResponse.data, idx);
              return (
                <Link
                  className={`forecast-dot forecast-dot--${status.value}`}
                  aria-label={`${format(addDays(new Date(), idx), "MMM do")} - ${status.label}`}
                  data-balloon-pos="up-left"
                  // to={`${channel.channel_id}/scheduler/${plan.plan_date}`}
                  to={``}
                  key={idx}
                ></Link>
              );
            })}
          </div>
        </div>
        <div className="g g--1-of-2">
          <h2>EPG Status</h2>
          <div>
            {epgResponse.data?.map((epg, idx) => {
              let label = epg.epg_status;

              return (
                <Link
                  className={`forecast-dot forecast-dot--${getEpgClassValue(epg)}`}
                  aria-label={`${format(new Date(epg.epg_date), "MMM do")} - ${label}`}
                  data-balloon-pos="up-left"
                  // to={`${channel.channel_id}/scheduler/${plan.plan_date}`}
                  to={``}
                  key={idx}
                ></Link>
              );
            })}
          </div>
        </div>
      </div>
    </ContentCard>
  );
}
