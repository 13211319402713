function getContentInheritanceChain(content) {
  let links = [content];
  let currentLink = 0;

  if (links[currentLink].episode_number && links[currentLink].season) {
    links.push(links[currentLink].season);
    currentLink++;
  }

  if (links[currentLink].season_number && links[currentLink].series) {
    links.push(links[currentLink].series);
    currentLink++;
  }

  return links;
}

function getContentMetadataValue(content, value) {
  switch (value) {
    case "short_summary":
      return content.summary?.short_summary;
    case "long_summary":
      return content.summary?.long_summary;
    case "imdb_genre":
      return content.genre?.imdb?.imdb_genre?.label;
    case "imdb_subgenre":
      return content.genre?.imdb?.imdb_subgenre?.label;
    case "freeview_genre":
      return content.genre?.freeview?.freeview_genre?.label;
    case "bbfc_rating":
      return content.rating?.bbfc_rating;
    case "mpaa_rating":
      return content.rating?.mpaa_rating_rating;
    case "us_tv_rating":
      return content.rating?.us_tv_rating;
  }
}

export function isInheritedValue(content, key) {
  if (!content || !key) return false;

  const contentLinks = getContentInheritanceChain(content);

  if (content.episode_number) {
    return (
      !getContentMetadataValue(contentLinks[0], key) &&
      (getContentMetadataValue(contentLinks[1], key) || getContentMetadataValue(contentLinks[2], key))
    );
  } else if (content.season_number) {
    return !getContentMetadataValue(contentLinks[0], key) && getContentMetadataValue(contentLinks[1], key);
  }

  return false;
}

export function getLabelWithInheritance(content, key) {
  if (!content || !key) return "";

  const contentLinks = getContentInheritanceChain(content);

  if (content.episode_number) {
    return (
      getContentMetadataValue(contentLinks[0], key) ||
      getContentMetadataValue(contentLinks[1], key) ||
      getContentMetadataValue(contentLinks[2], key) ||
      "N/A"
    );
  } else if (content.season_number) {
    return getContentMetadataValue(contentLinks[0], key) || getContentMetadataValue(contentLinks[1], key) || "N/A";
  }

  return "";
}
