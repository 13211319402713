import React from "react";
import { getTypeForContent } from "../utils/accessors.js";
import Loader from "../../../common/loader.jsx";
import { useSchedulerContext } from "../../../providers/scheduler-context.jsx";
import ProgramInformation from "./program-information/program-information.jsx";

function MissingProgram() {
  return (
    <div className="epg-program-overview">
      <div className="missing-content">
        <p className="missing-content__text">Select a program to see more information</p>
      </div>
    </div>
  );
}

function ContentLoader({
  type,
  // guid,
  onPlay,
  program,
  programEpg,
  isLoading,
  onRemoveProgram,
  saveAdBreaks,
  selectProgram,
  transcodingTypes = [],
  addCuepoint,
}) {
  const { getRenderTime } = useSchedulerContext();
  return (
    <div className="epg-program-overview__inner">
      {isLoading ? (
        <Loader />
      ) : (
        <ProgramInformation
          type={type}
          program={program}
          onPlay={onPlay}
          programInfo={programEpg}
          onRemoveProgram={onRemoveProgram}
          saveAdBreaks={saveAdBreaks}
          viewContentListProgram={selectProgram}
          transcodingTypes={transcodingTypes}
          formatTimeForTimezone={getRenderTime}
          addCuepoint={addCuepoint}
        />
      )}
    </div>
  );
}

export default function EpgProgramOverview({
  program,
  programEpg,
  isLoading,
  onPlay,
  onRemoveProgram,
  saveAdBreaks,
  viewContentListProgram,
  transcodingTypes,
  addCuepoint,
  removeNearestCuepoint,
  trimVideo,
}) {
  let type = null;
  let guid = null;
  if (program && program?.__gstvMeta) {
    // this is an epg program
    type = program.__gstvMeta.link_type;
    guid = program.__gstvMeta.link_guid;
  } else if (program) {
    // this is a content program
    type = getTypeForContent(program);
    guid = program[`${type}_guid`];
  }

  if (!isLoading && (!program || !type || !guid)) {
    return <MissingProgram />;
  }

  return (
    <div className="epg-program-overview">
      <ContentLoader
        type={type}
        guid={guid}
        key={guid}
        onPlay={onPlay}
        program={program}
        programEpg={programEpg}
        isLoading={isLoading}
        onRemoveProgram={onRemoveProgram}
        saveAdBreaks={saveAdBreaks}
        selectProgram={viewContentListProgram}
        transcodingTypes={transcodingTypes}
        addCuepoint={addCuepoint}
        removeNearestCuepoint={removeNearestCuepoint}
        trimVideo={trimVideo}
      />
    </div>
  );
}
