import React from "react";
import dateIsBetween from "../../../common/date-is-between.js";
import { isAfter, subSeconds } from "date-fns";

export default function useSegments(planDate, planBreaks, epg) {
  const [segments, setSegments] = React.useState([]);

  React.useEffect(() => {
    const nextSegments = planBreaks.map((pb) => {
      // times of pb is always in UTC, force planDate time to UTC so we can always use time comparison to find plan breaks
      // this helps maintain the direction of only showing timezone-based data visually but working with UTC
      pb.items = epg
        .map((item, index) => ({
          ...item,
          hasPreviousPromo: item.Type === "promo" && index > 0 && epg[index - 1].Type === "promo", // for pill offset logic
        }))
        .filter((item) => {
          return dateIsBetween(item.since, pb.start, pb.end, "[)");
        });

      return pb;
    });

    // attach any out of range items to the last segment
    if (nextSegments.length) {
      const lastSegment = nextSegments[nextSegments.length - 1];
      const outOfRange = epg.filter((item) => {
        return isAfter(item.since, subSeconds(lastSegment.end, 1));
      });

      if (outOfRange.length) {
        nextSegments[nextSegments.length - 1].items.push(...outOfRange);
      }
    }

    setSegments(nextSegments);
  }, [planDate, planBreaks, epg]);

  return segments;
}
