import React from "react";
import Plyr from "@robguy21/plyr-react";
import axios from "../../requests/axios.js";
import { PlayerControls } from "./player-controls.jsx";
import PlayerLoader from "./player-loader.jsx";
import usePlyrId from "./use-plyr-id.js";
import Hls from "hls.js";
import dashjs from "dashjs";
import { renderToString } from "react-dom/server";
import useSelectSourceListeners from "./hooks/use-select-source-listeners.js";
import usePlayerControlListeners from "./hooks/use-player-control-listeners.js";
import HbbtvPlayer from "../hbbtv-player/hbbtv-player.jsx";

const defaultOptions = {
  i18n: {
    restart: "Restart",
    rewind: "Rewind {seektime}s",
    play: "Play",
    pause: "Pause",
    fastForward: "Forward {seektime}s",
    seek: "Seek",
    seekLabel: "{currentTime} of {duration}",
    played: "Played",
    buffered: "Buffered",
    currentTime: "Current time",
    duration: "Duration",
    volume: "Volume",
    mute: "Mute",
    unmute: "Unmute",
    enableCaptions: "Enable captions",
    disableCaptions: "Disable captions",
    download: "Download",
    enterFullscreen: "Enter fullscreen",
    exitFullscreen: "Exit fullscreen",
    frameTitle: "Player for {title}",
    captions: "Captions",
    settings: "Settings",
    menuBack: "Go back to previous menu",
    speed: "Speed",
    normal: "Normal",
    quality: "Quality",
    loop: "Loop",
  },
  controls: {
    changeSource: false,
    selectSource: false,
    editCuepoints: false,
    playoutTrimming: false,
    timers: false,
  },
};

const sourceTypes = ["source", "normalised", "hls", "dash"];

async function asyncSetTimeout(fn, wait) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(fn());
    }, wait);
  });
}

async function timer(test, wait, attempts = 1) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    let result = false;
    for (let i = 1; i <= attempts; i++) {
      result = await asyncSetTimeout(test, wait);
      if (result) {
        break;
      }
    }

    resolve(result);
  });
}

let LAST_KNOWN_TIME = { src: "", time: 0 };

export function Player({
  cuepoints = null,
  source,
  trimPoint = null,
  isHls = false,
  isDash = false,
  id = null,
  addCuepoint = null,
  removeNearestCuepoint = null,
  trimVideo = null,
  title = null,
  changeSource = null,
  controls = {
    selectSource: false,
    changeSource: false,
    editCuepoints: false,
    playoutTrimming: false,
    timers: false,
  },
  availableSources = {
    source: false,
    normalised: false,
    hls: false,
    dash: false,
  },
  switchSource = () => {},
  activeSource = "normalised",
}) {
  const [isLoading, setLoading] = React.useState(false);
  const [playerDims, setPlayerDims] = React.useState({ height: "160px", width: "90px" });

  const playerRef = React.useRef();
  const containerRef = React.useRef();

  const playerId = usePlyrId(playerRef);

  const optionsMemo = React.useMemo(() => {
    return {
      ...defaultOptions,
      controls: renderToString(<PlayerControls {...defaultOptions.controls} {...controls} />),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls.selectSource, controls.changeSource, controls.playoutTrimming, controls.timers, controls.editCuepoints]);

  function toggleSourceDropdown() {
    if (!playerRef.current?.plyr?.id) {
      console.error("Could not select video source because no valid plyr element found");
      return;
    }

    const selectSourceDropdown = document.getElementById("select-source-dropdown");

    if (selectSourceDropdown) {
      selectSourceDropdown.classList.toggle("plyr__select-source-dropdown--visible");
    }
  }

  function addSelectionStyling(sourceType) {
    const newActiveSource = document.querySelector(`[data-plyr='select-source-dropdown__${sourceType}']`);
    if (newActiveSource && !newActiveSource.classList.contains("plyr__select-source-dropdown__item--selected")) {
      newActiveSource.classList.add("plyr__select-source-dropdown__item--selected");
    }
  }

  function clearSelectionStyling() {
    sourceTypes.forEach((sourceType) => {
      const dropdownElement = document.querySelector(`[data-plyr='select-source-dropdown__${sourceType}']`);
      if (dropdownElement.classList.contains("plyr__select-source-dropdown__item--selected")) {
        dropdownElement.classList.remove("plyr__select-source-dropdown__item--selected");
      }
    });
  }

  const onSourceSelected = React.useCallback(
    (source) => {
      toggleSourceDropdown();
      switchSource(source);
      clearSelectionStyling();
      addSelectionStyling(source);
    },
    [switchSource],
  );

  const selectSource = onSourceSelected.bind(null, "source");
  const selectNormalised = onSourceSelected.bind(null, "normalised");
  const selectHls = onSourceSelected.bind(null, "hls");
  const selectDash = onSourceSelected.bind(null, "dash");

  function onAddCuepoint() {
    if (!playerRef.current?.plyr?.id) {
      console.error("Could not add cuepoint to video because no valid plyr element found");
      return;
    }

    const time = Math.round(playerRef.current.plyr.currentTime);
    if (addCuepoint) {
      addCuepoint(time);
    }
  }

  function onRemoveCuepoint() {
    if (!playerRef.current?.plyr?.id) {
      console.error("Could not remove cuepoint from video because no valid plyr element found");
      return;
    }

    const time = Math.round(playerRef.current.plyr.currentTime);
    if (removeNearestCuepoint) {
      removeNearestCuepoint(time);
    }
  }

  function onTrimVideo() {
    if (!playerRef.current?.plyr?.id) {
      console.error("Could not trim video because no valid plyr element found");
      return;
    }

    const time = Math.round(playerRef.current.plyr.currentTime);
    if (trimVideo) {
      trimVideo(time);
    }
  }

  function onChangeSource() {
    if (!playerRef.current?.plyr?.id) {
      console.error("Could not change source video because no valid plyr element found");
      return;
    }

    if (changeSource) {
      changeSource();
    }
  }

  const updateLoader = React.useCallback((playerId, event) => {
    if (event.type === "loadstart") {
      setLoading(true);
    } else if (event.type === "canplay" && playerId === event.detail.plyr.id) {
      setLoading(false);
    }
  }, []);

  const onLoaded = React.useCallback(() => {
    if (cuepoints || trimPoint) {
      const markers = [
        ...(cuepoints && cuepoints.length ? cuepoints : []),
        ...(trimPoint
          ? [
              {
                time: trimPoint,
                label: "Trimmed",
                type: "trim-point",
              },
            ]
          : []),
      ];
      if (playerRef.current) {
        playerRef.current.plyr.updateMarkers(markers);

        if (source !== LAST_KNOWN_TIME.src) {
          LAST_KNOWN_TIME.src = source;
          LAST_KNOWN_TIME.time = 0;
        }

        playerRef.current.plyr.currentTime = LAST_KNOWN_TIME.time;
      }
    }
  }, [cuepoints, trimPoint, source]);

  async function plyrReady() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      try {
        const result = await timer(
          () => playerRef.current?.plyr && playerRef.current.plyr.hasOwnProperty("updateMarkers"),
          60,
        );
        if (result) {
          res(result);
        } else {
          console.error("failed");
          rej(result);
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  usePlayerControlListeners({
    playerId,
    onAddCuepoint,
    onRemoveCuepoint,
    onTrimVideo,
    onChangeSource,
    toggleSourceDropdown,
  });

  useSelectSourceListeners({
    playerId,
    onSelectSource: selectSource,
    onSelectNormalised: selectNormalised,
    onSelectHls: selectHls,
    onSelectDash: selectDash,
    availableSources,
  });

  //  load video once UI is ready
  React.useLayoutEffect(() => {
    async function plyrReady() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (res, rej) => {
        const result = await timer(() => playerRef.current?.plyr.hasOwnProperty("updateMarkers"), 60);
        if (result) {
          res(result);
        } else {
          rej(result);
        }
      });
    }

    plyrReady()
      .then(() => {
        const loadVideo = async () => {
          const video = document.getElementById(id);
          const hls = new Hls();
          hls.loadSource(source);
          hls.attachMedia(video);
          playerRef.current.plyr.media = video;

          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            playerRef.current.plyr.play();
            playerRef.current.plyr.muted = true;
          });
        };

        const loadDashVideo = async () => {
          const dash = dashjs.MediaPlayer().create();
          const video = document.getElementById(id);

          dash.initialize(video, source, true);
        };

        if (playerRef.current?.plyr) {
          // enforce options
          playerRef.current.plyr.muted = true;
          playerRef.current.plyr.autoplay = true;

          // update source
          if (isHls) {
            loadVideo().then().catch();
          } else if (isDash) {
            loadDashVideo().then().catch();
          } else if (source) {
            playerRef.current.plyr.source = {
              type: "video",
              title: "",
              sources: [
                {
                  src: source,
                  type: "video/mp4",
                  size: 720,
                },
              ],
            };
          }
        }
      })
      .catch(console.error);
  }, [source, isHls, isDash, id]);

  // add player resize observer
  React.useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (containerRef.current?.parentElement) {
        let parentElement = containerRef.current.parentElement;
        let videoWidth = parentElement.getBoundingClientRect().width;
        let videoHeight = parentElement.getBoundingClientRect().width / (16 / 9);

        if (videoHeight > parentElement.getBoundingClientRect().height) {
          videoHeight = parentElement.getBoundingClientRect().height;
          videoWidth = parentElement.getBoundingClientRect().height * (16 / 9);
        }

        setPlayerDims({ height: `${videoHeight}px`, width: `${videoWidth}px` });
      }
    });

    plyrReady()
      .then(() => {
        if (containerRef.current?.parentElement) {
          observer.observe(containerRef.current.parentElement);
        } else {
          // hack to make sure we avoid timing issues
          setTimeout(() => {
            if (containerRef.current?.parentElement) {
              observer.observe(containerRef.current.parentElement);
            }
          }, 300);
        }
      })
      .catch(console.error);
    return () => observer.disconnect();
  }, []);

  //  marker updates and player events
  React.useLayoutEffect(() => {
    const ref = playerRef.current;
    const updatePlayerLoader = updateLoader.bind(null, playerId);

    plyrReady()
      .then(() => {
        if (playerId && ref?.plyr?.elements) {
          ref.plyr.on("loadeddata", onLoaded);
          ref.plyr.on("error", handleError);
          ref.plyr.on("loadstart", updatePlayerLoader);
          ref.plyr.on("canplay", updatePlayerLoader);

          onLoaded();
          addSelectionStyling(activeSource);
        }
      })
      .catch((e) => {
        console.error("could not apply marker updates", e);
      });

    // setup action listeners for edit buttons if this is not an hls stream
    return () => {
      if (playerId && ref?.plyr?.elements) {
        ref.plyr.off("loadeddata", onLoaded);
        ref.plyr.off("error", handleError);
        ref.plyr.off("loadstart", updatePlayerLoader);
        ref.plyr.off("canplay", updatePlayerLoader);

        LAST_KNOWN_TIME.time = ref.plyr.currentTime;
      }
    };
  }, [activeSource, updateLoader, onLoaded, playerId]);

  function handleError(event) {
    console.error(event);
  }

  return (
    <div
      className={`plyr-container ${title ? "plyr-container--with-title" : ""}`}
      ref={containerRef}
      style={playerDims}
    >
      {title ? <h4 className="plyr-title">{title.toUpperCase()}</h4> : null}
      <Plyr
        ref={playerRef}
        className={`plyr-react plyr ${trimPoint ? "plyr--is-trimmed" : ""}`}
        source={null}
        id={id}
        autoPlay={true}
        muted={true}
        markers={{ enabled: true, points: [] }}
        options={optionsMemo}
      ></Plyr>
      <PlayerLoader isLoading={isLoading} />
      {!source ? <span className="plyr-title">Video not found</span> : null}
    </div>
  );
}

export function AssetPlayer({ assetId, ...props }) {
  const [streamUrl, setStreamUrl] = React.useState();
  React.useEffect(() => {
    if (assetId) {
      axios
        .get(`/api/assets/${assetId}`)
        .then((r) => {
          setStreamUrl(r.data.source_url);
        })
        .catch((e) => new Error(e));
    }
  }, [assetId]);

  return <Player {...props} source={streamUrl} />;
}

export function HlsPlayer({ source, ...props }) {
  return <Player {...props} source={source} isHls={true} id={props.id ?? "hls-stream"} />;
}

export function DashPlayer({ source, ...props }) {
  return <Player {...props} source={source} isDash={true} id={props.id ?? "dash-stream"} />;
}

export function HlsOrDashPlayer({ type, source, destination, channelName = "", ...props }) {
  if (type === "hls") {
    return <HlsPlayer {...props} source={source} />;
  } else if (type === "dash" && destination === "hbbtv") {
    return <HbbtvPlayer channelName={channelName} />;
  } else if (type === "dash") {
    return <DashPlayer {...props} source={source} />;
  }
}

export default Player;
