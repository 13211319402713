import React from "react";
import { useParams } from "react-router-dom";
import useApiRequest from "../hooks/use-api-request.js";
import { AppHeading } from "../layout/parts/app-heading.jsx";
import AppBody from "../layout/parts/app-body.jsx";
import { HlsOrDashPlayer } from "../components/player/player.jsx";
import ChannelPlaybackDropdown from "./epg-editor/components/channel-playback-dropdown.jsx";
import ContentCard from "./library/components/content-card.jsx";
import TabbedContent from "../components/tabbed-content.jsx";
import OverviewTab from "./channel-dashboard/overview-tab.jsx";
import SchedulingQualityControlTab from "./channel-dashboard/scheduling-quality-control-tab.jsx";
import EpgDeliveryTab from "./channel-dashboard/epg-delivery-tab.jsx";
import ChannelDetailsTab from "./channel-dashboard/channel-details-tab.jsx";
import PromosTab from "./library/tabs/promos-tab.jsx";
import { CalendarMonthRounded, EditCalendarRounded, FolderCopyRounded } from "@mui/icons-material";

export default function ChannelDashboardPage() {
  const { channelGuid } = useParams();
  const { isLoading, response: channelResponse } = useApiRequest(`/api/channels/${channelGuid}`);
  const [cardHeight, setCardHeight] = React.useState();
  const [activeSource, setActiveSource] = React.useState({});
  const videoHeightRef = React.useRef();

  React.useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (videoHeightRef.current) {
        setCardHeight(videoHeightRef.current.clientHeight);
      }
    });

    if (videoHeightRef.current) {
      observer.observe(videoHeightRef.current);
    } else {
      // hack to make sure we avoid timing issues
      setTimeout(() => {
        if (videoHeightRef.current) {
          observer.observe(videoHeightRef.current);
        }
      }, 300);
    }

    return () => observer.disconnect();
  }, []);

  React.useEffect(() => {
    if (channelResponse?.data) {
      setActiveSource({
        url: channelResponse.data.playouts[0]?.playback_url ?? "",
        type: channelResponse.data.playouts[0]?.destination_output ?? "",
        destination: channelResponse.data.playouts[0]?.destination ?? "",
      });
    }
  }, [channelResponse]);

  const playerOptions = React.useMemo(
    () => ({
      source: activeSource.url,
      type: activeSource.type,
      destination: activeSource.destination,
      controls: {
        selectSource: false,
        changeSource: false,
        editCuepoints: false,
        playoutTrimming: false,
        timers: false,
      },
    }),
    [activeSource.url, activeSource.type, activeSource.destination],
  );

  const channel = channelResponse?.data;
  return (
    <React.Fragment>
      <AppHeading
        breadcrumbs={[
          {
            link: "/channels",
            title: "Channels",
          },
          {
            title: channel?.display_name ?? "...",
            link: "",
          },
        ]}
        rightActions={[
          {
            type: "link",
            href: "templates",
            icon: FolderCopyRounded,
            balloonLabel: "Templates",
          },
          {
            type: "link",
            href: "calendar",
            icon: EditCalendarRounded,
            balloonLabel: "Template Calendar",
          },
          {
            type: "link",
            href: "scheduler",
            icon: CalendarMonthRounded,
            balloonLabel: "Scheduler",
          },
        ]}
      />
      <AppBody loading={isLoading}>
        {!isLoading && (
          <React.Fragment>
            <div className="island--small">
              <div className="gw">
                <div className="g g--1-of-1">
                  <ContentCard maxHeight={cardHeight} noPadding>
                    <div className="overlay-container">
                      <div className="video-player" ref={videoHeightRef}>
                        <HlsOrDashPlayer {...playerOptions} channelName={channel?.name} />
                      </div>
                      <div className="overlay__element overlay__element--top-right">
                        <ChannelPlaybackDropdown
                          channel={channel}
                          activePlayback={activeSource}
                          onChange={setActiveSource}
                        />
                      </div>
                      <div className="overlay__element overlay__element--bottom-left">
                        <div className={"image image--medium"}>
                          <img src={channelResponse.data.art.overlay_image} />
                        </div>
                      </div>
                    </div>
                  </ContentCard>
                </div>
              </div>
            </div>
            <div className="gw">
              <div className="g g--1-of-1">
                <TabbedContent
                  minHeight={"500px"}
                  tabs={[
                    {
                      label: "Overview",
                      name: "current",
                      component: OverviewTab,
                      props: {
                        guid: channelGuid,
                      },
                    },
                    {
                      label: "Scheduling QC",
                      name: "scheduling",
                      component: SchedulingQualityControlTab,
                      props: {
                        guid: channelGuid,
                      },
                    },
                    {
                      label: "EPG Delivery",
                      name: "epg",
                      component: EpgDeliveryTab,
                      props: {
                        guid: channelGuid,
                      },
                    },
                    {
                      label: "Channel Details",
                      name: "details",
                      component: ChannelDetailsTab,
                      props: {
                        guid: channelGuid,
                      },
                    },
                    {
                      label: "Promos",
                      name: "promos",
                      component: PromosTab,
                      props: {
                        type: "channel",
                        guid: channelGuid,
                        groups: [
                          {
                            label: "Channel",
                            url: `/api/channels/${channelGuid}/promos`,
                            contentGuid: channelGuid,
                            type: "channels",
                          },
                        ],
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </AppBody>
    </React.Fragment>
  );
}

/*
1. Largest Player
2. Logo Overlay (bot-left)
3. Tabs (Overview | Quality Control | EPG Delivery | Information)
 */
