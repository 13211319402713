import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useSchedulerContext } from "../../../providers/scheduler-context";
import { differenceInSeconds, format } from "date-fns";
import { toast } from "react-toastify";
import composeRefs from "@seznam/compose-react-refs";
import { formatSeconds } from "../utils/format-seconds";
import ReactInputMask from "react-input-mask";
import { getSecondsFromHis } from "../utils/create-ad-breaks";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import getClassList from "../../../functions/get-class-list.js";
import StraightIcon from "@mui/icons-material/Straight";
import { isPast } from "date-fns";

function DraggableTimelineProgram({
  program,
  segment,
  updateDuration,
  onProgramClick,
  removeProgram,
  pixelsPerSecond,
  index,
  onProgramDrag,
  isZoomed,
  timelineContentRef,
}) {
  const [isResizing, setIsResizing] = React.useState(false);
  const [showResizeInput, setResizeInput] = React.useState(false);
  const [programResizedDuration, setProgramResizedDuration] = React.useState(
    formatSeconds(program.__gstvMeta.total_duration_seconds),
  );

  const resizingTill = React.useRef();
  const programRef = React.useRef();
  const resizeInputRef = React.useRef();

  const { getRenderTime } = useSchedulerContext();

  function cancelResize() {
    setResizeInput(false);
    setIsResizing(false);
  }

  const getWidthFromSeconds = React.useCallback(
    (seconds) => {
      // trying to avoid weirdness due to fractional pixels, e.g. pushing visible programs to the hidden row after calculation
      return Math.floor(pixelsPerSecond * seconds);
    },
    [pixelsPerSecond],
  );

  const getWidthStyleFromSeconds = React.useCallback(
    (seconds) => {
      return `${getWidthFromSeconds(seconds)}px`;
    },
    [getWidthFromSeconds],
  );

  const getSecondsFromWidth = React.useCallback(
    (widthPix) => {
      // pps * s = width
      // width / pps = s
      return widthPix / pixelsPerSecond;
    },
    [pixelsPerSecond],
  );

  const commitResize = React.useCallback(
    (commitValue = null) => {
      const nextDuration = commitValue !== null ? commitValue : getSecondsFromHis(programResizedDuration);
      updateDuration(program.id, nextDuration);
    },
    [program.id, updateDuration, programResizedDuration],
  );

  const onResizeInputKeyPress = React.useCallback(
    (e) => {
      if (e.key === "Enter") {
        commitResize(getSecondsFromHis(resizeInputRef.current.value));

        resizeInputRef.current.removeEventListener("keypress", onResizeInputKeyPress);
      }

      if (e.key === "Enter" || e.key === "Escape") {
        setResizeInput(false);
        setIsResizing(false);
      }
    },
    [commitResize],
  );

  const onResizeClick = React.useCallback(
    (e) => {
      if (isPast(program.since)) {
        toast.error("Cannot change programs in the past.");
        return;
      }
      e.preventDefault();

      setResizeInput(true);
      setIsResizing(true);

      resizeInputRef.current.focus();
      resizeInputRef.current.addEventListener("keydown", onResizeInputKeyPress);
      window.addEventListener("mouseup", cancelResize);
    },
    [program.since, onResizeInputKeyPress],
  );

  // ensure programResizedDuration is always up to date
  React.useEffect(() => {
    setProgramResizedDuration(formatSeconds(program.__gstvMeta.total_duration_seconds));
  }, [program.__gstvMeta.total_duration_seconds]);

  function programExceedsSegmentLength(segmentEnd, programEnd) {
    return programEnd > segmentEnd;
  }

  function formatProgramText(programDate) {
    return getRenderTime(programDate, "HH:mm:ss");
  }

  function updateProgramResizedDuration(duration) {
    // resize duration cannot be longer than program, and cannot be less han 0
    if (
      getSecondsFromHis(duration) > program.__gstvMeta.original_total_duration_seconds ||
      getSecondsFromHis(duration) < 0
    ) {
      setProgramResizedDuration(formatSeconds(program.__gstvMeta.original_total_duration_seconds));
      return;
    }

    setProgramResizedDuration(duration);
  }

  function resizeHandler(program, mouseDownEvent) {
    if (isPast(program.since)) {
      toast.error("Cannot change programs in the past");
      return;
    }
    mouseDownEvent.preventDefault();
    mouseDownEvent.stopPropagation();
    setIsResizing(true);

    if (timelineContentRef.current) {
      timelineContentRef.current.classList.add("is-resizing");
    }

    let maxWidth = getWidthFromSeconds(program.__gstvMeta.original_total_duration_seconds);

    // if the start is trimmed, we know we need to use a reduced maxWidth
    if (program.originalSince) {
      // the original total width minus the width that is outside the range
      maxWidth = getWidthFromSeconds(
        program.__gstvMeta.original_total_duration_seconds - differenceInSeconds(program.since, program.originalSince),
      );
    }

    function onMouseMove(mouseMoveEvent) {
      const currentX = mouseMoveEvent.pageX;
      let nextWidth = currentX - programRef.current.getBoundingClientRect().left;

      //  check if width exceeds maxWidth
      if (currentX >= programRef.current.getBoundingClientRect().left + maxWidth) {
        nextWidth = maxWidth;
      }

      window.requestAnimationFrame(() => {
        if (resizingTill.current) {
          resizingTill.current.innerHTML = getRenderTime(
            new Date(program.since.getTime() + getSecondsFromWidth(nextWidth) * 1000),
            "HH:mm:ss",
          );
        }
        programRef.current.style.width = `${nextWidth}px`;
      });
    }

    function onMouseUp() {
      if (!isZoomed) {
        // When not zoomed, simply use the width to calculate time
        updateDuration(program.id, getSecondsFromWidth(programRef.current.getBoundingClientRect().width));
      } else {
        // try using width in combination with out-of-range seconds
        let nonWidthSeconds = 0;
        if (program.originalSince) {
          nonWidthSeconds += differenceInSeconds(program.since, program.originalSince);
        }
        if (program.originalTill) {
          nonWidthSeconds += differenceInSeconds(program.originalTill, program.till);
        }
        updateDuration(
          program.id,
          getSecondsFromWidth(programRef.current.getBoundingClientRect().width) + nonWidthSeconds,
        );
      }

      setIsResizing(false);
      if (timelineContentRef.current) {
        timelineContentRef.current.classList.remove("is-resizing");
      }
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("mouseup", onMouseUp);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  }

  const isPastState = isPast(program.since);

  const programClasses = getClassList({
    "scheduler-timeline__program": true,
    "scheduler-timeline__program--promo": program.__gstvMeta.link_type === "promo",
    "scheduler-timeline__program--is-past": isPastState,
  });

  function down(e) {
    onProgramDrag(e);
  }

  return (
    <React.Fragment>
      <Draggable
        draggableId={`h-timeline-${program.id}`}
        index={index}
        isDragDisabled={isPast(program.since) || isResizing}
      >
        {(provided) => {
          return (
            <div
              ref={composeRefs(programRef, provided.innerRef)}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={programClasses}
              style={{
                width: getWidthStyleFromSeconds(differenceInSeconds(program.till, program.since)),
                ...provided.draggableProps.style,
              }}
              onClick={onProgramClick.bind(null, program)}
              onMouseDown={down}
              key={index}
            >
              <div
                className={`scheduler-timeline__program__inner${
                  programExceedsSegmentLength(segment.end, program.till)
                    ? " scheduler-timeline__segment-length-exceeded"
                    : ""
                } `}
              >
                <p className="scheduler-timeline__program__title">{program.title}</p>
                <span
                  className={`scheduler-timeline__program__text${isResizing ? " scheduler-timeline__program__text--resizing" : ""}`}
                >
                  {formatProgramText(program.originalSince ?? program.since)} -{" "}
                  {isResizing ? (
                    <span style={{ fontWeight: "bold" }} ref={resizingTill}>
                      {formatProgramText(program.originalTill ?? program.till)}
                    </span>
                  ) : (
                    <span>{formatProgramText(program.originalTill ?? program.till)}</span>
                  )}
                </span>
                <div className="scheduler-timeline__program__actions">
                  {program.Type !== "promo" && !program.originalTill ? (
                    <React.Fragment>
                      <div
                        className={`scheduler-timeline__program__resize-input ${
                          showResizeInput ? "scheduler-timeline__program__resize-input--active" : ""
                        }`}
                      >
                        <ReactInputMask
                          mask={"99:99:99"}
                          value={programResizedDuration}
                          onChange={(e) => {
                            updateProgramResizedDuration(e.target.value);
                          }}
                          ref={resizeInputRef}
                        />
                      </div>

                      {!isPastState && (
                        <button
                          className="btn--resize-h"
                          onMouseDown={resizeHandler.bind(null, program)}
                          onClick={(e) => onResizeClick(e)}
                        >
                          <StraightIcon />
                        </button>
                      )}
                    </React.Fragment>
                  ) : null}
                  {!isPastState && (
                    <button
                      className="btn--remove-program"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeProgram(program);
                      }}
                    >
                      <CancelRoundedIcon />
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Draggable>
    </React.Fragment>
  );
}

export default DraggableTimelineProgram;
