import { addSeconds, differenceInSeconds, isAfter, isBefore } from "date-fns";
import dateIsBetween from "../../../common/date-is-between.js";
import { v4 } from "uuid";
import { trimProgramDurationToEndTime } from "./trim-program-duration.js";

/**
 *
 * @param activeDate Date
 * @param segments [{ start: Date, end: Date }]
 * @returns {{}}
 * @constructor
 */
export default function EpgDndHelper(channelId, activeDate, segments) {
  function getSegmentWithinTime(time) {
    let segment = segments.find((s) => dateIsBetween(time, s.start, s.end, "[)"));

    if (!segment) {
      if (isAfter(time, segments[segments.length - 1].end)) {
        segment = segments[segments.length - 1];
      } else if ((isBefore, segments[0].start)) {
        segment = segments[0];
      }
    }

    return segment;
  }

  function getValidSinceTime(currentProgram, previousProgram = null, earliestAllowedDate = null) {
    const marker1 = earliestAllowedDate ? earliestAllowedDate : getSegmentWithinTime(currentProgram.since).start;
    const marker2 = previousProgram ? previousProgram.till : new Date(currentProgram.since);
    return isBefore(marker2, marker1) ? marker1 : marker2;
  }

  function updateTimes(list, changedIndex, changedFromIndex, earliest = null, latest = null, log = false) {
    return list.map((program, index) => {
      if (index < Math.min(changedIndex, changedFromIndex)) {
        return program; // no changes to programs which exist before the changed index
      }

      const duration = program.__gstvMeta.total_duration_seconds;

      if (index === 0) {
        const useEarliest = earliest ? earliest : getValidSinceTime(program, null, null);
        program.since = useEarliest; // useEarliest ? earliest : getValidSinceTime(program, null, useEarliest);
        program.till = addSeconds(program.since, duration);
      } else {
        const earliestAllowedDateForProgram = index === changedIndex ? earliest : null;
        program.since = getValidSinceTime(program, list[index - 1], earliestAllowedDateForProgram);
        program.till = addSeconds(program.since, duration);
      }

      // only trim content for current
      if (latest && isAfter(program.till, latest) && changedIndex === index) {
        program.till = latest;
        program = trimProgramDurationToEndTime(program, latest);
        if (log) {
          console.log({
            since: program.since,
            till: program.till,
            y: differenceInSeconds(latest, program.since),
            program,
            duration: program.__gstvMeta.duration_seconds,
          });
        }
      }
      return program;
    });
  }

  return {
    insert(list, program, destinationIndex, earliestTime, latestTime) {
      // for the sake of calculations set till to start + duration
      program.since = new Date(activeDate);
      program.till = addSeconds(new Date(activeDate), program.__gstvMeta.total_duration_seconds);
      program.channelUuid = channelId;

      // insert a new element
      let result = Array.from(list);
      result.splice(destinationIndex, 0, program);

      // calculate times for list
      return updateTimes(result, destinationIndex, destinationIndex, earliestTime, latestTime, false);
    },
    overwriteSegmentWith(list, epgPrograms, destinationIndex, earliestTime, deleteCount) {
      // for the sake of calculations set till to start + duration
      // do this for each item in epgPrograms
      const epgPrograms2 = epgPrograms.map((program) => {
        program.id = v4();
        program.since = new Date(activeDate);
        program.till = addSeconds(new Date(activeDate), program.__gstvMeta.total_duration_seconds);
        return program;
      });

      // insert new element
      let result = Array.from(list);

      // clear all items from target segment
      result.splice(destinationIndex, deleteCount, ...epgPrograms2);

      // calculate times for list
      return updateTimes(result, destinationIndex, destinationIndex, earliestTime);
    },
    overwriteTemplateSegmentWith(list, templateItems, destinationIndex, earliestTime, deleteCount) {
      // for the sake of calculations set till to start + duration
      // do this for each item in templateItems
      const templateItems2 = templateItems.map((item) => {
        delete item.template_day_item_id;
        item.drag_id = v4();
        item.since = new Date(activeDate);
        item.till = addSeconds(new Date(activeDate), item.__gstvMeta.total_duration_seconds);
        return item;
      });

      // insert new element
      let result = Array.from(list);

      // clear all items from target segment
      result.splice(destinationIndex, deleteCount, ...templateItems2);

      // calculate times for list
      return updateTimes(result, destinationIndex, destinationIndex, earliestTime);
    },
    move(list, fromIndex, toIndex, earliestTime = null, latestTime = null) {
      let result = Array.from(list);
      const [element] = result.splice(fromIndex, 1);
      result.splice(toIndex, 0, element);
      return updateTimes(result, toIndex, fromIndex, earliestTime, latestTime);
    },
    remove(list, program) {
      const id = program.data ? program.data.id : program.id;
      const index = list.findIndex((p) => p.id === id);
      let result = Array.from(list);
      result.splice(index, 1);
      return updateTimes(result, index, index);
    },
    removeTemplateItem(list, item) {
      const id = item.data ? item.data.drag_id : item.drag_id;
      const index = list.findIndex((p) => p.drag_id === id);
      let result = Array.from(list);
      result.splice(index, 1);
      return updateTimes(result, index, index);
    },
    recalculate(list) {
      return updateTimes(list, 0, 0);
    },
  };
}
